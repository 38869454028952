import React, { useRef, useMemo } from 'react';
import JoditEditor, { Jodit } from 'jodit-react';

const Ckeditor = ({ desc, setDesc }) => {
  const editor = useRef(null);

  const config = useMemo(() => ({
    readonly: false,
    toolbarSticky: false,
    placeholder: 'Write something awesome...',
    buttons: [
      'undo', 'redo', '|', 'bold', 'italic', 'underline', 'strikeThrough', '|',
      'align', 'font', 'fontsize', 'brush', 'paragraph', '|',
      'link', 'image', 'video', '|', 'ul', 'ol', '|',
      'outdent', 'indent', '|', 'hr', 'eraser', '|', 'fullsize', 'source'
    ],
    controls: {
      fontsize: {
        list: Jodit.atom(['8px', '10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '28px', '32px', '36px', '48px', '60px', '72px', '96px'])
      },
      paragraph: {
        list: Jodit.atom({
          p: 'Paragraph',
          h1: 'Heading 1',
          h2: 'Heading 2',
          h3: 'Heading 3',
          blockquote: 'Quote',
          pre: 'Source code'
        })
      }
    }
  }), []);

  return (
    <div className="editor-container">
      <JoditEditor style={{height:"700px"}}
        ref={editor}
        value={desc}
        config={config}
        onBlur={newContent => {
          setDesc(newContent);
        }}
      />
    </div>
  );
}

export default Ckeditor;