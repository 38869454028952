import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Logo from "../../images/logo.png";
import {
    requestCompanyGetCrediteToken,
    requestCompanyBoughtToken,
    requestCompanySpentToken,
    requestRetrieveSession,
    requestPurchageTokenPayment,
    userLogout
} from "../../Redux/actions";
import { toast } from 'react-toastify';

const CreditManagment = (props) => {
    const location = useLocation();
    // const { item } = location.state || {};
    const [crediteTokenData, setCrediteTokenData] = useState({})
    const [boughtTokenData, setBoughtTokenData] = useState([]);
    const [spentTokenData, setSpentTokenData] = useState([]);
    const [hasPurchased, setHasPurchased] = useState(false);
    const navigate = useNavigate();


    const getSessionId = () => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('session_id');
    };

    const sessionId = getSessionId();

    useEffect(() => {
        if (sessionId && !hasPurchased) {
            props.requestRetrieveSession({ id: sessionId });
            removeSessionIdFromUrl();
        }
    }, [sessionId, hasPurchased]);

    let hasCalledPurchaseTokenPayment = false;

    useEffect(() => {
        const token = localStorage.getItem("token");
        const stripeRetrieveData = props.company.getStripeRetrieve;

        if (token && stripeRetrieveData && !hasPurchased) {
            if (stripeRetrieveData.resp.status === "complete" && !hasCalledPurchaseTokenPayment) {
                const paymentDetails = stripeRetrieveData.resp.metadata;
                const requestData = {
                    bought: paymentDetails.token_amount,
                    in: paymentDetails.productName,
                };

                hasCalledPurchaseTokenPayment = true;
                props.requestPurchageTokenPayment({
                    data: requestData,
                    token: token,
                });

                props.requestCompanyGetCrediteToken({ data: { token } });

                props.userLogout();
            }
        }

        props.company.getStripeRetrieve = undefined;
    }, [props.company.getStripeRetrieve, hasPurchased]);

    const removeSessionIdFromUrl = () => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete('session_id');
        const newUrl = `${window.location.pathname}${queryParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
    };

    useEffect(() => {
        if (!hasPurchased) {
            const purchaseTokenData = props.company.purchaseTokens;
            console.log(purchaseTokenData,"purchaseTokenData")

            if (purchaseTokenData && purchaseTokenData.data.Status === "Success") {

                toast.success("Thank You for purchasing the product!", { theme: "colored", autoClose: 1000 });
                removeSessionIdFromUrl();
                setHasPurchased(true);
                props.userLogout();
            } else {
                removeSessionIdFromUrl();
            }

            props.company.purchaseTokens = undefined;
        }
    }, [props.company.purchaseTokens, hasPurchased]);



    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            props.requestCompanyGetCrediteToken({ data: { token } });
            props.requestCompanyBoughtToken({ data: { token } });
            props.requestCompanySpentToken({ data: { token } });
        }
    }, []);

    useEffect(() => {
        const {
            getCrediteToken,
            getBoughtToken,
            getSpentToken
        } = props.company;

        if (getCrediteToken !== undefined) {
            if (getCrediteToken.data.Status === "Success") {
                setCrediteTokenData(getCrediteToken.data.Message);
                props.company.getCrediteToken = undefined;
            } else {
                console.log(getCrediteToken.data.Message, "getCrediteToken.data");
                props.company.getCrediteToken = undefined;
                toast.error(getCrediteToken?.data?.Message, { theme: "colored", autoClose: 1000 });
                //navigate("/company/PackageToken");
            }
        }

        if (getBoughtToken && getBoughtToken.data?.Status === "Success") {
            const allBoughtTokens = getBoughtToken.data.Message || [];
            // console.log("getBoughtToken", getBoughtToken.data)
            setBoughtTokenData(allBoughtTokens)
        } else {
            setBoughtTokenData([])
        }

        if (getSpentToken && getSpentToken.data?.Status === "Success") {
            // console.log(getSpentToken.data.Message, "getSpentToken");
            const allSpentTokens = getSpentToken.data.Message || [];
            setSpentTokenData(allSpentTokens);
        } else {
            setSpentTokenData([]);
        }

    }, [
        props.company.getCrediteToken,
        props.company.getBoughtToken,
        props.company.getSpentToken,
    ]);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const redirectToPlan = () => {
        navigate("/company/PackageToken");
    }

    return (
        <>
            <Header />
            <div className='credit-managment'>
                <div className='container'>
                    <div className="new_research_btn mt-4 text-end">
                        <button
                            className="btn btn_default btn_orange_outline"
                            type="button"
                            onClick={redirectToPlan}
                        >
                            Indietro
                        </button>
                    </div>
                    <h3>Gestione crediti</h3>
                    <div className='credits-available'>
                        <div className='credits-wrapper'>
                            <div className='available-credits'>
                                <h6>Crediti disponibili</h6>
                                <span>{crediteTokenData.tokens || 0}</span>
                                <strong onClick={redirectToPlan}>Acquista altri crediti</strong>
                            </div>
                            <div className=''>
                                <img src={Logo} alt="Main Logo" />
                            </div>
                        </div>
                    </div>
                    {boughtTokenData.length > 0 && boughtTokenData.map((item, index) => (
                        <div key={index} className='credit-info'>
                            <h5>Riepilogo crediti acquistati</h5>
                            <div className='credit-row'>
                                <p><strong>Crediti acquistati il:</strong>{formatDate(item.bought_date)}</p>
                                <p>N° di crediti acquistati: <strong>{item.tokens_bought}</strong></p>
                            </div>
                            <div className='credit-row'>
                                <p><strong>Crediti acquistati il:</strong></p>
                                <p>N° di crediti acquistati: <strong></strong></p>
                            </div>
                        </div>
                    ))}

                    {spentTokenData.length > 0 && spentTokenData.map((item, index) => (
                        <div key={index} className='credit-info' >
                            <h5>Riepilogo crediti spesi</h5>
                            <div className='credit-row'>
                                <p><strong>Crediti spesi il:</strong>{formatDate(item.spent_date)}</p>
                                <p>Sponsorizzazione annuncio: <strong>{item.tokens_spent}</strong> crediti</p>
                            </div>
                            <div className='credit-row'>
                                <p><strong>Crediti spesi il:</strong></p>
                                <p>Visualizzazione CV: <strong>0</strong> crediti</p>
                            </div>
                        </div>
                    ))
                    }
                </div>
            </div>
            <Footer />
        </>
    );
}

const mapStateToProps = (state) => ({
    candidate: state.candidate,
    company: state.company,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        requestCompanyGetCrediteToken,
        requestCompanyBoughtToken,
        requestCompanySpentToken,
        requestRetrieveSession,
        requestPurchageTokenPayment,
        userLogout
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreditManagment);