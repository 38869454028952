import React, { useEffect } from 'react';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import { useNavigate } from 'react-router-dom';
import BackButton from '../common/BackButton';
import ChildCreaAnnuncio7Bis from './ChildCreaAnnuncio7Bis';

function CreaAnnuncioSeven(props) {
    const navigate = useNavigate();
    const {
        prevStep,
        CreatAd_Seven,
        errorCreatAd_Seven,
        handleOnCreatAd_Seven,
        validateAdSponsorDate,
        validateforSpent,
        validateForSponsorRecurrency,
        handleOnPatchAdvertisement,
        handleOnCreatSponsorType,
        isClearable,
        isSearchable,
        filterConfig,
        spentRef,
        sponsorRecurrencyRef,
        sponsorDueDateRef,
        error,
        setError,
        mystyle,
        t,
        application_sending_deadline
        // newAdvertisementId
    } = props


    useEffect(() => {
        const focusField = () => {
            if (errorCreatAd_Seven.spent && spentRef?.current) {
                spentRef.current.focus();
            } else if (errorCreatAd_Seven.sponsor_recurrency && sponsorRecurrencyRef?.current) {
                sponsorRecurrencyRef.current.focus();
            } else if (errorCreatAd_Seven.sponsor_due_date && sponsorDueDateRef?.current) {
                sponsorDueDateRef.current.focus();
            }
        };

        if (error) {
            focusField();
            setError(false);
        }
    }, [
        error,
        errorCreatAd_Seven,
        spentRef,
        sponsorRecurrencyRef,
        sponsorDueDateRef
    ]);

 
    return (
        <>
            <Header />
            <div className="seekng_job_sec create-an-ad">
                <div className="container">
                    <form>
                        <ChildCreaAnnuncio7Bis
                            //==================spent=========================

                            CreatAd_Seven={CreatAd_Seven}
                            handleOnCreatAd_Seven={handleOnCreatAd_Seven}
                            validateforSpent={validateforSpent}
                            errorCreatAd_Seven={errorCreatAd_Seven}
                            spentRef={spentRef}

                            //==================sponsor_recurrency=========================
                            handleOnCreatSponsorType={handleOnCreatSponsorType}
                            validateForSponsorRecurrency={validateForSponsorRecurrency}
                            sponsorRecurrencyRef={sponsorRecurrencyRef}

                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            filterConfig={filterConfig}

                            //==================sponsor_due_date=========================
                            validateAdSponsorDate={validateAdSponsorDate}
                            sponsorDueDateRef={sponsorDueDateRef}
                            mystyle={mystyle}
                            t={t}
                            application_sending_deadline={application_sending_deadline}
                            // newAdvertisementId={newAdvertisementId}
                        />
                        <div className="seeking_job_inner">
                            <div className="seeking_job_form">
                                <div className="seeking_form_full m-0">
                                    <div className="row g-3">
                                        <div className="col-12 mt-0">
                                            <div className="publish_block">
                                                <div className="publish_block_inner">
                                                    <div className="publish_text mb-0">
                                                        <p>
                                                            Pubblica il tuo annuncio senza alcuna
                                                            sponsorizzazione.
                                                        </p>
                                                        <p>
                                                            L’annuncio sarà visibile ad un numero limitato di
                                                            potenziali candidati interessati
                                                        </p>
                                                        <p>
                                                            Se le candidature interessate che ti
                                                            raggiungeranno non ti soddisferanno potrai in ogni
                                                            momento procedere ad una sponsorizzazione
                                                            dell’annuncio aumentandone in modo considerevole
                                                            la visibilità e di conseguenza il numero di
                                                            potenziali candidati che potranno rendersi
                                                            disponibili
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="seeking_form_btn">
                            <BackButton
                                prevStep={prevStep}
                            />
                            <div className="btn btn_default btn_orange_outline" onClick={handleOnPatchAdvertisement}>
                                pubblica senza sponsorizzare
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default CreaAnnuncioSeven;