import {
  RECEIVE_COMPANY_REGISTER,
  RECEIVE_COMPANY_DETAILS,
  RECEIVE_COMPANY_UPDATE,
  RECEIVE_COMPANY_PACKAGE,
  RECEIVE_COMPANY_JWT,
  RECEIVE_COMPANY_REFERENCE,
  RECEIVE_COMPANY_GET_CV_LIST,
  RECEIVE_COMPANY_CREATE_ADVERTISEMENT,
  RECEIVE_COMPANY_PATCH_ADVERTISEMENT,
  RECEIVE_COMPANY_REC_TIME,
  RECEIVE_COMPANY_CONTRACT_TYPES,
  RECEIVE_COMPANY_AVAILABILITES,
  RECEIVE_COMPANY_ADDITIONAL_WAGES,
  RECEIVE_COMPANY_BENEFITS,
  RECEIVE_COMPANY_REPRESENTATIVES,

  //================================Tokens==============================
  RECEIVE_COMPANY_TWELVE_TOKEN_SPENT,
  RECEIVE_COMPANY_GET_CREDITE_TOKEN,
  RECEIVE_COMPANY_CREDITE,
  RECEIVE_COMPANY_BOUGHT_TOKEN,
  RECEIVE_COMPANY_SPENT_TOKEN,
  RECEIVE_COMPANY_CORRESPONDING_TOKEN,

  //=============================PAYMENT================================
  RECEIVE_PURCHAGE_TOKEN_PAYMENT,
  RECEIVE_PAYPAL_PAYMENT,
  RECEIVE_STRIPE_PAYMENT,
  RECEIVE_SATISPAY_PAYMENT,
  RECEIVE_GET_RETRIEVE_SESSION_PAYMENT,
  RECEIVE_GET_LIST,
  RECEIVE_GET_CV_DETAILS,
} from "./actions";

export default (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_COMPANY_REGISTER:
      return {
        ...state,
        comRegisterData: data,
      };

    case RECEIVE_COMPANY_DETAILS:
      return {
        ...state,
        getCompanyData: data,
      };

    case RECEIVE_COMPANY_UPDATE:
      return {
        ...state,
        companyUpdateData: data,
      };

    case RECEIVE_COMPANY_REFERENCE:
      return {
        ...state,
        referenceData: data,
      };

    case RECEIVE_COMPANY_JWT:
      return {
        ...state,
        companyJwt: data,
      };
    case RECEIVE_COMPANY_PACKAGE:
      return {
        ...state,
        packageData: data,
      };

    case RECEIVE_COMPANY_GET_CV_LIST:
      return {
        ...state,
        cvListData: data,
      };

    case RECEIVE_COMPANY_CREATE_ADVERTISEMENT:
      return {
        ...state,
        advertisementData: data,
      };

    case RECEIVE_COMPANY_PATCH_ADVERTISEMENT:
      return {
        ...state,
        updateAdvertisementData: data,
      };

    case RECEIVE_COMPANY_REC_TIME:
      return {
        ...state,
        getRecTime: data,
      };

    case RECEIVE_COMPANY_CONTRACT_TYPES:
      return {
        ...state,
        getContractTypes: data,
      };

    case RECEIVE_COMPANY_AVAILABILITES:
      return {
        ...state,
        getAvailabilities: data,
      };

    case RECEIVE_COMPANY_ADDITIONAL_WAGES:
      return {
        ...state,
        getAdditionalWages: data,
      };

    case RECEIVE_COMPANY_BENEFITS:
      return {
        ...state,
        getBenefits: data,
      };

    case RECEIVE_COMPANY_REPRESENTATIVES:
      return {
        ...state,
        getRepresentatives: data,
      };

    //=================================Tokens=============================

    //===================Get Tokens=======================================

    case RECEIVE_COMPANY_TWELVE_TOKEN_SPENT :
      return {
          ...state,
          twelveTokenSpent: data,
      };

    
    case RECEIVE_COMPANY_GET_CREDITE_TOKEN:
      return {
        ...state,
        getCrediteToken: data,
      };

    //===================Get Tokens all Info=======================================

    case RECEIVE_COMPANY_CREDITE:
      return {
        ...state,
        crediteData: data,
      };

    case RECEIVE_COMPANY_BOUGHT_TOKEN:
      return {
        ...state,
        getBoughtToken: data,
      };

    case RECEIVE_COMPANY_SPENT_TOKEN:
      return {
        ...state,
        getSpentToken: data,
      };

    case RECEIVE_COMPANY_CORRESPONDING_TOKEN:
      return {
        ...state,
        corresPondingData: data,
      };

    //=============================PAYMENT================================

    case RECEIVE_PURCHAGE_TOKEN_PAYMENT:
      return {
        ...state,
        purchageTokens: data,
      };

    case RECEIVE_PAYPAL_PAYMENT:
      return {
        ...state,
        getPaypal: data,
      };
    case RECEIVE_STRIPE_PAYMENT:
      return {
        ...state,
        getStripe: data,
      };

      case RECEIVE_GET_RETRIEVE_SESSION_PAYMENT:
        return {
            ...state,
            getStripeRetrieve: data,
        };

    case RECEIVE_SATISPAY_PAYMENT:
      return {
        ...state,
        getSatispay: data,
      };

    case RECEIVE_GET_LIST:
      return {
        ...state,
        getlistData: data,
      };

    case RECEIVE_GET_CV_DETAILS:
      return {
        ...state,
        getCVDetails: data,
      };

    default:
      return state;
  }
};
