import { call, put, takeLatest } from 'redux-saga/effects'
import {
  REQUEST_REGISTER,
  receiveRegister,
  REQUEST_LOGIN,
  receiveLogin,
  REQUEST_GET_LIST,
  receiveGetList,
  REQUEST_GET_LOCATION,
  receiveGetLocation,
  REQUEST_GET_REGIONE_LIST,
  receiveGetRegioneList,
  REQUEST_GET_CANDIDATE,
  receiveGetCandidate,
  REQUEST_CANDIDATE_PROFILE,
  receiveCandidateProfile,
  REQUEST_GET_JOB,
  receiveGetJobDetails,
  REQUEST_GET_JOBLIST,
  receiveGetJobList,
  REQUEST_GET_JOBLIST_USER_ID,
  receiveGetJobListUserId,
  REQUEST_COMPANY_JOB_APPLY_NOW, receiveJobApplyNow,
  REQUEST_CANDIDATE_RESUME,
  receiveCandidateResume,
  REQUEST_GET_RESUME,
  receiveGetResumeDetails,
  REQUEST_UPDATE_RESUME,
  receiveUpdateResumeDetails,
  REQUEST_DELETE_RESUME_FILE,
  receiveDeleteResumeFile,
  REQUEST_DELETE_LETTER_FILE,
  receiveDeleteLetterFile,
  REQUEST_GET_EDUCATION_LEVEL_LIST,
  receiveGetEducationLevelList,
  REQUEST_GET_SECTOR_LIST,
  receiveGetSectorsList,
  REQUEST_CANDIDATE_ADD_ANSWER, receiveAddAnswerForJob,
  REQUEST_CANDIDATE_GET_ANSWER, receiveGetAnswerForJob,

  //======================== Company ====================================
  REQUEST_COMPANY_REGISTER,
  receiveCompanyRegister,
  REQUEST_COMPANY_DETAILS,
  receiveCompanyDetails,
  REQUEST_COMPANY_UPDATE,
  receiveCompanyUpdate,
  REQUEST_COMPANY_PACKAGE,
  receiveCompanyPackage,
  REQUEST_COMPANY_REFERENCE,
  receiveCompanyReference,
  REQUEST_COMPANY_JWT,
  receiveCompanyJWT,
  REQUEST_COMPANY_GET_CV_LIST,
  receiveGetCVList,
  REQUEST_COMPANY_CREATE_ADVERTISEMENT,
  receiveCreateAdvertisement,
  REQUEST_COMPANY_PATCH_ADVERTISEMENT,
  receivePatchAdvertisement,
  REQUEST_COMPANY_REC_TIME,
  receiveGetrecTime,
  REQUEST_COMPANY_CONTRACT_TYPES,
  receiveGetContractTypes,
  REQUEST_COMPANY_AVAILABILITES,
  receiveGetAvailabilities,
  REQUEST_COMPANY_ADDITIONAL_WAGES,
  receiveGetAdditionalWages,
  REQUEST_COMPANY_BENEFITS,
  receiveGetBenefits,
  REQUEST_COMPANY_REPRESENTATIVES,
  receiveGetRepresentatives,
  //=============================Tokens=================================
  REQUEST_COMPANY_GET_CREDITE_TOKEN,
  receiveCompanyGetCrediteToken,
  //================ all info of token=============================
  REQUEST_COMPANY_CREDITE,
  receiveCompanyCredite,
  REQUEST_COMPANY_BOUGHT_TOKEN,
  receiveCompanyBoughtToken,
  REQUEST_COMPANY_SPENT_TOKEN,
  receiveCompanySpentToken,
  REQUEST_COMPANY_CORRESPONDING_TOKEN,
  receiveCompanyCorrespondingToken,

  //==============================PAYMENT===========================
  REQUEST_PURCHAGE_TOKEN_PAYMENT,
  receivePurchageTokenPayment,
  REQUEST_PAYPAL_PAYMENT,
  receivePaypalPayment,
  REQUEST_STRIPE_PAYMENT,
  receiveStripePayment,
  REQUEST_SATISPAY_PAYMENT,
  receiveSatispayPayment,
  REQUEST_GET_RETRIEVE_SESSION_PAYMENT,
  receiveRetrieveSession,
  receiveGetProducts,
  REQUEST_GET_PRODUCTS,
  receiveGetFilterOptions,
  REQUEST_GET_FILTER_OPTIONS,
  receiveGetFranchisorResults,
  REQUEST_GET_FRANCHISOR_RESULTS,
  REQUEST_PUBLISH_FRANCHISING_AD,
  receivePublishFranchisingAd,
  REQUEST_SAVE_FILTER_OPTIONS,
  receiveSaveFilterOptions,
  receiveGetCVDetails,
  REQUEST_GET_CV_DETAILS,
} from './actions'

import {
  register,
  login,
  getlist,
  getlocation,
  getRegionelist,
  GetCandidate,
  candidateProfile,
  candidateresume,
  GetResumeDetails,
  UpdateResumeDetails,
  DeleteResumeFile,
  DeleteLetterFile,
  GetJobDetails,
  GetJobList,
  GetJobListUserId,
  jobApplyNow,
  getEducationlevel,
  getSectors,
  getProducts,
  getFilterOptions,
  getFranchisorResults,
  publishFranchisingAd,
  saveFilterOptions
} from './api'

import {
  companyRegister,
  companyDetails,
  companyProfile,
  CompanyJWTToken,
  GetCompanyReference,
  packageToken,
  // CreditToken,
  GetCVList,
  createAdvertisement,
  updateAdvertisement,
  getRectTime,
  getContractTypes,
  getAvailabilities,
  getAdditionalWages,
  getBenefits,
  getRepresentatives,

  //===========================Tokens================================
  getCreditTokens,
  allInfoToken,
  getBoughtToken,
  getSpentToken,
  corresPondingToken,

  //===============================PAYMENT===========================
  purchagePaymentToken,
  createOrderPaypal,
  createOrderStripe,
  createOrderSatispay,
  RetrieveSession,
  getCVDetails
} from './companyApi'

export function* registerCandidate (action) {
  try {
    const response = yield call(register, action.obj)

    yield put(receiveRegister(response))
  } catch (e) {
    console.log(e)
    // console.log(e.message)
  }
}

export function* loginCandidate (action) {
  try {
    const response = yield call(login, action.obj)

    yield put(receiveLogin(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* getListlevel (action) {
  try {
    const response = yield call(getlist, action.obj)

    yield put(receiveGetList(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* getLocationData (action) {
  try {
    const response = yield call(getlocation, action.obj)

    yield put(receiveGetLocation(response))
  } catch (e) {
    console.log(e.message)
  }
}

//================= regione list ===============================

export function* getRegionelistData (action) {
  try {
    const response = yield call(getRegionelist, action.obj)

    yield put(receiveGetRegioneList(response))
  } catch (e) {
    console.log(e.message)
  }
}

//================= educational level ===============================

export function* getEducationlevelList (action) {
  try {
    const response = yield call(getEducationlevel, action.obj)

    yield put(receiveGetEducationLevelList(response))
  } catch (e) {
    console.log(e.message)
  }
}

//================= sector level ===============================

export function* getListSectorlevel (action) {
  try {
    const response = yield call(getSectors, action.obj)

    yield put(receiveGetSectorsList(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* GetCandidateDetails (action) {
  try {
    const response = yield call(GetCandidate, action.obj)

    yield put(receiveGetCandidate(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* candidateProfileData (action) {
  try {
    const response = yield call(candidateProfile, action.obj)

    yield put(receiveCandidateProfile(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* resumeCandidate (action) {
  try {
    const response = yield call(candidateresume, action.obj)

    yield put(receiveCandidateResume(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* GetResumeData (action) {
  try {
    const response = yield call(GetResumeDetails, action.obj)

    yield put(receiveGetResumeDetails(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* UpdateResumeData (action) {
  try {
    const response = yield call(UpdateResumeDetails, action.obj)

    yield put(receiveUpdateResumeDetails(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* DeleteResumeFileData (action) {
  try {
    const response = yield call(DeleteResumeFile, action.obj)

    yield put(receiveDeleteResumeFile(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* DeleteLetterFileData (action) {
  try {
    const response = yield call(DeleteLetterFile, action.obj)

    yield put(receiveDeleteLetterFile(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* GetJobData (action) {
  try {
    const response = yield call(GetJobDetails, action.obj)

    yield put(receiveGetJobDetails(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* GetJobListData (action) {
  try {
    const response = yield call(GetJobList, action.obj)

    yield put(receiveGetJobList(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* GetJobListUserIdData (action) {
  try {
    const response = yield call(GetJobListUserId, action.obj)

    yield put(receiveGetJobListUserId(response))
  } catch (e) {
    console.log(e.message)
  }
}


export function* jobApplyNowData(action) {
  try {
    const response = yield call(jobApplyNow, action.obj)

    yield put(receiveJobApplyNow(response))
  } catch (e) {
    console.log(e.message)
  }
}

//======================= add answer ==========================================

export function* AddAnswerForJobsData(action) {
  try {
    const response = yield call(AddAnswerForJobs, action.obj)

    yield put(receiveAddAnswerForJob(response))
  } catch (e) {
    console.log(e.message)
  }
}

//======================= get answer ==========================================

export function* GetAnswerForJobsData(action) {
  try {
    const response = yield call(GetAnswerForJobs, action.obj)

    yield put(receiveGetAnswerForJob(response))
  } catch (e) {
    console.log(e.message)
  }
}

//======================================= Company ==========================================

export function* registerCompany (action) {
  try {
    const response = yield call(companyRegister, action.obj)

    yield put(receiveCompanyRegister(response))
  } catch (e) {
    console.log(e)
  }
}

export function* GetCompanyDetails (action) {
  try {
    const response = yield call(companyDetails, action.obj)

    yield put(receiveCompanyDetails(response))
  } catch (e) {
    console.log(e)
  }
}

export function* CompanyDetailsUpdate (action) {
  try {
    const response = yield call(companyProfile, action.obj)

    yield put(receiveCompanyUpdate(response))
  } catch (e) {
    console.log(e)
  }
}

export function* GetCompanyReferenceData (action) {
  try {
    const response = yield call(GetCompanyReference, action.obj)

    yield put(receiveCompanyReference(response))
  } catch (e) {
    console.log(e)
  }
}

export function* CompanyJWTTokenData (action) {
  try {
    const response = yield call(CompanyJWTToken, action.obj)

    yield put(receiveCompanyJWT(response))
  } catch (e) {
    console.log(e)
  }
}

export function* GetPackageData (action) {
  try {
    const response = yield call(packageToken, action.obj)

    yield put(receiveCompanyPackage(response))
  } catch (e) {
    console.log(e)
  }
}

// export function* GetCrediteData(action) {
//   try {
//     const response = yield call(CreditToken, action.obj);

//     yield put(receiveCompanyCredite(response));
//   } catch (e) {
//     console.log(e);
//   }
// }

export function* GetCVListData (action) {
  try {
    const response = yield call(GetCVList, action.obj)

    yield put(receiveGetCVList(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* createAdvertisementData (action) {
  try {
    const response = yield call(createAdvertisement, action.obj)

    yield put(receiveCreateAdvertisement(response))
  } catch (e) {
    console.log(e)
  }
}

//update advertisement

export function* updateAdvertisementData (action) {
  try {
    const response = yield call(updateAdvertisement, action.obj)

    yield put(receivePatchAdvertisement(response))
  } catch (e) {
    console.log(e)
  }
}

//get rect time

export function* getRectTimeData (action) {
  try {
    const response = yield call(getRectTime, action.obj)

    yield put(receiveGetrecTime(response))
  } catch (e) {
    console.log(e)
  }
}

// getContractTypes

export function* getContractTypesData (action) {
  try {
    const response = yield call(getContractTypes, action.obj)

    yield put(receiveGetContractTypes(response))
  } catch (e) {
    console.log(e)
  }
}

// getAvailabilities

export function* getAvailabilitiesData (action) {
  try {
    const response = yield call(getAvailabilities, action.obj)

    yield put(receiveGetAvailabilities(response))
  } catch (e) {
    console.log(e)
  }
}

// getAdditionalWages

export function* getAdditionalWagesData (action) {
  try {
    const response = yield call(getAdditionalWages, action.obj)

    yield put(receiveGetAdditionalWages(response))
  } catch (e) {
    console.log(e)
  }
}

// getBenefits

export function* getBenefitsData (action) {
  try {
    const response = yield call(getBenefits, action.obj)

    yield put(receiveGetBenefits(response))
  } catch (e) {
    console.log(e)
  }
}

//=========================== Representatives ==========================================

export function* getRepresentativesData (action) {
  try {
    const response = yield call(getRepresentatives, action.obj)

    yield put(receiveGetRepresentatives(response))
  } catch (e) {
    console.log(e)
  }
}

//=================================Get Only Tokens============================

export function* getCreditTokensData (action) {
  try {
    const response = yield call(getCreditTokens, action.obj)

    yield put(receiveCompanyGetCrediteToken(response))
  } catch (e) {
    console.log(e)
  }
}

//=================================all info of tokens==========================

export function* allInfoTokenData (action) {
  try {
    const response = yield call(allInfoToken, action.obj)

    yield put(receiveCompanyCredite(response))
  } catch (e) {
    console.log(e)
  }
}

//===========================bought token========================================

export function* getBoughtTokenData (action) {
  try {
    const response = yield call(getBoughtToken, action.obj)

    yield put(receiveCompanyBoughtToken(response))
  } catch (e) {
    console.log(e)
  }
}

//===========================spent token================================================

export function* getSpentTokenData (action) {
  try {
    const response = yield call(getSpentToken, action.obj)

    yield put(receiveCompanySpentToken(response))
  } catch (e) {
    console.log(e)
  }
}

//===========================corresponding number of tokens================================================

export function* corresPondingTokenData (action) {
  try {
    const response = yield call(corresPondingToken, action.obj)

    yield put(receiveCompanyCorrespondingToken(response))
  } catch (e) {
    console.log(e)
  }
}

//===============================PAYMENT GAT WAY SYSTEM==================================

//=========================Purchage Token of payment=========================

export function* purchageTokenPaymentData (action) {
  try {
    const response = yield call(purchagePaymentToken, action.obj)

    yield put(receivePurchageTokenPayment(response))
  } catch (e) {
    console.log(e)
  }
}

//======================== PAYPAL ============================================

export function* createOrderPaypalData (action) {
  try {
    const response = yield call(createOrderPaypal, action.obj)

    yield put(receivePaypalPayment(response))
  } catch (e) {
    console.log(e)
  }
}

//======================== STRIPE ============================================

export function* createOrderStripeData (action) {
  try {
    const response = yield call(createOrderStripe, action.obj)

    yield put(receiveStripePayment(response))
  } catch (e) {
    console.log(e)
  }
}

export function* getRetrieveSessionData (action) {
  try {
    const response = yield call(RetrieveSession, action.obj)

    yield put(receiveRetrieveSession(response))
  } catch (e) {
    console.log(e)
  }
}

//======================== SATISPAY ===========================================

export function* createOrderSatispayData (action) {
  try {
    const response = yield call(createOrderSatispay, action.obj)

    yield put(receiveSatispayPayment(response))
  } catch (e) {
    console.log(e)
  }
}

export function* GetProductsData (action) {
  try {
    const response = yield call(getProducts, action.obj)

    yield put(receiveGetProducts(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* GetFilterOptionsData (action) {
  try {
    const response = yield call(getFilterOptions, action.obj)

    yield put(receiveGetFilterOptions(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* GetFranchisorResultsData (action) {
  try {
    const response = yield call(getFranchisorResults, action.obj)

    yield put(receiveGetFranchisorResults(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* PublishFranchisingAdData (action) {
  try {
    const response = yield call(publishFranchisingAd, action.obj)

    yield put(receivePublishFranchisingAd(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* SaveFilterOptionsData (action) {
  try {
    const response = yield call(saveFilterOptions, action.obj)

    yield put(receiveSaveFilterOptions(response))
  } catch (e) {
    console.log(e.message)
  }
}

export function* GetCVDetailsData (action) {
  try {
    const response = yield call(getCVDetails, action.obj)

    yield put(receiveGetCVDetails(response))
  } catch (e) {
    console.log(e.message)
  }
}

export default function* mainSaga () {
  yield takeLatest(REQUEST_REGISTER, registerCandidate)
  yield takeLatest(REQUEST_LOGIN, loginCandidate)
  yield takeLatest(REQUEST_GET_LIST, getListlevel)
  yield takeLatest(REQUEST_GET_LOCATION, getLocationData)

  //=======================region list ===============================================

  yield takeLatest(REQUEST_GET_REGIONE_LIST, getRegionelistData)

  //================= educational level and sector level ===============================

  yield takeLatest(REQUEST_GET_EDUCATION_LEVEL_LIST, getEducationlevelList)
  yield takeLatest(REQUEST_GET_SECTOR_LIST, getListSectorlevel)

  yield takeLatest(REQUEST_GET_CANDIDATE, GetCandidateDetails)
  yield takeLatest(REQUEST_CANDIDATE_PROFILE, candidateProfileData)

  //======================== get resume =========================

  yield takeLatest(REQUEST_GET_RESUME, GetResumeData)
  //======================== post resume =========================

  yield takeLatest(REQUEST_CANDIDATE_RESUME, resumeCandidate)
  //======================== update resume =========================
  yield takeLatest(REQUEST_UPDATE_RESUME, UpdateResumeData)

  //======================== delete resume file =========================
  yield takeLatest(REQUEST_DELETE_RESUME_FILE, DeleteResumeFileData)

  //======================== delete letter file =========================
  yield takeLatest(REQUEST_DELETE_LETTER_FILE, DeleteLetterFileData)

  yield takeLatest(REQUEST_GET_JOB, GetJobData)
  yield takeLatest(REQUEST_GET_JOBLIST, GetJobListData)
  yield takeLatest(REQUEST_GET_JOBLIST_USER_ID, GetJobListUserIdData)

  yield takeLatest(REQUEST_COMPANY_JOB_APPLY_NOW, jobApplyNowData)

  //======================add answer for job and get answer ==========================

  yield takeLatest(REQUEST_CANDIDATE_ADD_ANSWER, AddAnswerForJobsData)

  yield takeLatest(REQUEST_CANDIDATE_GET_ANSWER, GetAnswerForJobsData)

  //================================================ Company =================================
  yield takeLatest(REQUEST_COMPANY_REGISTER, registerCompany)
  yield takeLatest(REQUEST_COMPANY_DETAILS, GetCompanyDetails)
  yield takeLatest(REQUEST_COMPANY_UPDATE, CompanyDetailsUpdate)
  yield takeLatest(REQUEST_COMPANY_REFERENCE, GetCompanyReferenceData)
  yield takeLatest(REQUEST_COMPANY_JWT, CompanyJWTTokenData)
  yield takeLatest(REQUEST_COMPANY_PACKAGE, GetPackageData)

  yield takeLatest(REQUEST_COMPANY_GET_CV_LIST, GetCVListData)
  yield takeLatest(
    REQUEST_COMPANY_CREATE_ADVERTISEMENT,
    createAdvertisementData
  )
  yield takeLatest(REQUEST_COMPANY_PATCH_ADVERTISEMENT, updateAdvertisementData)

  yield takeLatest(REQUEST_COMPANY_REC_TIME, getRectTimeData)
  yield takeLatest(REQUEST_COMPANY_CONTRACT_TYPES, getContractTypesData)
  yield takeLatest(REQUEST_COMPANY_AVAILABILITES, getAvailabilitiesData)
  yield takeLatest(REQUEST_COMPANY_ADDITIONAL_WAGES, getAdditionalWagesData)
  yield takeLatest(REQUEST_COMPANY_BENEFITS, getBenefitsData)
  yield takeLatest(REQUEST_COMPANY_REPRESENTATIVES, getRepresentativesData)

  //==========================Tokens===================================
  yield takeLatest(REQUEST_COMPANY_GET_CREDITE_TOKEN, getCreditTokensData)
  yield takeLatest(REQUEST_COMPANY_CREDITE, allInfoTokenData)
  yield takeLatest(REQUEST_COMPANY_BOUGHT_TOKEN, getBoughtTokenData)
  yield takeLatest(REQUEST_COMPANY_SPENT_TOKEN, getSpentTokenData)
  yield takeLatest(REQUEST_COMPANY_CORRESPONDING_TOKEN, corresPondingTokenData)

  //========================payment system==========================
  yield takeLatest(REQUEST_PURCHAGE_TOKEN_PAYMENT, purchageTokenPaymentData)
  yield takeLatest(REQUEST_PAYPAL_PAYMENT, createOrderPaypalData)
  yield takeLatest(REQUEST_STRIPE_PAYMENT, createOrderStripeData)
  yield takeLatest(REQUEST_SATISPAY_PAYMENT, createOrderSatispayData)
  yield takeLatest(REQUEST_GET_RETRIEVE_SESSION_PAYMENT, getRetrieveSessionData)

  yield takeLatest(REQUEST_GET_PRODUCTS, GetProductsData)

  yield takeLatest(REQUEST_GET_FILTER_OPTIONS, GetFilterOptionsData)

  yield takeLatest(REQUEST_GET_FRANCHISOR_RESULTS, GetFranchisorResultsData)
  yield takeLatest(REQUEST_PUBLISH_FRANCHISING_AD, PublishFranchisingAdData)

  yield takeLatest(REQUEST_SAVE_FILTER_OPTIONS, SaveFilterOptionsData)

  yield takeLatest(REQUEST_GET_CV_DETAILS, GetCVDetailsData)
}
