import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestGetCVDetails, requestGetJobDetails } from "../../Redux/actions";
import CompanyLogo from "../../../src/images/logo.png";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import { toast } from "react-toastify";

function CandidateJobsDetails(props) {
  const [jobDetails, setJobDetails] = useState({});

  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (props.id !== null && token && !props.user.company) {
      props.requestGetJobDetails({
        data: {
          id: props.id,
          token: token,
        },
      });
    } else if (props.id !== null && token && props.user.company) {
      props.requestGetCVDetails({
        data: {
          username: props.detail.username,
        },
        token: props.candidate.loginData?.data?.Message?.access, // .....
      });
    }
  }, [props.id, props.detail]);

  useEffect(() => {
    if (props.user.company) {
      let jobsDetails = props.company.getCVDetails;
      if (jobsDetails !== undefined) {
        if (jobsDetails?.data?.Status === "Success") {
          props.company.getCVDetails = undefined;
          const jobDetailsData = jobsDetails?.data?.Message[0];
          setJobDetails(jobDetailsData);
          console.log(jobDetails);
        } else {
          props.company.getCVDetails = undefined;
        }
      }
      return;
    }
    let JobsDetails = props.candidate.GetJobData;
    if (JobsDetails !== undefined) {
      if (JobsDetails?.data?.Status === "Success") {
        props.candidate.GetJobData = undefined;
        const jobDetailsData = JobsDetails?.data?.Message[0];
        setJobDetails(jobDetailsData);
      } else {
        props.candidate.GetJobData = undefined;
      }
    }
  }, [props.candidate.GetJobData, props]);

  const handleSaveCV = async (jobDetails) => {
    const token = localStorage.getItem('token');
    const username = props.detail.username;
    axios.patch(`${process.env.REACT_APP_API_HOST}auth/register/company`,
      {
        data: {
          saved_users: [
            {
              id: props.id
            }
          ]
        }
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: { username: username }
      }
    ).then(response => {
      console.log(response);
      if(response.data.Status === 'Success') {
        toast.success("Curriculum salvato con successo.", {
          theme: 'colored',
          autoClose: 8000
        });
      }
    })
  }

  return (
    <>
      <div className="job_detail_sec">
        <div className="container">
          <div className="row">
            <div className="job_detail_inner col-xl-12 col-lg-12 col-md-12 col-m-12 col-12">
              <div className="job_details">
                <div className="company_logo">
                  <img src={CompanyLogo} alt="company logo" />
                </div>
                <div className="job_title mb-0">
                  <h2 className=""></h2>
                </div>
                <div className="job_title">
                  <h2 className="">{jobDetails.job_title}</h2>
                </div>

                <div className="job_salary">
                  {(() => {
                    if (props.user.company) {
                      return (
                        <p>
                          {jobDetails.name} {jobDetails.surname}
                        </p>
                      );
                    } else {
                      return jobDetails.salary ? (
                        jobDetails.salary.fixedAmount ? (
                          <p>{`${jobDetails.salary.fixedAmount} ${jobDetails.salary.reccurency}`}</p>
                        ) : jobDetails.salary.minAmount &&
                          jobDetails.salary.maxAmount ? (
                          <p>{`${jobDetails.salary.minAmount} - ${jobDetails.salary.maxAmount} ${jobDetails.salary.reccurency}`}</p>
                        ) : (
                          <p>Not disclosed</p>
                        )
                      ) : (
                        <p>No salary information available</p>
                      );
                    }
                  })()}
                </div>

                <div className="job_address">
                  <p>
                    {(() => {
                      if (props.user.company) {
                        return (
                          <>
                            {jobDetails.address?.[0].street &&
                              `${jobDetails.address?.[0].street}, `}
                            {jobDetails.address?.[0].city &&
                              `${jobDetails.address?.[0].city}, `}
                            {jobDetails.address?.[0].province &&
                              `${jobDetails.address?.[0].province}, `}
                            {jobDetails.address?.[0].state &&
                              `${jobDetails.address?.[0].state}, `}
                            {jobDetails.address?.[0].country &&
                              `${jobDetails.address?.[0].country}, `}
                            {jobDetails.address?.[0].zip_code &&
                              `CAP: ${jobDetails.address?.[0].zip_code}`}
                          </>
                        );
                      } else {
                        return jobDetails.address ? (
                          <>
                            {jobDetails.address.street &&
                              `${jobDetails.address.street}, `}
                            {jobDetails.address.city &&
                              `${jobDetails.address.city}, `}
                            {jobDetails.address.province &&
                              `${jobDetails.address.province}, `}
                            {jobDetails.address.state &&
                              `${jobDetails.address.state}, `}
                            {jobDetails.address.country &&
                              `${jobDetails.address.country}, `}
                            {jobDetails.address.zip_code &&
                              `CAP: ${jobDetails.address.zip_code}`}
                          </>
                        ) : (
                          ""
                        );
                      }
                    })()}
                  </p>
                </div>
                <div className="company_placed_on">
                  <ul>
                    {jobDetails.sector && (
                      <li>
                        <b style={{ color: "black" }}>Sector: </b>
                        {jobDetails.sector.sectorName
                          ? jobDetails.sector.sectorName
                          : "Not specified"}
                      </li>
                    )}
                    {jobDetails.subsector && (
                      <li>
                        <b style={{ color: "black" }}>Subsector: </b>
                        {jobDetails.subsector.subsector_name
                          ? jobDetails.subsector.subsector_name
                          : "Not specified"}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              {props.user.company ? (
                <></>
              ) : (
                <div className="job_requirements">
                  <ul>
                    <li>
                      <b style={{ color: "black" }}>Required Skills: </b>
                      {jobDetails.skills && jobDetails.skills.length > 0 ? (
                        jobDetails.skills.map((skill, index) => (
                          <span key={index}>
                            {skill.skillName}
                            {index !== jobDetails.skills.length - 1 && ", "}
                          </span>
                        ))
                      ) : (
                        <span>No skills listed</span>
                      )}
                    </li>
                  </ul>
                </div>
              )}
              {props.user.company ? (
                <></>
              ) : (
                <div className="recruiter_info">
                  <ul>
                    {jobDetails.tel_visible ? (
                      <li>
                        <b style={{ color: "black" }}>Recruiter E-mail: </b>
                        {jobDetails.e_mail}
                      </li>
                    ) : (
                      ""
                    )}
                    <li>
                      <b style={{ color: "black" }}>Recruiter E-mail: </b>
                      {jobDetails.e_mail}
                    </li>
                  </ul>
                </div>
              )}

              {props.user.company ? (
                <>
                  <div className="job_description">
                    <h6 className="job_description_title">Formazione</h6>
                    <p>{jobDetails.educationalLevel?.levelType}</p>
                  </div>
                </>
              ) : (
                <div className="job_description">
                  <h6 className="job_description_title">Job Description :</h6>
                  {/* <div> */}
                  <p>{jobDetails.job_description}</p>

                  {jobDetails.driving_licence_required && (
                    <p>Driving license required</p>
                  )}
                  <p>
                    {jobDetails.educationalLevel
                      ? jobDetails.educationalLevel?.levelType
                      : "Nothing is mentioned about education "}
                  </p>
                  {jobDetails.job_description_adv && (
                    <p> {jobDetails.job_description_adv}</p>
                  )}
                  {/* </div> */}
                </div>
              )}

              {props.user.company ? (
                <></>
              ) : (
                <div className="job_additional_info">
                  <ul>
                    {jobDetails.availability &&
                      jobDetails.availability.length > 0 && (
                        <li>
                          <b style={{ color: "black" }}>Availability: </b>
                          {jobDetails.availability.map(
                            (availability, index) => (
                              <span key={index}>
                                {availability.availability_type}
                                {index !== jobDetails.availability.length - 1 &&
                                  ", "}
                              </span>
                            )
                          )}
                        </li>
                      )}
                    {jobDetails.driving_licences_required &&
                      jobDetails.driving_licences_required.length > 0 && (
                        <li>
                          <b style={{ color: "black" }}>
                            Driving Licenses Required:{" "}
                          </b>
                          {jobDetails.driving_licences_required.map(
                            (licence, index) => (
                              <span key={index}>
                                {licence.licenceType}
                                {index !==
                                  jobDetails.driving_licences_required.length -
                                    1 && ", "}
                              </span>
                            )
                          )}
                        </li>
                      )}
                    {jobDetails.salary &&
                      jobDetails.salary.additional_wages &&
                      jobDetails.salary.additional_wages.length > 0 && (
                        <li>
                          <b style={{ color: "black" }}>Additional Wages: </b>
                          {jobDetails.salary.additional_wages.map(
                            (wage, index) => (
                              <span key={index}>
                                {wage.additional_wage}
                                {index !==
                                  jobDetails.salary.additional_wages.length -
                                    1 && ", "}
                              </span>
                            )
                          )}
                        </li>
                      )}

                    {jobDetails.salary &&
                      jobDetails.salary.benefit &&
                      jobDetails.salary.benefit.length > 0 && (
                        <li>
                          <b style={{ color: "black" }}>Benefits: </b>
                          {jobDetails.salary.benefit.map((benefit, index) => (
                            <span key={index}>
                              {benefit.benefit}
                              {index !== jobDetails.salary.benefit.length - 1 &&
                                ", "}
                            </span>
                          ))}
                        </li>
                      )}
                  </ul>
                </div>
              )}

              {/* <div className="job_requests">
                <h6 className="job_requests_title">Domande</h6>
                <p>Lorem?</p>

                <form>
                  <div className="mb-3">
                    <textarea
                      className="form-control"
                      id="JobRequests"
                      rows="3"
                    ></textarea>
                  </div>
                </form>
              </div> */}

              <div className="job_next_process company_next_process">
                <div className="save_apply_btn">
                  <div className="save_btn">
                    <button className="btn btn_default btn_orange"
                    onClick={() => handleSaveCV()}
                    >
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 19.71H15C16.0609 19.71 17.0783 19.2885 17.8284 18.5384C18.5786 17.7882 19 16.7708 19 15.71V6.12396C18.9999 5.85877 18.8946 5.60445 18.707 5.41696L15.293 2.00296C15.1055 1.81541 14.8512 1.71002 14.586 1.70996H5C3.93913 1.70996 2.92172 2.13139 2.17157 2.88153C1.42143 3.63168 1 4.64909 1 5.70996V15.71C1 16.7708 1.42143 17.7882 2.17157 18.5384C2.92172 19.2885 3.93913 19.71 5 19.71Z"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 19.71V12.71C15 12.4447 14.8946 12.1904 14.7071 12.0029C14.5196 11.8153 14.2652 11.71 14 11.71H6C5.73478 11.71 5.48043 11.8153 5.29289 12.0029C5.10536 12.1904 5 12.4447 5 12.71V19.71M7 1.70996H13V4.70996C13 4.97518 12.8946 5.22953 12.7071 5.41707C12.5196 5.6046 12.2652 5.70996 12 5.70996H8C7.73478 5.70996 7.48043 5.6046 7.29289 5.41707C7.10536 5.22953 7 4.97518 7 4.70996V1.70996Z"
                          stroke="white"
                          strokeWidth="2"
                        />
                        <path
                          d="M9 15.71H11"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinejoin="round"
                        />
                      </svg>
                      { location.state.resultsType === "employees" && "Salva candidato"}
                      { location.state.resultsType === "agents" && "Salva agente"}
                    </button>
                  </div>
                  <div className="apply_btn">
                    <button className="btn btn_default btn_green">
                    { location.state.resultsType === "employees" && "Contatta candidato"}
                    { location.state.resultsType === "agents" && "Contatta agente"}
                    </button>
                  </div>
                </div>
                {props.user.company ? (
                  <p>
                    <b>Iscritto a Wideer dal: </b>
                    {new Date(
                      jobDetails.registration_date
                    ).toLocaleDateString()}
                  </p>
                ) : (
                  <>
                    <div className="job_overview">
                      <h6 className="job_overview_title">Job Overview</h6>
                      <ul>
                        <li>
                          <b>Published on:</b>
                          {new Date(
                            jobDetails.ad_creation_date
                          ).toLocaleDateString()}
                        </li>
                      </ul>
                    </div>
                    <div className="job_question" id="commentid">
                      <h6 className="job_question_title">Questions</h6>
                      {jobDetails.questions &&
                        jobDetails.questions.length > 0 && (
                          <ul>
                            {jobDetails.questions.map((q) => (
                              <li key={q.id}>
                                <b>
                                  {q.id}. {q.question}
                                </b>
                              </li>
                            ))}
                          </ul>
                        )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return { candidate: state.candidate, company: state.company };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestGetJobDetails, requestGetCVDetails }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateJobsDetails);
