import React from 'react';

//================ Basic Register, Candidate Register, Profile ===================// 

function ZipCode(props) {
    const {
        data,
        zipCodeRef,
        onChangeData,
        validateZipCode,
        errorZipcode,
        mystyle,
        t,
    } = props;

    return (
        <div className="registration_col form-group col-md-6 col-12">
            <label htmlFor="zip_code" className="label">
                {/* CAP */}
                {t("register.zip_code")}
            </label>
            <input
                type="text"
                className={`form-control zip_input ${errorZipcode ? "error" : ""
                    }`}
                placeholder={t("register.placeHolder_zip_code")}
                id="zip_code"
                name="zip_code"
                ref={zipCodeRef}
                value={data.zip_code || ""}
                onChange={onChangeData}
                onBlur={validateZipCode}
            />
            {errorZipcode && <div style={mystyle}>{errorZipcode}</div>}
        </div>
    )
}

export default ZipCode