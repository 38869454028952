import React, { useState, useRef } from 'react'
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { useNavigate } from 'react-router-dom';
import ChildCreaAnnuncio7Bis from '../Create-and-ad/step7/ChildCreaAnnuncio7Bis';
import { useTranslation } from "react-i18next";

function CreaAnnuncio7Bis() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [CreatAd_Seven, setCreatAd_Seven] = useState(
    {
      spent: "",
      sponsor_due_date: "",
      sponsor_recurrency: ""
    }
  );

  const [errorCreatAd_Seven, setErrorCreatAd_Seven] = useState(
    {
      spent: "",
      sponsor_due_date: "",
      sponsor_recurrency: ""
    });

  const [dueDate, setDueDate] = useState('');

  const mystyle = {
    color: "#D10000",
    padding: "3px 10px",
    width: "100%",
  };

  const isClearable = true;
  const isSearchable = true;
  const matchFromStart = true;

  const filterConfig = {
    matchFrom: matchFromStart ? "start" : "any",
  };

  const spentRef = useRef(null);
  const sponsorRecurrencyRef = useRef(null);
  const sponsorDueDateRef = useRef(null);

  //=====================================STEP 7 Validateion  ==========================================

  const validateAdSponsorDate = () => {
    const { sponsor_due_date } = CreatAd_Seven;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (!sponsor_due_date) {
      setErrorCreatAd_Seven((prevState) => ({
        ...prevState,
        sponsor_due_date: "Inserisci una data di scadenza valida.",
      }));
      return false;
    } else if (new Date(sponsor_due_date).getTime() <= today.getTime()) {
      setErrorCreatAd_Seven((prevState) => ({
        ...prevState,
        sponsor_due_date: "Inserisci una data di scadenza valida.",
      }));

      return false;
    } else {
      setErrorCreatAd_Seven((prevState) => ({
        ...prevState,
        sponsor_due_date: "",
      }));
      return true;
    }
  };

  const validateforSpent = () => {
    const { spent } = CreatAd_Seven;
    if (!spent) {
      setErrorCreatAd_Seven((prevState) => ({
        ...prevState,
        spent: "Inserisci il tuo spent",
      }));
      return false;
    } else {
      setErrorCreatAd_Seven((prevState) => ({
        ...prevState,
        spent: "",
      }));
      return true;
    }
  }

  const validateForSponsorRecurrency = () => {
    const { sponsor_recurrency } = CreatAd_Seven;
    if (!sponsor_recurrency) {
      setErrorCreatAd_Seven((prevState) => ({
        ...prevState,
        sponsor_recurrency: "Inserisci il tuo sponsor recurrency",
      }));
      return false;
    } else {
      setErrorCreatAd_Seven((prevState) => ({
        ...prevState,
        sponsor_recurrency: "",
      }));
      return true;
    }
  }

  //============================================= Step 7  Function================================================
  
  // Function to format a Date object to YYYY-MM-DD


const handleOnCreatAd_Seven = (e) => {
  const { name, value } = e.target;

  if (name === "spent" && value < 0) {
      return;
  }

  setCreatAd_Seven((prevData) => ({ ...prevData, [name]: value }));
};


  const handleOnCreatSponsorType = (value) => {
    const newValue = value?.label || "";

    if (newValue) {
      setCreatAd_Seven((prevData) => ({
        ...prevData,
        sponsor_recurrency: newValue,
      }));
    } else {
      setCreatAd_Seven((prevData) => ({
        ...prevData,
        sponsor_recurrency: "",
      }));
    }
  };


  const redirectToAdvertisementList = () => {
    navigate("/company/advertisement-list");
  }

  return (
    <>
      <Header />
      <div className="seekng_job_sec create-an-ad">
        <div className="container">
          <div className="new_research_btn text-end">
            <button
              className="btn btn_default btn-prev btn_orange_outline"
              type="button"
              onClick={() => redirectToAdvertisementList()}
            >
              <svg
                width="15"
                height="11"
                viewBox="0 0 15 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.707 10.7072C6.51947 10.8947 6.26516 11 6 11C5.73484 11 5.48053 10.8947 5.293 10.7072L0.793 6.20721C0.605529 6.01969 0.500214 5.76538 0.500214 5.50021C0.500214 5.23505 0.605529 4.98074 0.793 4.79321L5.293 0.293214C5.4816 0.111055 5.7342 0.0102615 5.9964 0.0125399C6.2586 0.0148182 6.50941 0.119987 6.69482 0.305395C6.88023 0.490803 6.9854 0.741616 6.98767 1.00381C6.98995 1.26601 6.88916 1.51861 6.707 1.70721L4 4.50021L13.5 4.50021C13.7652 4.50021 14.0196 4.60557 14.2071 4.79311C14.3946 4.98064 14.5 5.235 14.5 5.50021C14.5 5.76543 14.3946 6.01978 14.2071 6.20732C14.0196 6.39486 13.7652 6.50021 13.5 6.50021L4 6.50021L6.707 9.29321C6.89447 9.48074 6.99979 9.73505 6.99979 10.0002C6.99979 10.2654 6.89447 10.5197 6.707 10.7072Z"
                  fill="#E75420"
                />
              </svg>
              Indietro
            </button>

          </div>
          <form>
            {/* <div className="seekng_job_title_note">
              <div className="seekng_job_title">
                <h2>
                  Inserisci un nuovo annuncio per la ricerca di dipendenti
                </h2>
                <h6>Pubblica annuncio</h6>
              </div>
            </div>
            <div className="seeking_job_inner">
              <div className="seeking_job_form">
                <div className="seeking_form_full m-0">
                  <div className="row g-3">
                    <div className="col-12 mt-0">
                      <div className="publish_block">
                        <div className="publish_block_inner">
                          <div className="publish_text">
                            <p>
                              Pubblica il tuo annuncio sponsorizzandolo. <br />
                              In questo modo l annuncio risulterà molto più
                              visibile ai potenziali candidati interessati
                            </p>
                            <p>
                              Ti suggeriamo un valore di sponsorizzazione che,
                              in base alla nostre statistiche storiche può far
                              aumentare la percentuale di candidature
                              interessate fino ad un valore dell 84%
                            </p>
                          </div>

                          <div className="row g-3">
                            <div className="col-md-6 col-12">
                              <label className="form-label">
                                Crediti da investire
                              </label>
                              <div className="d-flex flex-wrap flex-sm-nowrap contact-duration">
                                <input className="form-control" />
                                <input
                                  className="form-control"
                                  placeholder="Giorno"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-12 text-center">
                              <label className="form-label">
                                Sponsorizza fino al
                              </label>
                              <div className="d-flex flex-wrap flex-sm-nowrap contact-duration justify-content-center">
                                <input className="form-control" type="date" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="seeking_form_btn">
              <p className="credits_text">
                Crediti spesi al giorno:
                <span>Non hai crediti sufficienti!</span>
              </p>
              <div className="btn btn_default btn_green_outline" >
                acquista crediti
              </div>
              <div className="btn btn_default btn_green">
                Sponsorizza annuncio
              </div>
            </div> */}

            <ChildCreaAnnuncio7Bis
              //==================spent=========================

              CreatAd_Seven={CreatAd_Seven}
              handleOnCreatAd_Seven={handleOnCreatAd_Seven}
              validateforSpent={validateforSpent}
              errorCreatAd_Seven={errorCreatAd_Seven}
              spentRef={spentRef}

              //==================sponsor_recurrency=========================
              handleOnCreatSponsorType={handleOnCreatSponsorType}
              validateForSponsorRecurrency={validateForSponsorRecurrency}
              sponsorRecurrencyRef={sponsorRecurrencyRef}

              isClearable={isClearable}
              isSearchable={isSearchable}
              filterConfig={filterConfig}

              //==================sponsor_due_date=========================
              validateAdSponsorDate={validateAdSponsorDate}
              sponsorDueDateRef={sponsorDueDateRef}
              mystyle={mystyle}
              t={t}
            />
          </form>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default CreaAnnuncio7Bis