import React from 'react';
import Select, { createFilter } from "react-select";

function SelectLocation(props) {
    const {
        CreatAd_One,
        errorCreatAd_One,
        sortedCountryData,
        municipalities,
        handleOnCreatAd_One,
        handleChangeCityCreatAd_One,
        countryRef,
        cityRef,
        provinceRef,
        validateCountry,
        validateCity,
        validateProvince,
        isLoading,
        isClearable,
        isSearchable,
        filterConfig,
        mystyle,
        t
    } = props;

    return (
        <>
            {
                CreatAd_One.isAddressComplete === true &&
                    <>
                        <div className="col-12" style={{
                            // visibility: `${CreatAd_One.isAddressComplete === true
                            //     ? "visible"
                            //     : "hidden"
                            //     }`,
                        }}
                        >
                            <label className="form-label" htmlFor="country">
                                Nazione
                            </label>
                            <select
                                className={`form-control ${errorCreatAd_One?.country ? "error" : ""
                                    }`}
                                placeholder={t("location.country")}
                                id="country"
                                name="country"
                                value={CreatAd_One?.country || ""}
                                ref={countryRef}
                                onChange={handleOnCreatAd_One}
                                onBlur={validateCountry}
                            >
                                <option value="">Nazione</option>
                                {sortedCountryData.map((option, index) => (
                                    <option key={index} value={option.nativeCountryName}>
                                        {option.nativeCountryName}
                                    </option>
                                ))}
                            </select>
                            {errorCreatAd_One.country && (
                                <div style={mystyle}>{errorCreatAd_One.country}</div>
                            )}
                        </div>

                        {CreatAd_One?.country?.length > 0 && (
                            <>
                                {CreatAd_One.country === "Italia" ? (
                                    <>
                                        <div className="col-12"
                                        // style={{
                                        //     visibility: `${CreatAd_One.isAddressComplete === true
                                        //         ? "visible"
                                        //         : "hidden"
                                        //         }`,
                                        // }}
                                        >
                                            <label htmlFor="city" className="label">
                                                {t("location.city")}, {t("location.province")}
                                            </label>
                                            <Select
                                                className={`form-control set_max_width select_dropdown ${errorCreatAd_One.city ? "error" : ""
                                                    }`}
                                                options={municipalities}
                                                value={
                                                    CreatAd_One.city && CreatAd_One.province
                                                        ? {
                                                            label: `${CreatAd_One.city} ${CreatAd_One.province}`,
                                                        }
                                                        : ""
                                                }
                                                isLoading={isLoading}
                                                isClearable={isClearable}
                                                onChange={handleChangeCityCreatAd_One}
                                                isSearchable={!isLoading && isSearchable}
                                                ref={cityRef}
                                                onBlur={validateCity}
                                                placeholder="Seleziona un Comune"
                                                filterOption={createFilter(filterConfig)}
                                            />
                                            {errorCreatAd_One?.city && (
                                                <div style={mystyle}>
                                                    {errorCreatAd_One?.city}
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="col-12"
                                        // style={{
                                        //     visibility: `${CreatAd_One.isAddressComplete === true
                                        //         ? "visible"
                                        //         : "hidden"
                                        //         }`,
                                        // }}
                                        >
                                            <label htmlFor="city" className="label">
                                                {t("location.city")}
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control  ${errorCreatAd_One.city ? "error" : ""
                                                    }`}
                                                placeholder={t("location.city")}
                                                id="city"
                                                name="city"
                                                ref={cityRef}
                                                value={CreatAd_One.city || ""}
                                                onChange={handleOnCreatAd_One}
                                                onBlur={validateCity}
                                            />
                                            {errorCreatAd_One?.city && (
                                                <div style={mystyle}>
                                                    {errorCreatAd_One?.city}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12"
                                        // style={{
                                        //     visibility: `${CreatAd_One.isAddressComplete === true
                                        //         ? "visible"
                                        //         : "hidden"
                                        //         }`,
                                        // }}
                                        >
                                            <label htmlFor="province" className="label">
                                                {t("location.province")}
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control  ${CreatAd_One.province.length === 0 && errorCreatAd_One.province ? "error" : ""
                                                    }`}
                                                placeholder={t("location.province")}
                                                name="province"
                                                id="province"
                                                ref={provinceRef}
                                                value={CreatAd_One.province || ""}
                                                onChange={handleOnCreatAd_One}
                                                onBlur={validateProvince}
                                            />
                                            {CreatAd_One.province.length === 0 && errorCreatAd_One?.province && (
                                                <div style={mystyle}>
                                                    {errorCreatAd_One?.province}
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </> 
            }
        </>
    )
}

export default SelectLocation