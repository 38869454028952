import React from 'react';
import TitleField from './TitleField';
import FirstNameField from './FirstNameField';
import LastNameField from './LastNameField';
import BirthDateField from './BirthDateField';

function BasicInfoRegister(props) {
    const {
        //================== title ========================
        data,
        titleData,
        onChangeData,
        validateTitle,
        titleRef,
        errortitle,

        // ==================== First Name ==========================

        validateFname,
        firstNameRef,
        errorfirst_name,

        // =====================Last Name ===========================

        validateLname,
        lastNameRef,
        errorlast_name,

        // ===================== Birth Date ===========================

        handleDateChange,
        validateBirthDate,
        birthDateRef,
        errorbirthDate,

        mystyle,
        t,
    } = props;
    console.log(props);
    return (
        <>
            <div className="registration_col form-group col-md-6 col-12">
                <div className="row g-2">
                    <TitleField
                        data={data}
                        titleData={titleData}
                        onChangeData={onChangeData}
                        validateTitle={validateTitle}
                        titleRef={titleRef}
                        errortitle={errortitle}
                        mystyle={mystyle}
                        t={t}
                    />
                    <FirstNameField
                        data={data}
                        onChangeData={onChangeData}
                        validateFname={validateFname}
                        firstNameRef={firstNameRef}
                        errorfirst_name={errorfirst_name}
                        mystyle={mystyle}
                        t={t}
                    />
                </div>
            </div>
            <LastNameField
                data={data}
                onChangeData={onChangeData}
                validateLname={validateLname}
                lastNameRef={lastNameRef}
                errorlast_name={errorlast_name}
                mystyle={mystyle}
                t={t}
            />
            <BirthDateField
                data={data}
                handleDateChange={handleDateChange}
                validateBirthDate={validateBirthDate}
                birthDateRef={birthDateRef}
                errorbirthDate={errorbirthDate}
                mystyle={mystyle}
                t={t}
            />
        </>
    )
}

export default BasicInfoRegister