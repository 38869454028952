import { useEffect, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  requestCompanyDetails,
  requestGetJobListUserId,
  requestCandidateProfile,
  requestGetCandidate
} from '../Redux/actions'
import loaderLogo from '../images/Iphone-spinner-2.gif'
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

function SavedAds (props) {
  const [jobList, setJobList] = useState([])
  const [savedListLoader, setSavedListLoader] = useState(true)
  const [companyId, setCompanyId] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token')
    const userType = localStorage.getItem('userType')
    const username = atob(localStorage.getItem('username'))
    if (token) {
      if (userType === 'employee') {
        props.requestGetCandidate({
          token: token,
          data: { username: username }
        })
      } else {
        props.requestCompanyDetails({ data: { token } })
      }
    }
  }, [])

  useEffect(() => {
    const getCompanyData = props.company.getCompanyData
    if (getCompanyData !== undefined) {
      if (getCompanyData.data?.Status === 'Success') {
        const CompanyDetails = getCompanyData?.data?.Message || {}

        if (CompanyDetails.flag_company) {
          setCompanyId(CompanyDetails?.id)
        } else {
          setUserId(CompanyDetails?.user)
        }
      } else {
        props.company.getCompanyData = undefined
      }
    } else {
      props.company.getCompanyData = undefined
    }
  }, [props.company.getCompanyData])

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (companyId) {
      const requestData = {
        company_id: companyId
      }

      if (token) {
        props.requestGetJobListUserId({ data: requestData, token })
      }
    } else if (userId) {
      const requestData = {
        spokesman_id: userId
      }

      if (token) {
        props.requestGetJobListUserId({ data: requestData, token })
      }
    }
  }, [companyId])

  useEffect(() => {
    const getCompanyData = props.company.getCompanyData
    if (getCompanyData !== undefined) {
      if (getCompanyData.data?.Status === 'Success') {
        const CompanyDetails = getCompanyData?.data?.Message || {}

        if (CompanyDetails.flag_company) {
          setCompanyId(CompanyDetails?.id)
        } else {
          setUserId(CompanyDetails?.user)
        }
      } else {
        props.company.getCompanyData = undefined
      }
    } else {
      props.company.getCompanyData = undefined
    }
  }, [props.company.getCompanyData])

  const handleSearchChange = event => {
    setSearchQuery(event.target.value)
  }

  const formatDate = dateString => {
    if (!dateString) return ''
    const date = new Date(dateString)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  const formatDate1 = dateString => {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) return ''
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  useEffect(() => {
    const jobListUserIdData = props?.candidate?.GetCandidateData
    if (jobListUserIdData !== undefined) {
      setSavedListLoader(true)
      if (jobListUserIdData.data.Status === 'Success') {
        const jobListUserIdDetails =
          jobListUserIdData?.data?.Message[0].saved_ads || []
        console.log(jobListUserIdDetails, 'jobListUserIdDetails')
        if (
          Array.isArray(jobListUserIdDetails) &&
          jobListUserIdDetails.length === 0
        ) {
          setJobList([])
        } else {
          setJobList(jobListUserIdDetails)
        }
        setSavedListLoader(false)
        props.candidate.GetCandidateData = undefined
      } else {
        setSavedListLoader(false)
        toast.error(jobListUserIdData.data.Message, {
          theme: 'colored',
          autoClose: 8000
        })
        navigate('/company/create-adv')
        props.candidate.GetCandidateData = undefined
      }
    }
    console.log(jobList, 'joblist')
  }, [props.candidate.GetCandidateData])

  const redirectOnDetails = (id) => {
    navigate("/company/advertisement-details/" + id);
}

  const filteredJobList = jobList.filter(job => {
    const jobTitle = job?.job_title ? job.job_title.toLowerCase() : ''
    const creationDate = job?.ad_creation_date
      ? formatDate(job.ad_creation_date).toLowerCase()
      : ''
    const expirationDate = job?.ad_exp_date
      ? formatDate1(job.ad_exp_date).toLowerCase()
      : ''
    const query = searchQuery.toLowerCase()

    return (
      jobTitle.includes(query) ||
      creationDate.includes(query) ||
      expirationDate.includes(query)
    )
  })

  return (
    <>
      <Header />
      <div className='advertisement_listing'>
        <div className='container'>
          <div className='advertisement_title'>
            <h3>Lista annunci salvati</h3>
          </div>
          <div
            className='advertisement_listing_inner position-relative'
            style={{ minHeight: 'calc(100vh - 280px)' }}
          >
            {savedListLoader ? (
              <div className='loader'>
                <div className='loader_inner'>
                  <img src={loaderLogo} />
                </div>
              </div>
            ) : (
              <>
                <div className='search_ads'>
                  <form>
                    <div className='row g-4'>
                      <div className='form-group col-md-6 col-12'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Ricerca annunci'
                          id='search'
                          name='search'
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </div>
                    </div>
                  </form>
                </div>

                <div className='advertisement_table table-responsive'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th scope='col'>Data di pubblicazione</th>
                        <th scope='col'>Titolo</th>
                        <th scope='col'>Azienda</th>
                        <th scope='col'>Settore</th>
                        <th scope='col'>Scadenza annuncio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredJobList.length > 0 &&
                        filteredJobList
                          .filter(value => value.published_id == null)
                          .sort((a, b) => {
                            return (
                              new Date(b.ad_creation_date) -
                              new Date(a.ad_creation_date)
                            )
                          })
                          .map((item, index) => (
                            <tr key={index}>
                              <td>
                                <p className='publication_date'>
                                  {formatDate(item.ad_creation_date)}
                                </p>
                              </td>
                              <td>
                                <a
                                  className='title'
                                  onClick={() => redirectOnDetails(item.id)}
                                >
                                  {item.job_title}
                                </a>
                              </td>
                              <td>
                                {item.company_id.name}
                              </td>
                              <td>
                              {item.company_id.type}
                              </td>
                              <td>
                                <p className='announcement_deadline'>
                                  {item.sponsor_due_date
                                    ? formatDate1(item.sponsor_due_date)
                                    : item.application_sending_deadline
                                    ? formatDate1(
                                        item.application_sending_deadline
                                      )
                                    : ''}
                                </p>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = state => {
  return { candidate: state.candidate, company: state.company }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestCompanyDetails,
      requestGetJobListUserId,
      requestCandidateProfile,
      requestGetCandidate
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(SavedAds)
