//==================================== Company =======================================

import { client as axios } from "../lib/axios";

export const companyRegister = (obj) => {
  return axios
    .post("auth/register/company", obj.data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const companyDetails = (obj) => {
  return axios
    .get("auth/register/company", {
      headers: {
        Authorization: `Bearer ${obj?.data?.token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const companyProfile = (obj) => {
  const username = {
    username: obj.data.username,
  };
  return axios
    .patch("auth/register/company", obj.data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${obj?.token}`,
      },
      params: username,
    })
    .then((response) => {
      console.log("response for company update", response);
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const packageToken = (obj) => {
  return axios
    .get("wideer/getPackages/", {
      headers: {
        Authorization: `Bearer ${obj?.data?.token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const getCreditTokens = (obj) => {
  return axios
    .get("wideer/tok", {
      headers: {
        Authorization: `Bearer ${obj?.data?.token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

//===================== All Info Token ================================

export const allInfoToken = (obj) => {
  console.log(obj, "credite");
  return axios
    .get("wideer/tok?info=all", {
      headers: {
        Authorization: `Bearer ${obj?.data?.token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

//=========================Purchage Token of Payment=================

export const purchagePaymentToken = (obj) => {
  console.log(obj, "purchagePaymentToken");
  const headers = {
    'Content-Type': 'application/json',
  };

  if (obj.token) {
    headers['Authorization'] = `Bearer ${obj.token}`;
  }

  return axios
    .post(
      'wideer/tok/',
      obj.data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.log(err, "err")
      return err.response;
    });
};

//===================== Bought Token ================================

export const getBoughtToken = (obj) => {
  console.log(obj, "credite");
  return axios
    .get("wideer/tok/bought", {
      headers: {
        Authorization: `Bearer ${obj?.data?.token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

//===================== Spent Token ================================

export const getSpentToken = (obj) => {
  console.log(obj, "credite");
  return axios
    .get("wideer/tok/spent", {
      headers: {
        Authorization: `Bearer ${obj?.data?.token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

//=========================== corresponding number of tokens================================

export const corresPondingToken = (obj) => {
  console.log(obj, "corresPondingToken");

  const headers = {
    "Content-Type": "application/json",
  };

  if (obj.token) {
    headers["Authorization"] = `Bearer ${obj.token}`;
  }

  return axios
    .post("wideer/tok/", obj.data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const CompanyJWTToken = (obj) => {
  return axios
    .post(
      "auth/t/",
      {},
      {
        headers: {
          Authorization: `Bearer ${obj?.data?.token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const GetCompanyReference = (obj) => {
  return axios
    .get("auth/login/rep/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${obj?.token}`,
      },
      params: obj.data,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const GetCVList = (obj) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (obj.token || obj.data.token) {
    headers["Authorization"] = `Bearer ${obj.token ?? obj.data.token}`;
  }

  return axios
    .get("/wideer/createResume/cvs/", {
      params: obj.data,
      headers: headers,
    })
    .then((response) => {
      console.log(response, "response");
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const createAdvertisement = (obj) => {
  console.log(obj, "advertisement Data");

  const formData = new FormData();

  if (obj.data.picture) {
    formData.append("picture", obj.data.picture);
  }

  for (const key in obj.data) {
    if (key !== "picture") {
      formData.append(key, obj.data[key]);
    }
  }

  return axios
    .post("/wideer/ads/ad/", obj.data, {
      headers: {
        Authorization: `Bearer ${obj?.token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

//patch advertisement

export const updateAdvertisement = (obj) => {
  console.log(obj, "obj*****************");

  const headers = {
    "Content-Type": "application/json",
  };

  if (obj.token) {
    headers["Authorization"] = `Bearer ${obj.token}`;
  }

  return axios
    .patch(
      `/wideer/ads/ad/${obj.id}/`,
      {},
      {
        headers: headers,
        params: obj.data,
      }
    )
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const getRectTime = (obj) => {
  return axios
    .get("/wideer/getLists/getRecTime/", {
      headers: {
        Authorization: `Bearer ${obj?.token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const getContractTypes = (obj) => {
  return axios
    .get("/wideer/getLists/getContractTypes/", {
      headers: {
        Authorization: `Bearer ${obj?.token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const getAvailabilities = (obj) => {
  return axios
    .get("/wideer/getLists/getAvailabilities/", {
      headers: {
        Authorization: `Bearer ${obj?.token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const getAdditionalWages = (obj) => {
  return axios
    .get("/wideer/getLists/getAdditionalWages/", {
      headers: {
        Authorization: `Bearer ${obj?.token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const getBenefits = (obj) => {
  return axios
    .get("/wideer/getLists/getBenefits/", {
      headers: {
        Authorization: `Bearer ${obj?.token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

//========================== Representatives =================================

export const getRepresentatives = (obj) => {
  return axios
    .get("wideer/ads/representatives/", {
      headers: {
        Authorization: `Bearer ${obj?.token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

//===================Payment Gat Way API =====================================

//======================== PAYPAL ============================================

export const createOrderPaypal = (obj) => {
  console.log(obj, "Paypal");
  return axios
    .post("wideer/paypal/create-order/ ", obj.data, {
      headers: {
        Authorization: `Bearer ${obj?.token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

//======================== STRIPE ============================================

export const createOrderStripe = (obj) => {
  console.log(obj, "Stripe");
  return axios
    .post("wideer/stripe/create-order/ ", obj.data, {
      headers: {
        Authorization: `Bearer ${obj?.token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const RetrieveSession = (obj) => {
  return axios
    .get(`wideer/retrieve-session/${obj.id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${obj.token}`,
      },
    })
    .then((response) => {
      return response.data; 
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

//======================== SATISPAY ==========================================

export const createOrderSatispay = (obj) => {
  return axios
    .post("wideer/satispay/create-order/ ", obj.data, {
      headers: {
        Authorization: `Bearer ${obj?.token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const getCVDetails = (obj) => {
  return axios
    .get(`wideer/createResume/cvs/`, {
      headers: {
        Authorization: `Bearer ${obj?.token}`,
      },
      params: obj.data,
    })
    .then((response) => {
      return response;
    });
};
