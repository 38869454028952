import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  requestGetList,
  requestGetEducationLevelList,
  requestGetJobList,
  requestGetJobListUserId,
  requestGetrecTime,
  requestGetContractTypes,
  requestGetAvailabilities,
  requestGetAdditionalWages,
  requestGetBenefits,
  requestGetRepresentatives,
  requestCreateAdvertisement,
  requestPatchAdvertisement,
  requestCompanyDetails,
  requestCompanyUpdate,
  userLogout
} from '../../Redux/actions'
import CreaAnnuncioOne from './step1/CreaAnnuncioOne'
import CreaAnnuncioTwo from './step2/CreaAnnuncioTwo'
import CreaAnnuncioThree from './step3/CreaAnnuncioThree'
import CreaAnnuncioFour from './step4/CreaAnnuncioFour'
import CreaAnnuncioFive from './step5/CreaAnnuncioFive'
import CreaAnnuncioSix from './step6/CreaAnnuncioSix'
import CreaAnnuncioSeven from './step7/CreaAnnuncioSeven'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import axios from 'axios'

function FinalForm (props) {
  const [countryData, setCountryData] = useState([])
  const [municipalities, setMunicipalities] = useState([])
  const [step, setStep] = useState(() => {
    const savedStep = localStorage.getItem('CreaAnnuncio')
    return savedStep ? Number(savedStep) : 1
  })

  const navigate = useNavigate()

  const location = useLocation()

  const isClearable = true
  const isSearchable = true
  const matchFromStart = true

  const filterConfig = {
    matchFrom: matchFromStart ? 'start' : 'any'
  }

  const { t } = useTranslation()

  const [error, setError] = useState(false)

  const [searchParams] = useSearchParams()
  const newAdvertisementId = searchParams.get('adId')
  const setStepParam = searchParams.get('setStep')

  //========================== step-1 State ==========================

  const [CreatAd_One, setCreatAd_One] = useState({
    country: 'Italia',
    province: '',
    city: '',
    street: '',
    zip_code: '',
    cellphone: '+39',
    email_for_updates: '',
    e_mail: '',
    job_title: '',
    updates_by_email: false,
    allow_phone_contact: false,
    allow_e_mail_contact: false,
    is_exp_date: false,
    isAddressComplete: false,
    n_roles_sought: '',
    application_sending_deadline: null,
    recruitment_time: '',
    privious_ad: '',
    contact_person: '',
    ad_type: '',
    ad_id: ''
  })

  const [errorCreatAd_One, setErrorCreatAd_One] = useState({
    country: '',
    province: '',
    city: '',
    street: '',
    zip_code: '',
    cellphone: '',
    email_for_updates: '',
    e_mail: '',
    job_title: '',
    updates_by_email: '',
    allow_phone_contact: '',
    allow_e_mail_contact: '',
    is_exp_date: '',
    isAddressComplete: true,

    n_roles_sought: '',
    application_sending_deadline: '',
    recruitment_time: '',
    // privious_ad: "",
    contact_person: ''
  })

  const [addressDetails, setAddressDetails] = useState(null)

  const [CreatAd_One_Loader, setCreatAd_One_Loader] = useState(true)

  // const [newAdvertisementId, setNewAdvertisementId] = useState(null);

  const previousAddRef = useRef(null)
  const jobTitleRef = useRef(null)
  const rolesSoughtRef = useRef(null)
  const countryRef = useRef(null)
  const cityRef = useRef(null)
  const provinceRef = useRef(null)
  const streetRef = useRef(null)
  const zipCodeRef = useRef(null)
  const contactPersonRef = useRef(null)
  const applicationSendingDeadlineRef = useRef(null)
  const recruitmentTimeRef = useRef(null)
  const emailRef = useRef(null)
  const addemailRef = useRef(null)
  const phoneRef = useRef(null)

  const addressRef = useRef(null)
  const birthDateRef = useRef(null)

  const [selectedCity, setSelectedCity] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [recruitmentTimeline, setRecruitmentTimeline] = useState([])

  const [jobList, setJobList] = useState([])

  const [previousAdd, setPreviousAdd] = useState([])

  const [companyId, setCompanyId] = useState(null)
  const [userId, setUserId] = useState(null)

  const [representative, setRepresentative] = useState([])

  //========================== step-2 State==========================

  const [CreatAd_Two, setCreatAd_Two] = useState({
    educationLevels: '',
    driverlicense: [],
    job_starting_date: '',
    isStartDateVisible: true
  })

  const [errorCreatAd_Two, setErrorCreatAd_Two] = useState({
    educationLevels: '',
    subeducationLevels: '',
    driverlicense: '',
    job_starting_date: '',
    isStartDateVisible: true
  })

  const [educationLevels, setEducationLevels] = useState([])
  const [subSubmenuOptions, setSubSubmenuOptions] = useState([])

  const [driverlicenseData, setDriverlicenseData] = useState([])

  const [selectedOptionsDrivelicense, setSelectedOptionsDrivelicense] =
    useState([])

  const educationLevelRef = useRef(null)

  const subEducationalRef = useRef(null)

  const driverLicenseRef = useRef(null)
  const jobStartingDateRef = useRef(null)

  const [selectedOptionsEducation, setSelectedOptionsEducation] = useState({
    submenu: '',
    subSubmenu: ''
  })

  //========================== step-3 State ==========================

  const positionErrorRef = useRef(null)
  const fileErrorRef = useRef(null)
  const [CreatAd_Three, setCreatAd_Three] = useState({
    job_title: '',
    selectedFile: '',
    desc: ''
  })

  const [errorCreatAd_Three, setErrorCreatAd_Three] = useState({
    job_title: '',
    selectedFile: null,
    desc: ''
  })

  //========================== step-4 State ==========================

  const [CreatAd_Four, setCreatAd_Four] = useState({
    contract_type: [],
    contract_duration_n: '',
    contract_duration_period: '',
    availability: []
  })

  const [errorCreatAd_Four, setErrorCreatAd_Four] = useState({
    contract_type: '',
    contract_duration_n: '',
    contract_duration_period: '',
    availability: ''
  })

  const contractTypeRef = useRef(null)
  const contractDurationRef = useRef(null)
  const durationStringRef = useRef(null)
  const availabilityRef = useRef(null)

  const [contractTypesData, setContractTypesData] = useState([])
  const [availabilitiesData, setAvailabilitiesData] = useState([])

  const [selectedOptionsContractType, setSelectedOptionsContractType] =
    useState([])

  const [selectedOptionsAvailabilityType, setSelectedOptionsAvailabilityType] =
    useState([])

  //========================== step-5  State==========================

  const [CreatAd_Five, setCreatAd_Five] = useState({
    fixedAmount: '',
    salary_recurrence: '',
    minAmount: '',
    maxAmount: '',
    additional_wages: [],
    benefit: [],
    range: false
  })

  const [errorCreatAd_Five, setErrorCreatAd_Five] = useState({
    additional_wages: '',
    benefit: '',
    fixedAmount: '',
    salary_recurrence: '',
    minAmount: '',
    maxAmount: '',
    range: ''
  })

  const minAmountRef = useRef(null)
  const maxAmountRef = useRef(null)
  const salaryRecurrenceRef = useRef(null)
  const fixedAmountRef = useRef(null)
  const additionalWagesRef = useRef(null)
  const benefitRef = useRef(null)

  const [additionalWagesData, setAdditionalWagesData] = useState([])
  const [benefitsData, setBenefitsData] = useState([])

  const [selectedOptionsAdditionalWages, setSelectedOptionsAdditionalWages] =
    useState([])

  const [selectedOptionsBenefits, setSelectedOptionsBenefits] = useState([])

  //========================== step-6 State ==========================

  const [CreatAd_Six, setCreatAd_Six] = useState([
    {
      question: ''
    }
  ])

  const [errorCreatAd_Six, setErrorCreatAd_Six] = useState([
    {
      question: ''
    }
  ])

  const formFocusQuestionRefs = {
    question: useRef([])
  }

  //========================== step-7 State ==========================

  const [CreatAd_Seven, setCreatAd_Seven] = useState({
    spent: '',
    sponsor_due_date: '',
    sponsor_recurrency: ''
  })

  const [errorCreatAd_Seven, setErrorCreatAd_Seven] = useState({
    spent: '',
    sponsor_due_date: '',
    sponsor_recurrency: ''
  })

  const mystyle = {
    color: '#D10000',
    padding: '3px 10px',
    width: '100%'
  }

  const spentRef = useRef(null)
  const sponsorRecurrencyRef = useRef(null)
  const sponsorDueDateRef = useRef(null)

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      props.requestGetJobList({ token })
      props.requestGetrecTime({ token })
      props.requestGetContractTypes({ token })
      props.requestGetAvailabilities({ token })
      props.requestGetAdditionalWages({ token })
      props.requestGetBenefits({ token })
      props.requestGetRepresentatives({ token })
      props.requestCompanyDetails({ data: { token } })
    }
    props.requestGetList()
    props.requestGetEducationLevelList()
  }, [])

  useEffect(() => {
    const getCompanyData = props.company.getCompanyData
    console.log('getCompanyData', getCompanyData)
    if (getCompanyData !== undefined) {
      if (getCompanyData.data?.Status === 'Success') {
        const CompanyDetails = getCompanyData?.data?.Message || {}
        console.log('CompanyDetails', CompanyDetails)
        // const representativeData = CompanyDetails?.representative || [];
        // console.log(CompanyDetails, "CompanyDetails")

        const addressDetails = CompanyDetails?.address
        if (addressDetails) {
          setAddressDetails(addressDetails[0])
        }

        setCreatAd_One(prevState => ({
          ...prevState,
          country: CompanyDetails.address?.[0]?.country || '',
          street: CompanyDetails.address?.[0]?.street || '',
          city: CompanyDetails.address?.[0]?.city || '',
          province: CompanyDetails.address?.[0]?.province || '',
          zip_code: CompanyDetails.address?.[0]?.zip_code || '',
          email_for_updates: CompanyDetails?.e_mail,
          // e_mail: CompanyDetails.e_mail || "",
          updates_by_email: CompanyDetails.e_mail_verified
        }))

        // console.log(representativeData,"representativeData")

        // const transformedRepresentativeData = representativeData?.map((item, index) => ({
        //     value: item.id,
        //     label: item.e_mail,
        // })) || [];

        // setRepresentative(transformedRepresentativeData);

        if (CompanyDetails.flag_company) {
          setCompanyId(CompanyDetails?.id)
        } else {
          // setCompanyId()
        }
      } else {
        // props.company.getCompanyData = undefined;
      }
    } else {
      // props.company.getCompanyData = undefined;
    }
  }, [props.company.getCompanyData])

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (companyId) {
      const requestData = {
        company_id: companyId
      }
      if (token) {
        props.requestGetJobListUserId({ data: requestData, token })
      }
    } else if (userId) {
      const requestData = {
        spokesman_id: userId
      }
      if (token) {
        props.requestGetJobListUserId({ data: requestData, token })
      }
    }
  }, [companyId])

  useEffect(() => {
    const jobListUserIdData = props?.candidate?.GetJobListUserId
    if (jobListUserIdData !== undefined) {
      setCreatAd_One_Loader(true)
      if (jobListUserIdData.data.Status === 'Success') {
        const jobListUserIdDetails = jobListUserIdData?.data?.Message || []

        const filteredJobListUserIdDetails =
          jobListUserIdDetails?.filter(item => item?.job_title !== null) || []

        const transformedjobListUserIdDetails =
          filteredJobListUserIdDetails.map((item, index) => ({
            value: item.id,
            label: item.job_title,
            data: item
          }))

        setPreviousAdd(transformedjobListUserIdDetails)
        setCreatAd_One_Loader(false)
      } else {
        // toast.error(jobListUserIdData?.data?.Message, { theme: "colored", autoClose: 1000 });
        setCreatAd_One_Loader(false)
        props.candidate.GetJobListUserId = undefined
      }
    }
  }, [props.candidate.GetJobListUserId])

  useEffect(() => {
    let GetJobListData = props.candidate.GetJobListUserId

    if (GetJobListData !== undefined) {
      setIsLoading(false)
      if (GetJobListData?.data?.Status === 'Success') {
        const transformedJoblistData =
          GetJobListData?.data?.Message?.filter(
            job => job?.job_title !== null
          ).map((job, index) => ({
            value: index,
            label: job?.job_title
          })) || []

        setIsLoading(true)
        setJobList(transformedJoblistData)
      } else {
        setJobList([])
        setIsLoading(true)
      }
    }
  }, [props.candidate.GetJobListData])

  useEffect(() => {
    let levelData = props.candidate.getlistData
    if (levelData !== undefined) {
      if (levelData?.data?.Status === 'Success') {
        const processedCountries = levelData.data.Message.countries.map(
          item => {
            const name = item.nativeCountryName
            return name
          }
        )
        const uniqueCountries = [...new Set(processedCountries)]
        const uniqueCountryData = uniqueCountries.map(name => ({
          nativeCountryName: name
        }))

        setCountryData(uniqueCountryData)

        const municipalit = levelData?.data?.Message?.municipalities
        const uniqueMunicipalities = []
        const municipalityNames = new Set()

        municipalit.forEach(municipality => {
          if (!municipalityNames.has(municipality.name)) {
            municipalityNames.add(municipality.name)
            const municipalityObject = {
              name: `${municipality.name}, ${municipality.provincia.name}`
            }
            uniqueMunicipalities.push(municipalityObject)
          }
        })

        const transformedCityData =
          uniqueMunicipalities?.map((city, index) => ({
            value: index,
            label: city?.name
          })) || []

        setMunicipalities(transformedCityData)

        const transformedDriverLicenseData =
          levelData.data.Message.drivingLicences.map(license => ({
            value: license.id,
            label: license.licenceType
          }))
        setDriverlicenseData(transformedDriverLicenseData)
      }
    }
  }, [props.candidate.getlistData])

  const sortedCountryData = [...countryData].sort((a, b) => {
    if (a.nativeCountryName === 'Italia') return -1
    if (b.nativeCountryName === 'Italia') return 1
    return 0
  })

  useEffect(() => {
    const {
      getRecTime,
      getContractTypes,
      getAvailabilities,
      getAdditionalWages,
      getBenefits,
      getRepresentatives
    } = props.company

    if (getRecTime && getRecTime.data?.Status === 'Success') {
      const transformedRecruitmentTimeline =
        getRecTime?.data?.Message?.filter(time => time?.time !== null).map(
          (time, index) => ({
            value: index,
            label: time.time
          })
        ) || []
      setRecruitmentTimeline(transformedRecruitmentTimeline)
    }

    if (getContractTypes && getContractTypes.data?.Status === 'Success') {
      const transformedContractTypesData =
        getContractTypes.data.Message?.map((contract, index) => ({
          value: index,
          label: contract.contract_name
        })) || []
      setContractTypesData(transformedContractTypesData)
    }

    if (getAvailabilities && getAvailabilities.data?.Status === 'Success') {
      const transformedAvailabilitiesData =
        getAvailabilities.data.Message?.map((availability, index) => ({
          value: index,
          label: availability.availability_type
        })) || []
      setAvailabilitiesData(transformedAvailabilitiesData)
    }

    if (getAdditionalWages && getAdditionalWages.data?.Status === 'Success') {
      const transformedAdditionalWagesData =
        getAdditionalWages.data.Message?.map((additional, index) => ({
          value: index,
          label: additional.additional_wage
        })) || []
      setAdditionalWagesData(transformedAdditionalWagesData)
    }

    if (getBenefits && getBenefits.data?.Status === 'Success') {
      const transformedBenefitsData =
        getBenefits.data.Message?.map((benefit, index) => ({
          value: index,
          label: benefit.benefit
        })) || []
      setBenefitsData(transformedBenefitsData)
    }

    if (getRepresentatives && getRepresentatives.data?.Status === 'Success') {
      const transformedRepresentativesData =
        getRepresentatives.data.Message?.reps?.map((rep, index) => ({
          value: rep.id,
          label: rep.e_mail,
          cellphone: rep.cellphone
        })) || []

      setRepresentative(transformedRepresentativesData)
    }
  }, [
    props.company.getRecTime,
    props.company.getContractTypes,
    props.company.getAvailabilities,
    props.company.getAdditionalWages,
    props.company.getBenefits,
    props.company.getRepresentatives
  ])

  //get education leve data

  useEffect(() => {
    const educationlevelData = props.candidate.EducationlevelData
    if (educationlevelData?.data?.Status === 'Success') {
      const uniqueLevels = {}

      educationlevelData.data.Message.forEach(level => {
        if (!uniqueLevels[level.levelType]) {
          uniqueLevels[level.levelType] = {
            value: level.id,
            label: level.levelType
          }
        }
      })
      const transformedEducationlevelData = Object.values(uniqueLevels)
      setEducationLevels(transformedEducationlevelData)

      const filteredSubMenuOptions = educationlevelData.data.Message.filter(
        level => level.specialisation
      ).map(level => ({
        levelType: level.levelType,
        specialisation: level.specialisation
      }))

      setSubSubmenuOptions(filteredSubMenuOptions)
    }
  }, [props.candidate.EducationlevelData])

  useEffect(() => {})

  //=====================================STEP 1 Validateion  ==========================================

  const validateJobTitle = () => {
    const jobTitle = CreatAd_One?.job_title

    if (!jobTitle) {
      setErrorCreatAd_One(prev => ({
        ...prev,
        job_title: 'Seleziona una posizione ricercata.'
      }))
      return false
    }

    setErrorCreatAd_One(prev => ({ ...prev, job_title: '' }))
    return true
  }

  const contactpersonValidate = () => {
    const priviousAd = CreatAd_One?.contact_person
    if (!priviousAd) {
      setErrorCreatAd_One(prev => ({
        ...prev,
        contact_person: 'internal person required must'
      }))
      return false
    } else {
      setErrorCreatAd_One(prevState => ({
        ...prevState,
        contact_person: ''
      }))

      return true
    }
  }
  const validateEmail = () => {
    const email = emailRef.current.value
    if (!email) {
      setErrorCreatAd_One(prev => ({
        ...prev,
        email_for_updates: 'Inserisci la tua e-mail.'
      }))
      return false
    }
    const trimmedEmail = email.trim()
    if (!trimmedEmail) {
      setErrorCreatAd_One(prev => ({
        ...prev,
        email_for_updates: "L'e-mail non può essere vuota."
      }))
      return false
    }
    if (
      !trimmedEmail.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    ) {
      setErrorCreatAd_One(prev => ({
        ...prev,
        email_for_updates: 'Inserisci un ID e-mail valido.'
      }))
      return false
    }
    setErrorCreatAd_One(prev => ({
      ...prev,
      email_for_updates: ''
    }))
    return true
  }

  const validateAddEmail = () => {
    const email = addemailRef.current.value
    if (!email) {
      setErrorCreatAd_One(prev => ({
        ...prev,
        e_mail: 'Inserisci la tua e-mail.'
      }))
      return false
    }
    const trimmedEmail = email.trim()
    if (!trimmedEmail) {
      setErrorCreatAd_One(prev => ({
        ...prev,
        e_mail: "L'e-mail non può essere vuota."
      }))
      return false
    }
    if (
      !trimmedEmail.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    ) {
      setErrorCreatAd_One(prev => ({
        ...prev,
        e_mail: 'Inserisci un ID e-mail valido.'
      }))
      return false
    }
    setErrorCreatAd_One(prev => ({ ...prev, e_mail: '' }))
    return true
  }

  function validateZipCode () {
    const zip_code = CreatAd_One.zip_code

    if (!zip_code) {
      setErrorCreatAd_One(prevState => ({
        ...prevState,
        zip_code: 'Inserisci il tuo Cap.'
      }))
      return false
    }

    const trimmedZipcode = zip_code.trim()

    if (!trimmedZipcode) {
      setErrorCreatAd_One(prevState => ({
        ...prevState,
        zip_code: 'Il CAP non può essere vuoto.'
      }))
      return false
    }

    if (!/^\d+$/.test(trimmedZipcode)) {
      setErrorCreatAd_One(prevState => ({
        ...prevState,
        zip_code: 'Il CAP può contenere solo numeri.'
      }))
      return false
    }

    if (trimmedZipcode.length < 5) {
      setErrorCreatAd_One(prevState => ({
        ...prevState,
        zip_code: 'Il CAP deve contenere almeno 5 cifre.'
      }))
      return false
    }

    setErrorCreatAd_One(prevState => ({
      ...prevState,
      zip_code: ''
    }))

    return true
  }

  const validateCountry = () => {
    const { country } = CreatAd_One
    if (!country) {
      setErrorCreatAd_One(prevState => ({
        ...prevState,
        country: 'Nazione non selezionato.'
      }))
      return false
    }

    setErrorCreatAd_One(prevState => ({
      ...prevState,
      country: ''
    }))
    return true
  }

  const validateCity = () => {
    let city

    if (CreatAd_One?.country === 'Italia') {
      if (CreatAd_One !== 'undefined') {
        if (!CreatAd_One?.city || !CreatAd_One?.province) {
          setErrorCreatAd_One(prev => ({
            ...prev,
            city: 'Comune non selezionato.'
          }))
          return false
        }
      }
    } else {
      if (cityRef?.current) {
        city = cityRef.current.value ?? ''
        if (!city.trim()) {
          setErrorCreatAd_One(prev => ({
            ...prev,
            city: 'Inserisci la tua Comune.'
          }))
          return false
        }
      }
    }
    setErrorCreatAd_One(prev => ({
      ...prev,
      city: ''
    }))
    return true
  }

  function validateProvince () {
    if (provinceRef?.current !== null) {
      const province = provinceRef?.current?.value
      if (!province) {
        setErrorCreatAd_One(prev => ({
          ...prev,
          province: 'Inserisci la tua provincia.'
        }))
        return false
      } else {
        setErrorCreatAd_One(prev => ({
          ...prev,
          province: ' '
        }))
        return true
      }
    } else {
      setErrorCreatAd_One(prev => ({
        ...prev,
        province: ''
      }))
      return true
    }
  }

  const validatePhone = () => {
    let phone_no = phoneRef?.current?.props.value
    if (!phone_no) {
      setErrorCreatAd_One(prev => ({
        ...prev,
        cellphone: 'Inserisci il numero di telefono.'
      }))

      return false
    } else if (phone_no.length < 5) {
      setErrorCreatAd_One(prev => ({
        ...prev,
        cellphone: 'Inserisci il numero di telefono.'
      }))
      return false
    } else {
      setErrorCreatAd_One(prev => ({
        ...prev,
        cellphone: ''
      }))
      return true
    }
  }

  const validateNumberOfRoles = () => {
    const { n_roles_sought } = CreatAd_One

    if (!n_roles_sought) {
      setErrorCreatAd_One(prevState => ({
        ...prevState,
        n_roles_sought: 'l numero di ruoli ricercati non può essere vuoto.'
      }))
      return false
    } else {
      setErrorCreatAd_One(prevState => ({
        ...prevState,
        n_roles_sought: ''
      }))
      return true
    }
  }

  function validateStreetNumber () {
    const street_number = CreatAd_One?.street

    if (!street_number) {
      setErrorCreatAd_One(prevErrors => ({
        ...prevErrors,
        street: 'Inserisci il numero civico.'
      }))
      return false
    }

    if (street_number === '') {
      setErrorCreatAd_One(prevErrors => ({
        ...prevErrors,
        street: 'Il numero civico non può essere vuoto.'
      }))
      return false
    }

    setErrorCreatAd_One(prevErrors => ({
      ...prevErrors,
      street: ''
    }))
    return true
  }

  const validateAdExpDate = () => {
    const { application_sending_deadline } = CreatAd_One
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    if (!application_sending_deadline && CreatAd_One.is_exp_date) {
      setErrorCreatAd_One(prevState => ({
        ...prevState,
        application_sending_deadline: 'Inserisci una data di scadenza valida.'
      }))
      return false
    } else {
      setErrorCreatAd_One(prevState => ({
        ...prevState,
        application_sending_deadline: ''
      }))
      return true
    }
  }

  const validateTimelinePosition = () => {
    const recruitment_time = CreatAd_One?.recruitment_time
    if (!recruitment_time) {
      setErrorCreatAd_One(prevErrors => ({
        ...prevErrors,
        recruitment_time: 'Recruitment timeline cannot be empty'
      }))
      return false
    }
    setErrorCreatAd_One(prevErrors => ({
      ...prevErrors,
      recruitment_time: ''
    }))
    return true
  }
  // const mobilToggle = () => {
  //     if (CreatAd_One?.allow_phone_contact === false) {
  //         setErrorCreatAd_One((prevErrors) => ({
  //             ...prevErrors,
  //             allow_phone_contact: "Mobile number toggle true must required must",
  //         }));
  //         return false;
  //     } else {
  //         setErrorCreatAd_One((prevErrors) => ({
  //             ...prevErrors,
  //             allow_phone_contact: "",
  //         }));
  //         return true;
  //     }
  // };
  // const emailToggle = () => {
  //     if (CreatAd_One?.allow_e_mail_contact === false) {
  //         setErrorCreatAd_One((prevErrors) => ({
  //             ...prevErrors,
  //             allow_e_mail_contact: "email toggle true must required must",
  //         }));
  //         return false;
  //     } else {
  //         setErrorCreatAd_One((prevErrors) => ({
  //             ...prevErrors,
  //             allow_e_mail_contact: "",
  //         }));
  //         return true;
  //     }
  // };
  // const dateToggle = () => {
  //     if (CreatAd_One?.is_exp_date === false) {
  //         setErrorCreatAd_One((prevErrors) => ({
  //             ...prevErrors,
  //             is_exp_date: "date toggle true must required must",
  //         }));
  //         return false;
  //     } else {
  //         setErrorCreatAd_One((prevErrors) => ({
  //             ...prevErrors,
  //             is_exp_date: "",
  //         }));
  //         return true;
  //     }
  // };
  const emailVerificationToggle = () => {
    if (CreatAd_One?.updates_by_email === false) {
      setErrorCreatAd_One(prevErrors => ({
        ...prevErrors,
        updates_by_email: 'email verification toggle true must required must'
      }))
      return false
    } else {
      setErrorCreatAd_One(prevErrors => ({
        ...prevErrors,
        updates_by_email: ''
      }))
      return true
    }
  }
  // const isAddress = () => {
  //     if (CreatAd_One?.isAddressComplete === false) {
  //         setErrorCreatAd_One((prevErrors) => ({
  //             ...prevErrors,
  //             isAddressComplete: "Address toggle true must required must",
  //         }));
  //         return false;
  //     }
  //     else {
  //         setErrorCreatAd_One((prevErrors) => ({
  //             ...prevErrors,
  //             isAddressComplete: "",
  //         }));
  //         return true;
  //     }
  // };
  //=====================================STEP 2 Validateion  ==========================================

  const validateEducationLevel = () => {
    const educationLevelsData = CreatAd_Two?.educationLevels;
    /*if (!educationLevelsData) {
      setErrorCreatAd_Two(prev => ({
        ...prev,
        educationLevels: 'Seleziona o inserisci il tuo titolo di studio.'
      }))
      return false
    }
    setErrorCreatAd_Two(prev => ({ ...prev, educationLevels: '' }))*/
    return true;
  }

  const validateSubEducationLevel = () => {
    if (subEducationalRef?.current) {
      const subEducationalData = subEducationalRef?.current?.value
      if (!subEducationalData) {
        setErrorCreatAd_Two(prev => ({
          ...prev,
          subeducationLevels: "Seleziona il titolo dell'annuncio."
        }))
        return false
      }
    }
    setErrorCreatAd_Two(prev => ({
      ...prev,
      subeducationLevels: ''
    }))
    return true
  }

  const validateDateTwo = () => {
    if (CreatAd_Two.isStartDateVisible != false) {
      const job_starting_dateData = CreatAd_Two?.job_starting_date
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      if (!job_starting_dateData) {
        setErrorCreatAd_Two(prev => ({
          ...prev,
          job_starting_date: 'Inserisci una data valida.'
        }))
        return false
      }

      setErrorCreatAd_Two(prev => prev => ({ ...prev, job_starting_date: '' }))
      return true
    } else {
      return true
    }
  }

  const validateCreateTwoDriverlicense = () => {
    /*if (selectedOptionsDrivelicense?.length === 0) {
      setErrorCreatAd_Two(prevErrors => ({
        ...prevErrors,
        driverlicense: 'Seleziona almeno una patente di guida.'
      }))
      return false
    } else {
      setErrorCreatAd_Two(prevErrors => ({
        ...prevErrors,
        driverlicense: ''
      }))
      return true
    }*/
   return true;
  }

  //=====================================STEP 3 Validateion  ==========================================

  const validateFormthirdJobTitle = () => {
    const { job_title } = CreatAd_Three
    if (!job_title) {
      setErrorCreatAd_Three(prevErrors => ({
        ...prevErrors,
        job_title: 'Please select at least one option.'
      }))
      return false
    } else {
      setErrorCreatAd_Three(prevErrors => ({
        ...prevErrors,
        job_title: ''
      }))
      return true
    }
  }

  // const validatethirdFormFile = () => {
  //     const { selectedFile } = CreatAd_Three;
  //     if (!selectedFile) {
  //         setErrorCreatAd_Three((prevErrors) => ({
  //             ...prevErrors,
  //             selectedFile: "Il file è obbligatorio",
  //         }));
  //         return false
  //     } else {
  //         setErrorCreatAd_Three((prevErrors) => ({
  //             ...prevErrors,
  //             selectedFile: null,
  //         }));
  //         return true
  //     }
  // }

  const validatethirdFormFile = () => {
    const { selectedFile } = CreatAd_Three
    /*if (!selectedFile) {
      setErrorCreatAd_Three(prevErrors => ({
        ...prevErrors,
        selectedFile: 'Il file è obbligatorio'
      }))
      return false
    }*/

    const allowedExtensions = ['jpg', 'jpeg', 'png'];
    if(selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase()

      if (!allowedExtensions.includes(fileExtension)) {
        setErrorCreatAd_Three(prevErrors => ({
          ...prevErrors,
          selectedFile:
            'Sono consentiti solo file con estensione .jpg, .jpeg, .png'
        }))
        return false
      }

      setErrorCreatAd_Three(prevErrors => ({
        ...prevErrors,
        selectedFile: null
      }))
    }
    return true
  }

  const validateFormThirdCkeditor = () => {
    const { desc } = CreatAd_Three
    if (!desc) {
      setErrorCreatAd_Three(prevErrors => ({
        ...prevErrors,
        desc: 'La descrizione è obbligatoria'
      }))
      return false
    } else {
      setErrorCreatAd_Three(prevErrors => ({
        ...prevErrors,
        desc: ''
      }))
      return true
    }
  }

  //=====================================STEP 4 Validateion  ==========================================

  const validateContractType = () => {
    if (selectedOptionsContractType?.length === 0) {
      setErrorCreatAd_Four(prevErrors => ({
        ...prevErrors,
        contract_type: 'Il tipo di contratto è obbligatorio'
      }))
      return false
    } else {
      setErrorCreatAd_Four(prevErrors => ({
        ...prevErrors,
        contract_type: ''
      }))
      return true
    }
  }

  const validateAvailability = () => {
    /*if (selectedOptionsAvailabilityType?.length === 0) {
      setErrorCreatAd_Four(prevErrors => ({
        ...prevErrors,
        availability: 'La disponibilità è obbligatoria'
      }))
      return false
    } else {
      setErrorCreatAd_Four(prevErrors => ({
        ...prevErrors,
        availability: ''
      }))
      return true
    }*/
   return true;
  }

  const validateDuration = () => {
    const { contract_duration_n, contract_duration_period } = CreatAd_Four

    if (contract_duration_n) {
      if (!/^\d+$/.test(contract_duration_n)) {
        setErrorCreatAd_Four(prevErrors => ({
          ...prevErrors,
          contract_duration_n: 'La durata deve essere un numero intero'
        }))
        return false
      }
    }

    if (contract_duration_n || contract_duration_period) {
      if (!contract_duration_n && contract_duration_period) {
        setErrorCreatAd_Four(prevErrors => ({
          ...prevErrors,
          contract_duration_n:
            'Selezionare anche la durata se si sceglie una durata del contratto'
        }))
        return false
      }

      if (!contract_duration_period && contract_duration_n) {
        setErrorCreatAd_Four(prevErrors => ({
          ...prevErrors,
          contract_duration_period:
            'Se si inserisce una durata, selezionare anche la durata del contratto'
        }))
        return false
      }
    }

    setErrorCreatAd_Four(prevErrors => ({
      ...prevErrors,
      contract_duration_n: '',
      contract_duration_period: ''
    }))

    return true
  }

  //=====================================STEP 5 Validateion  ==========================================

  const validateForFixAmount = () => {
    const { fixedAmount } = CreatAd_Five
    if (CreatAd_Five?.range === false) {
      if (!fixedAmount) {
        setErrorCreatAd_Five(prevErrors => ({
          ...prevErrors,
          fixedAmount: 'This field is required.'
        }))
        return false
      } else {
        setErrorCreatAd_Five(prevErrors => ({
          ...prevErrors,
          fixedAmount: ''
        }))
        return true
      }
    } else {
      setErrorCreatAd_Five(prevErrors => ({
        ...prevErrors,
        fixedAmount: ''
      }))
      return true
    }
  }

  const validateForSalaryRecurrence = () => {
    const { salary_recurrence } = CreatAd_Five
    if (!salary_recurrence) {
      setErrorCreatAd_Five(prevErrors => ({
        ...prevErrors,
        salary_recurrence: 'This field is required.'
      }))
      return false
    } else {
      setErrorCreatAd_Five(prevErrors => ({
        ...prevErrors,
        salary_recurrence: ''
      }))
      return true
    }
  }

  const validateForMinAmount = () => {
    const { minAmount } = CreatAd_Five
    if (CreatAd_Five?.range === true) {
      if (!minAmount) {
        setErrorCreatAd_Five(prevErrors => ({
          ...prevErrors,
          minAmount: 'This field is required.'
        }))
        return false
      } else {
        setErrorCreatAd_Five(prevErrors => ({
          ...prevErrors,
          minAmount: ''
        }))
        return true
      }
    } else {
      setErrorCreatAd_Five(prevErrors => ({
        ...prevErrors,
        minAmount: ''
      }))
      return true
    }
  }

  const validateForMaxAmount = () => {
    const { maxAmount } = CreatAd_Five

    if (CreatAd_Five?.range === true) {
      if (!maxAmount) {
        setErrorCreatAd_Five(prevErrors => ({
          ...prevErrors,
          maxAmount: 'This field is required.'
        }))
        return false
      } else {
        setErrorCreatAd_Five(prevErrors => ({
          ...prevErrors,
          maxAmount: ''
        }))
        return true
      }
    } else {
      setErrorCreatAd_Five(prevErrors => ({
        ...prevErrors,
        maxAmount: ''
      }))
      return true
    }
  }

  const validateAdditionalWages = () => {
    /*if (selectedOptionsAdditionalWages?.length === 0) {
            setErrorCreatAd_Five((prevErrors) => ({
                ...prevErrors,
                additional_wages: "Il tipo di contratto è obbligatorio",
            }));
            return false;
        } else {
            setErrorCreatAd_Five((prevErrors) => ({
                ...prevErrors,
                additional_wages: "",
            }));
            return true;
        }*/
    return true
  }

  const validateBenefit = () => {
    /*if (selectedOptionsBenefits?.length === 0) {
      setErrorCreatAd_Five(prevErrors => ({
        ...prevErrors,
        benefit: 'Il tipo di contratto è obbligatorio'
      }))
      return false
    } else {
      setErrorCreatAd_Five(prevErrors => ({
        ...prevErrors,
        benefit: ''
      }))
      return true
    }*/
   return true;
  }

  //=====================================STEP 6 Validateion  ==========================================

  function validateFormSixQuestion (i) {
    let formIsValid = true

    const questionsFilled =
      formFocusQuestionRefs?.question?.current[i]?.question?.value

    if (!questionsFilled) {
      formIsValid = false
      const updateErrorQuestions = [...errorCreatAd_Six]
      updateErrorQuestions[i].question = 'Inserisci il tuo nome.'
      setErrorCreatAd_Six(updateErrorQuestions)
    }

    const trimmedQuestions = questionsFilled.trim()

    if (formIsValid && !trimmedQuestions) {
      formIsValid = false
      const updateErrorQuestions = [...errorCreatAd_Six]
      updateErrorQuestions[i].question = 'Il nome non può essere vuoto.'
      setErrorCreatAd_Six(updateErrorQuestions)
    }

    if (formIsValid) {
      const updateErrorQuestions = [...errorCreatAd_Six]
      updateErrorQuestions.question = ''
      setErrorCreatAd_Six(updateErrorQuestions)
    }

    return formIsValid
  }

  //=====================================STEP 7 Validateion  ==========================================

  const validateAdSponsorDate = () => {
    const { sponsor_due_date } = CreatAd_Seven
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    if (!sponsor_due_date) {
      setErrorCreatAd_Seven(prevState => ({
        ...prevState,
        sponsor_due_date: 'Inserisci una data di scadenza valida.'
      }))
      return false
    } else if (new Date(sponsor_due_date).getTime() <= today.getTime()) {
      setErrorCreatAd_Seven(prevState => ({
        ...prevState,
        sponsor_due_date: 'Inserisci una data di scadenza valida.'
      }))

      return false
    } else {
      setErrorCreatAd_Seven(prevState => ({
        ...prevState,
        sponsor_due_date: ''
      }))
      return true
    }
  }

  const validateforSpent = () => {
    const { spent } = CreatAd_Seven
    if (!spent) {
      setErrorCreatAd_Seven(prevState => ({
        ...prevState,
        spent: 'Inserisci il tuo spent'
      }))
      return false
    } else {
      setErrorCreatAd_Seven(prevState => ({
        ...prevState,
        spent: ''
      }))
      return true
    }
  }

  const validateForSponsorRecurrency = () => {
    const { sponsor_recurrency } = CreatAd_Seven
    if (!sponsor_recurrency) {
      setErrorCreatAd_Seven(prevState => ({
        ...prevState,
        sponsor_recurrency: 'Inserisci il tuo sponsor recurrency'
      }))
      return false
    } else {
      setErrorCreatAd_Seven(prevState => ({
        ...prevState,
        sponsor_recurrency: ''
      }))
      return true
    }
  }

  //=====================================STEP 1 Function==========================================

  const handleOnCreatAd_One = e => {
    const { name, value, type } = e.target
    let newValue

    if (type === 'number') {
      newValue = value.replace(/\D/g, '').slice(0, 5)
    } else {
      newValue = value
    }

    if (name === 'country') {
      if (value === 'Italia') {
        setCreatAd_One(prevData => ({
          ...prevData,
          country: newValue,
          city: '',
          province: ''
        }))
      } else {
        setCreatAd_One(prevData => ({
          ...prevData,
          country: newValue,
          city: '',
          province: ''
        }))
      }
    } else {
      setCreatAd_One(prevData => ({ ...prevData, [name]: newValue }))
    }
  }

  const handleOnCreatAdJobTitle = selectOption => {
    if (selectOption !== null) {
      const newData = selectOption.label ? selectOption?.label : ''
      setCreatAd_One(prevData => ({ ...prevData, job_title: newData }))
      setCreatAd_Three(prevData => ({ ...prevData, job_title: newData }))
    } else {
      setCreatAd_One(prevData => ({ ...prevData, job_title: '' }))
      setCreatAd_Three(prevData => ({ ...prevData, job_title: '' }))
    }
  }

  const handleOnAddressSelect = selectAddress => {
    if (selectAddress !== null) {
      const newData = selectAddress.addressDetails
        ? selectAddress?.addressDetails
        : ''
      setCreatAd_One(prevData => ({
        ...prevData,
        city: newData.city,
        country: newData.country,
        province: newData.province,
        street: newData.street,
        zip_code: newData.zip_code,
        state: newData.state
      }))
    } else {
      setCreatAd_One(prevData => ({
        ...prevData,
        city: '',
        country: '',
        province: '',
        street: '',
        zip_code: '',
        state: ''
      }))
    }
  }

  const handleOnPreviousAdvertisement = selectOption => {
    if (selectOption !== null) {
      const newData1 = selectOption.label ? selectOption?.label : ''
      const newData = selectOption.data || {}
      console.log(selectOption.data)

      setCreatAd_One(prevState => ({
        ...prevState,
        privious_ad: newData1 || '',
        country: newData.address?.[0]?.country || '',
        street: newData.address?.[0]?.street || '',
        city: newData.address?.[0]?.city || '',
        province: newData.address?.[0]?.province || '',
        zip_code: newData.address?.[0]?.zip_code || '',
        job_title: newData.job_title || '',
        cellphone: newData.cellphone || '+39',
        e_mail: newData.e_mail || '',
        // email_for_updates: "",
        // updates_by_email:updates_by_email ,
        allow_phone_contact: true,
        allow_e_mail_contact: true,
        is_exp_date: true,
        isAddressComplete: true,
        n_roles_sought: newData.n_roles_sought || '',
        application_sending_deadline:
          newData.application_sending_deadline || null
        // recruitment_time: "",
        // privious_ad: "",
        // contact_person: "",
      }))

      setCreatAd_Three(prevState => ({
        ...prevState,
        job_title: newData.job_title || ''
      }))
    } else {
      setCreatAd_One(prevState => ({
        ...prevState,
        privious_ad: '',
        country: '',
        street: '',
        city: '',
        province: '',
        zip_code: '',
        job_title: '',
        cellphone: '+39',
        n_roles_sought: '',
        isAddressComplete: false,
        allow_phone_contact: false,
        allow_e_mail_contact: false,
        is_exp_date: false,
        e_mail: '',
        application_sending_deadline: null
      }))
      setCreatAd_Three(prevState => ({
        ...prevState,
        job_title: ''
      }))
    }
  }

  const handlenContactPerson = selectOption => {
    if (selectOption !== null) {
      const newData = selectOption.value ? selectOption?.value : ''
      const newDataLabel = selectOption.label ? selectOption?.label : ''
      setCreatAd_One(prevData => ({
        ...prevData,
        contact_person: newData,
        cellphone: selectOption.cellphone,
        e_mail: newDataLabel,
        is_exp_date: true,
        allow_e_mail_contact: true,
        allow_phone_contact: true
      }))
    } else {
      setCreatAd_One(prevData => ({
        ...prevData,
        contact_person: '',
        cellphone: '+39',
        e_mail: '',
        is_exp_date: false,
        allow_e_mail_contact: false,
        allow_phone_contact: false
      }))
    }
  }

  const handleChangeCityCreatAd_One = async selectedOptions => {
    setIsLoading(true)
    if (selectedOptions !== null) {
      setTimeout(() => {
        const [city, province] = selectedOptions?.label.split(', ')
        setCreatAd_One(prevData => ({
          ...prevData,
          city: city,
          province: province
        }))
        setSelectedCity(selectedOptions)
        setIsLoading(false)
        setErrorCreatAd_One(prevState => ({
          ...prevState,
          city: ''
        }))
      }, 1000)
    } else {
      setCreatAd_One(prevData => ({
        ...prevData,
        city: '',
        province: ''
      }))
      setSelectedCity(null)
      setIsLoading(false)
    }
  }

  const handleOnDadeLineDate = event => {
    const expireDate = event.target.value
    if (expireDate.length < 11) {
      setCreatAd_One({
        ...CreatAd_One,
        application_sending_deadline: expireDate
      })
    }
  }

  const handlePhoneChange = value => {
    if (typeof value !== 'undefined') {
      if (!value.startsWith('+')) {
        value = '+' + value
      }
      setCreatAd_One(prevData => ({ ...prevData, cellphone: value }))
    }
  }

  const handleOnCreatNumberOfRoles = e => {
    const { name, value } = e.target
    const numericValue = Number(value)
    if (
      name === 'n_roles_sought' &&
      !isNaN(numericValue) &&
      numericValue >= 0
    ) {
      setCreatAd_One(prevData => ({
        ...prevData,
        n_roles_sought: numericValue
      }))
    }
  }

  //======================================== STEP 2 Function ==============================|

  const handleOnCreatAd_Two = e => {
    e.preventDefault()
    const { name, value } = e.target
    setCreatAd_Two(prevData => ({ ...prevData, [name]: value ? value : '' }))
  }

  const handleOnCreatAdTwoEducationLevel = e => {
    console.log(e)
    setIsLoading(true)
    if (e !== null) {
      setTimeout(() => {
        const levelType = []
        levelType.push({
          label: e.label
        })
        console.log(levelType)
        const updatedSelectedOptions = e ? e.label : ''
        //const levelTypes = levelType?.map((option) => ({ licenceType: option.label })) || [];
        setCreatAd_Two(prevData => ({
          ...prevData,
          educationLevels: [{ levelType: e.label }]
        }))
        setSelectedOptionsEducation(prevOptions => ({
          ...prevOptions,
          submenu: e.label,
          subSubmenu: ''
        }))
        setIsLoading(false)
        setErrorCreatAd_Two(prev => ({ ...prev, educationLevels: '' }))
      }, 50)
    } else {
      setCreatAd_Two(prevData => ({ ...prevData, educationLevels: '' }))
      setSelectedOptionsEducation(prevOptions => ({
        ...prevOptions,
        submenu: '',
        subSubmenu: ''
      }))
      setIsLoading(false)
      validateEducationLevel()
    }
  }

  const handleCreatAdTwoSubEducation = e => {
    const { value } = e.target
    console.log(value)

    setSelectedOptionsEducation(prevOptions => {
      const newOptions = {
        ...prevOptions,
        subSubmenu: value
      }

      const educationLevels = `${newOptions.subSubmenu}, ${value}`
      setCreatAd_Two(prevData => ({
        ...prevData,
        educationLevels
      }))

      return newOptions
    })
  }

  const handleCreatTwoDriver = selectedOptions => {
    console.log(selectedOptions)
    const licenseTypes =
      selectedOptions?.map(option => ({ licenceType: option.label })) || []
    setCreatAd_Two(prevData => ({
      ...prevData,
      driverlicense: licenseTypes
    }))
    setSelectedOptionsDrivelicense(selectedOptions)
    setErrorCreatAd_Two(prevErrors => ({
      ...prevErrors,
      driverlicense: ''
    }))
  }

  useEffect(() => {
    validateSubEducationLevel()
  }, [selectedOptionsEducation])

  //======================================== STEP 3 Function ==============================

  // const handleOnPostionTitle = (selectOption) => {
  //     if (selectOption !== null) {
  //         const newData = selectOption.label ? selectOption?.label : ""
  //         setCreatAd_Three((prevData) => ({ ...prevData, position: newData }));
  //     } else {
  //         setCreatAd_Three((prevData) => ({ ...prevData, position: "" }));
  //     }
  // }

  const handleOnCreatAdThird = e => {
    const { name, value, type, files } = e.target
    const newValue = type === 'file' ? files[0] : value

    setCreatAd_Three(prevState => ({
      ...prevState,
      [name]: newValue
    }))
  }

  //===========================================  STEP 4 Function ============================================

  const handleCreateFourType = selectedOptions => {
    const contractType =
      selectedOptions?.map(option => ({ contract_name: option.label })) || []
    setCreatAd_Four(prevData => ({
      ...prevData,
      contract_type: contractType
    }))
    setSelectedOptionsContractType(selectedOptions)
    setErrorCreatAd_Four(prevErrors => ({
      ...prevErrors,
      contract_type: ''
    }))
  }

  const handleCreateAvailability = selectedOptions => {
    console.log('handleCreateAvailability', selectedOptions)
    const availabilityType =
      selectedOptions?.map(option => ({ availability_type: option.label })) ||
      []
    setCreatAd_Four(prevData => ({
      ...prevData,
      availability: availabilityType
    }))
    setSelectedOptionsAvailabilityType(selectedOptions)
    setErrorCreatAd_Four(prevErrors => ({
      ...prevErrors,
      availability: ''
    }))
  }

  const handleContractDuration = selectedOptions => {
    if (selectedOptions !== null) {
      setCreatAd_Four(prevData => ({
        ...prevData,
        contract_duration_period: selectedOptions.label
          ? selectedOptions.label
          : ''
      }))
    } else {
      setCreatAd_Four(prevData => ({
        ...prevData,
        contract_duration_period: ''
      }))
    }
  }

  const handleOnCreatAd_Four = e => {
    const { name, value } = e.target
    setCreatAd_Four(prevData => ({ ...prevData, [name]: value ? value : '' }))
  }

  //=================================================Step 5 Function============================================

  const handleInputChange = (event, inputName) => {
    let value
    if (inputName == 'salary_recurrence') {
      value = event?.target?.value
    } else {
      value = event?.target?.value.replace(/[^0-9]/g, '')
    }
    setCreatAd_Five(prevData => ({
      ...prevData,
      [inputName]: value
    }))
  }

  const handleCreateFiveAdditionalWages = selectedOptions => {
    const additionalWages =
      selectedOptions?.map(option => ({ additional_wages: option.label })) || []
    setCreatAd_Five(prevData => ({
      ...prevData,
      additional_wages: additionalWages
    }))
    setSelectedOptionsAdditionalWages(selectedOptions)
    setErrorCreatAd_Five(prevErrors => ({
      ...prevErrors,
      additional_wages: ''
    }))
  }

  const handleCreateFiveBenefits = selectedOptions => {
    const benefitType =
      selectedOptions?.map(option => ({ benefit: option.label })) || []
    setCreatAd_Five(prevData => ({
      ...prevData,
      benefit: benefitType
    }))
    setSelectedOptionsBenefits(selectedOptions)
    setErrorCreatAd_Five(prevErrors => ({
      ...prevErrors,
      benefit: ''
    }))
  }

  //============================================= Step 6  Function================================================

  const handleOnAddQuestions = () => {
    let length = CreatAd_Six.length
    if (length === 0) {
      setCreatAd_Six([
        ...CreatAd_Six,
        {
          question: ''
        }
      ])

      setErrorCreatAd_Six([
        ...errorCreatAd_Six,
        {
          question: ''
        }
      ])
    } else if (CreatAd_Six[length - 1].question === '') {
      Swal.fire(
        'Errore!',
        'Dopo aver completato quello precedente, puoi aggiungerne uno nuovo.',
        'error'
      )
      return
    } else {
      setCreatAd_Six([
        ...CreatAd_Six,
        {
          question: ''
        }
      ])
      setErrorCreatAd_Six([
        ...errorCreatAd_Six,
        {
          question: ''
        }
      ])
    }
  }

  const handleOnConfirmDraft = () => {
    return Swal.fire({
      title: 'Conferma',
      text: "Il draft dell'annuncio è stato salvato. Passa ora alle opzioni di pubblicazione",
      icon: 'info',
      confirmButtonText: 'OK',
      showCancelButton: false,
      cancelButtonText: 'Annulla'
    }).then(result => {
      return result.isConfirmed
    })
  }

  const handleOnCreatAdSixQuestion = (e, i) => {
    let { name, value } = e.target
    const newList = [...CreatAd_Six]
    newList[i][name] = value
    setCreatAd_Six(newList)
    console.log('CreatAd_Six', CreatAd_Six)
  }

  const handleRemoveQuestions = index => {
    const updateQuestions = [...CreatAd_Six]
    updateQuestions.splice(index, 1)
    setCreatAd_Six(updateQuestions)

    const updateErrorQuestions = [...errorCreatAd_Six]
    updateErrorQuestions.splice(index, 1)
    setErrorCreatAd_Six(updateErrorQuestions)
  }

  //============================================= Step 7  Function================================================
  const handleOnCreatAd_Seven = e => {
    const { name, value } = e.target
    if (name === 'spent' && value < 0) {
      return
    }
    setCreatAd_Seven(prevData => ({ ...prevData, [name]: value }))
  }

  const handleOnCreatSponsorType = value => {
    const newValue = value?.label || ''

    if (newValue) {
      setCreatAd_Seven(prevData => ({
        ...prevData,
        sponsor_recurrency: newValue
      }))
    } else {
      setCreatAd_Seven(prevData => ({
        ...prevData,
        sponsor_recurrency: ''
      }))
    }
  }

  function validateFormFirst () {
    let countryV = validateCountry()
    let cityV = validateCity()
    let provinceV = validateProvince()
    let streetNum = validateStreetNumber()
    let zipCode = validateZipCode()
    let add_email = validateAddEmail()
    let phonN = validatePhone()
    let expbirthV = validateAdExpDate()
    // let privious_ad = proviousaddvalidate();
    let contactperson = contactpersonValidate()
    let jobtitle = validateJobTitle()
    // let datetoggle = dateToggle();
    // let mobiletoggle = mobilToggle();
    // let emailtoggle = emailToggle();
    let timeline = validateTimelinePosition()
    let emailverify = emailVerificationToggle()
    let validatemail = validateEmail()
    let validaterole = validateNumberOfRoles()
    // const addresstoggle = isAddress();
    const validall =
      countryV &&
      cityV &&
      provinceV &&
      streetNum &&
      zipCode &&
      add_email &&
      phonN &&
      expbirthV &&
      // privious_ad &&
      contactperson &&
      jobtitle &&
      // datetoggle &&
      // mobiletoggle &&
      // emailtoggle &&
      timeline &&
      emailverify &&
      validatemail &&
      validaterole
    // addresstoggle;
    return validall
  }

  function validateFormAddress () {
    let countryV = validateCountry()
    let cityV = validateCity()
    let provinceV = validateProvince()
    let streetNum = validateStreetNumber()
    let zipCode = validateZipCode()
    // let add_email = validateAddEmail();

    const validall = countryV && cityV && provinceV && streetNum && zipCode
    // add_email
    return validall
  }

  function validateFormSecond () {
    let educationlevelValid = validateEducationLevel()
    let subEducationLevelValid = validateSubEducationLevel()
    let driverlicenseValid = validateCreateTwoDriverlicense()
    let datevalid = validateDateTwo()
    //let dateToggle = periodDateToggle()
    const valid =
      educationlevelValid &&
      subEducationLevelValid &&
      driverlicenseValid &&
      datevalid
    return valid
  }

  function validateFormThird () {
    let job_titleV = validateFormthirdJobTitle()
    let fileV = validatethirdFormFile()
    let ckeditorV = validateFormThirdCkeditor()
    const validall = job_titleV && fileV && ckeditorV
    return validall
  }

  function validateFormFour () {
    let durationValid = validateDuration()
    let availabilityValid = validateAvailability()
    let contractTypeValid = validateContractType()
    const validall = durationValid && availabilityValid && contractTypeValid
    return validall
  }

  function validateFormFive () {
    let fixedAmountValid = validateForFixAmount()
    let salaryRecurrenceValid = validateForSalaryRecurrence()
    let minAmountValid = validateForMinAmount()
    let maxAmountValid = validateForMaxAmount()
    let additionalWagesValid = validateAdditionalWages()
    let benefiltsValid = validateBenefit()
    const validall =
      fixedAmountValid &&
      salaryRecurrenceValid &&
      minAmountValid &&
      maxAmountValid &&
      additionalWagesValid &&
      benefiltsValid
    return validall
  }

  const validateFormSix = () => {
    if (CreatAd_Six.length > 0) {
      return CreatAd_Six.every((_, i) => validateFormSixQuestion(i))
    }
    return false
  }

  //address update

  const handleOnGetAddress = e => {
    e.preventDefault()
    props.userLogout()
    console.log('address get details', e)
    if (validateFormAddress()) {
      const token = localStorage.getItem('token')

      props.requestCompanyUpdate({
        data: {
          // e_mail: CreatAd_One.email_for_updates,
          address: {
            street: CreatAd_One.street,
            city: CreatAd_One.city,
            zip_code: CreatAd_One.zip_code,
            state: null,
            province: CreatAd_One.province,
            country: CreatAd_One.country
          }
        },
        token: token
      })

      setError(false)
    } else {
      setError(true)
    }
  }

  useEffect(() => {
    let companyUpdateData = props.company.companyUpdateData
    if (companyUpdateData !== undefined) {
      if (companyUpdateData.data.Status === 'Success') {
        toast.success(companyUpdateData.data.Status, {
          theme: 'colored',
          autoClose: 1000
        })
        props.company.companyUpdateData = undefined
      } else {
        toast.error(companyUpdateData.data.Message, {
          theme: 'colored',
          autoClose: 1000
        })
        setError(true)
        props.company.companyUpdateData = undefined
      }
    }
  }, [props.company.companyUpdateData])

  const handleOnPatchAdvertisement = e => {
    //props.userLogout();
    e.preventDefault()
    const token = localStorage.getItem('token')

    const requestData = {
      published: true
    }
    if (newAdvertisementId) {
      props.requestPatchAdvertisement({
        data: requestData,
        token: token,
        id: newAdvertisementId
      })

      const updateAdvertisement = props.company.updateAdvertisementData
      if (updateAdvertisement !== undefined) {
        if (updateAdvertisement.data.Status === 'Success') {
          toast.success(updateAdvertisement.data.Status, {
            theme: 'colored',
            autoClose: 1000
          })
          console.log(updateAdvertisement, 'updateAdvertisement');
          props.company.updateAdvertisementData = undefined;
          navigate('/company/advertisement-list')
        } else {
          toast.error(updateAdvertisement.data.Message, {
            theme: 'colored',
            autoClose: 1000
          })
          toast.error(updateAdvertisement.data.detail, {
            theme: 'colored',
            autoClose: 1000
          })
        }
      }
    }
  }

  useEffect(() => {
    if (newAdvertisementId === null) {
      setStep(1)
    } else {
      if (setStepParam == 1) {
        setStep(1)
      }
      const token = localStorage.getItem('token')
      axios
        .get(
          `${process.env.REACT_APP_API_HOST}wideer/ads?id=${newAdvertisementId} `,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(response => {
          console.log('getid', response)
          let responseData = response?.data?.Message[0]
          console.log('responseData', responseData)
          let is_exp_date = false
          if (responseData.application_sending_deadline) {
            is_exp_date = true
          }
          setCreatAd_One(prevData => ({
            ...prevData,
            recruitment_time: responseData.recruitment_time.time,
            allow_e_mail_contact: responseData.allow_e_mail_contact,
            allow_phone_contact: responseData.allow_phone_contact,
            application_sending_deadline:
              responseData.application_sending_deadline,
            is_exp_date: is_exp_date,
            contact_person: responseData.spokesman_id,
            e_mail: responseData.e_mail,
            cellphone: responseData.cellphone,
            n_roles_sought: responseData.n_roles_sought,
            job_title: responseData.job_title,
            ad_type: responseData.ad_type.type
          }))

          const driverLicenses =
            responseData.driving_licences_required?.map(option => ({
              licenceType: option.label
            })) || []
          setSelectedOptionsDrivelicense(
            responseData.driving_licences_required?.map(option => ({
              value: option.id,
              label: option.licenceType
            }))
          )
          const educationalLevel =
            responseData?.educationalLevel?.levelType || []

          setSelectedOptionsEducation(prevOptions => ({
            ...prevOptions,
            submenu: educationalLevel?.levelType,
            subSubmenu: ''
          }))
          let isStartDateVisible = false
          if (responseData.job_starting_date) {
            isStartDateVisible = true
          }
          setCreatAd_Two(prevData => ({
            ...prevData,
            educationLevels: [{ levelType: educationalLevel }],
            driverlicense: driverLicenses,
            job_starting_date: responseData.job_starting_date,
            isStartDateVisible: isStartDateVisible
          }))

          setCreatAd_Three(prevData => ({
            ...prevData,
            job_title: responseData.job_title,
            job_description: responseData.job_description
          }))

          const contractType =
            responseData.contract_type?.map(option => ({
              contract_name: option.label
            })) || []
          setSelectedOptionsContractType(
            responseData.contract_type?.map(option => ({
              label: option.contract_name
            }))
          )

          const availability = responseData.availability?.map(option => ({
            availability_type: option.availability_type
          }))
          setSelectedOptionsAvailabilityType(
            responseData.availability?.map(option => ({
              value: option.availability_type, //ATTENZIONE NON VA BENE
              label: option.availability_type
            }))
          )

          setCreatAd_Four(prevData => ({
            ...prevData,
            contract_type: contractType,
            contract_duration_n: responseData?.contract_duration_n,
            contract_duration_period: responseData?.contract_duration_period,
            availability: availability
          }))

          console.log('creatAd_Four', CreatAd_Four)

          const additionalWage =
            responseData.salary?.additional_wages?.map(option => ({
              additional_wage: option.additional_wage
            })) || []
          setSelectedOptionsAdditionalWages(
            responseData.salary?.additional_wages?.map(option => ({
              value: option.id,
              label: option.additional_wage
            }))
          )

          setCreatAd_Five(prevData => ({
            ...prevData,
            fixedAmount: responseData.salary?.fixedAmount,
            salary_recurrence: responseData.salary?.reccurency,
            minAmount: responseData.salary?.minAmount,
            maxAmount: responseData.salary?.maxAmount,
            additional_wages: additionalWage,
            reccurency: responseData.salary?.reccurency
          }))

          const questionList = responseData.questions?.map(option => ({
            question: option.question
          }))
          setCreatAd_Six(
            questionList?.length > 0 ? questionList : [{ question: '' }]
          )
        })
    }
  }, [newAdvertisementId])

  const handleNextStep = async formType => {
    const token = localStorage.getItem('token')
    let isValid = false
    let draftValid = false

    try {
      switch (formType) {
        case 'first':
          isValid = validateFormFirst()
          if (isValid) {
            handleFirstForm(token)
          }
          break

        case 'second':
          isValid = validateFormSecond()
          if (isValid) {
            handleSecondForm(token)
          }
          break

        case 'third':
          isValid = validateFormThird()
          if (isValid) {
            handleThirdForm(token)
          }
          break

        case 'four':
          isValid = validateFormFour()
          if (isValid) {
            handleFourthForm(token)
          }
          break

        case 'five':
          isValid = validateFormFive()
          if (isValid) {
            handleFifthForm(token)
          }
          break

        case 'six':
          isValid = validateFormSix()
          if (isValid) {
            draftValid = await handleOnConfirmDraft()
            if (draftValid) {
              handleSixthForm(token)
            }
          }
          break

        default:
          console.warn('Invalid form type')
          break
      }

      if (isValid) {
        if (newAdvertisementId) {
          if (setStepParam != 1) {
            const updateAdvertisement = props.company.updateAdvertisementData
            console.log(updateAdvertisement, 'updateAdvertisement')
            if (updateAdvertisement !== undefined) {
              console.log(updateAdvertisement, 'updateAdvertisement')
              if (updateAdvertisement?.data?.Status === 'Success') {
                toast.success(updateAdvertisement.data.Status, {
                  theme: 'colored',
                  autoClose: 1000
                })
                proceedToNextStep()
              } else {
                toast.error(
                  updateAdvertisement?.data?.Message ||
                    updateAdvertisement?.data?.detail,
                  { theme: 'colored', autoClose: 1000 }
                )
              }
            }
          } else {
            toast.success('Success', { theme: 'colored', autoClose: 1000 })
            proceedToNextStep()
          }
        }
      } else {
        setError(true)
      }
    } catch (error) {
      console.error('Error occurred while processing:', error)
      setError(true)
    }
  }

  const handleFirstForm = token => {
    let tipologiaAnnuncio;
    if(CreatAd_One.ad_type == '') {
      if(location?.state?.ad_type == 'agent') {
        tipologiaAnnuncio = 'Agente';
      } else {
        tipologiaAnnuncio = 'Employee';
      }
    } else {
      tipologiaAnnuncio = CreatAd_One.ad_type
    }

    const data = {
      address: {
        country: CreatAd_One.country,
        city: CreatAd_One.city,
        province: CreatAd_One.province,
        street: CreatAd_One.street,
        zip_code: CreatAd_One.zip_code
      },
      cellphone: CreatAd_One.cellphone,
      application_sending_deadline: CreatAd_One.application_sending_deadline,
      e_mail: CreatAd_One.e_mail,
      n_roles_sought: CreatAd_One.n_roles_sought,
      allow_e_mail_contact: CreatAd_One.allow_e_mail_contact,
      allow_phone_contact: CreatAd_One.allow_phone_contact,
      email_for_updates: CreatAd_One.email_for_updates,
      is_exp_date: CreatAd_One.is_exp_date,
      job_title: CreatAd_One.job_title,
      recruitment_time: { time: CreatAd_One.recruitment_time },
      updates_by_email: CreatAd_One.updates_by_email,
      spokesman_id: CreatAd_One.contact_person,
      ad_type: {
        type: tipologiaAnnuncio
      }
    }

    if (newAdvertisementId) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
      axios
        .post(
          `http://85.235.145.232/wideer/ads/ad/${newAdvertisementId}/`,
          {
            data
          },
          config
        )
        .then(response => {
          console.log(response)
        })
      //props.requestPatchAdvertisement({ id: newAdvertisementId, token, data });
    } else {
      props.requestCreateAdvertisement({ token, data })
    }
    setCreatAd_One(prev => ({
      ...prev,
      ad_id: 'invalidate'
    }))
  }

  useEffect(() => {
    if (location.pathname === '/company/create-adv') {
      const advertisementData = props.company.advertisementData
      if (advertisementData !== undefined) {
        if (advertisementData?.data?.Status === 'Success') {
          //proceedToNextStep()
          //props.userLogout()
          console.log(
            advertisementData?.data?.Message?.id,
            'advertisementData?.data?.Message?.id'
          );
          console.log("CreatAd_One.ad_id", CreatAd_One.ad_id);
          if (CreatAd_One.ad_id === null) {
            
          } else {
            proceedToNextStep();
            navigate(
              `/company/create-adv?adId=${advertisementData?.data?.Message?.id}`
            )
          }
          toast.success(advertisementData.data.Status, {
            theme: 'colored',
            autoClose: 1000
          })
        } else {
          toast.error(advertisementData?.data?.Message, {
            theme: 'colored',
            autoClose: 1000
          })
        }
      }
    }
  }, [props.company.advertisementData])

  const handleSecondForm = token => {
    console.log(CreatAd_Two, 'CreatAd_Two')
    const data = {
      driving_licences_required: CreatAd_Two.driverlicense,
      educationalLevel: CreatAd_Two.educationLevels,
      job_starting_date: CreatAd_Two.job_starting_date
    }
    props.requestPatchAdvertisement({ token, id: newAdvertisementId, data })
  }

  const handleThirdForm = token => {
    console.log(CreatAd_Three.selectedFile, 'CreatAd_Three')
    //const token = localStorage.getItem("token");
    const data = {
      job_description_adv: CreatAd_Three.desc
      //picture: CreatAd_Three.selectedFile
    }
    //props.requestPatchAdvertisement({ token, id: newAdvertisementId, data });
    const formData = new FormData();
    if(CreatAd_Three.selectedFile) {
    formData.append(
      'picture',
      CreatAd_Three.selectedFile,
      CreatAd_Three.selectedFile.name
    )
  }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
    axios
      .post(
        `http://85.235.145.232/wideer/ads/ad/${newAdvertisementId}/`,
        {
          formData
        },
        config
      )
      .then(response => {
        props.requestPatchAdvertisement({ token, id: newAdvertisementId, data })
      })
  }

  const handleFourthForm = token => {
    const data = {
      availability: CreatAd_Four.availability,
      contract_duration_n: CreatAd_Four.contract_duration_n,
      contract_duration_period: CreatAd_Four.contract_duration_period,
      contract_type: CreatAd_Four.contract_type
    }
    props.requestPatchAdvertisement({ token, id: newAdvertisementId, data })
  }

  const handleFifthForm = token => {
    const data = {
      salary: {
        range: CreatAd_Five.range,
        fixedAmount: CreatAd_Five.fixedAmount,
        additional_wages: CreatAd_Five.additional_wages,
        benefit: CreatAd_Five.benefit,
        reccurency: CreatAd_Five.reccurency,
        minAmount: CreatAd_Five.minAmount || 0,
        maxAmount: CreatAd_Five.maxAmount || 0
      }
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    axios
      .post(
        `http://85.235.145.232/wideer/ads/ad/${newAdvertisementId}/`,
        {
          data
        },
        config
      )
      .then(response => {
        console.log(response)
      })
    //props.requestPatchAdvertisement({ token, id: newAdvertisementId, data });
  }

  const handleSixthForm = token => {
    props.requestPatchAdvertisement({
      token,
      id: newAdvertisementId,
      data: { questions: CreatAd_Six }
    })
  }

  const proceedToNextStep = () => {
    const nextStep = step + 1
    setStep(nextStep)
    localStorage.setItem('CreaAnnuncio', nextStep)
    setError(false)
  }

  const prevStep = () => {
    const previousStep = step - 1
    setStep(previousStep)
    localStorage.setItem('CreaAnnuncio', previousStep)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const renderStepComponent = () => {
    switch (step) {
      case 1:
        return (
          <CreaAnnuncioOne
            nextStep={() => handleNextStep('first')}
            CreatAd_One={CreatAd_One}
            errorCreatAd_One={errorCreatAd_One}
            setCreatAd_One={setCreatAd_One}
            setErrorCreatAd_One={setErrorCreatAd_One}
            sortedCountryData={sortedCountryData}
            municipalities={municipalities}
            recruitmentTimeline={recruitmentTimeline}
            countryRef={countryRef}
            provinceRef={provinceRef}
            emailRef={emailRef}
            addemailRef={addemailRef}
            cityRef={cityRef}
            phoneRef={phoneRef}
            streetRef={streetRef}
            handleOnCreatAd_One={handleOnCreatAd_One}
            handleOnCreatAdJobTitle={handleOnCreatAdJobTitle}
            handlenContactPerson={handlenContactPerson}
            handleChangeCityCreatAd_One={handleChangeCityCreatAd_One}
            handleOnCreatNumberOfRoles={handleOnCreatNumberOfRoles}
            handleOnDadeLineDate={handleOnDadeLineDate}
            handlePhoneChange={handlePhoneChange}
            handleOnGetAddress={handleOnGetAddress}
            isLoading={isLoading}
            validateEmail={validateEmail}
            validateCountry={validateCountry}
            validateCity={validateCity}
            validateProvince={validateProvince}
            validateZipCode={validateZipCode}
            validatePhone={validatePhone}
            validateAddEmail={validateAddEmail}
            validateJobTitle={validateJobTitle}
            validateNumberOfRoles={validateNumberOfRoles}
            validateStreetNumber={validateStreetNumber}
            validateAdExpDate={validateAdExpDate}
            validateTimelinePosition={validateTimelinePosition}
            handleOnPreviousAdvertisement={handleOnPreviousAdvertisement}
            // proviousaddvalidate={proviousaddvalidate}
            contactpersonValidate={contactpersonValidate}
            error={error}
            setError={setError}
            mystyle={mystyle}
            isClearable={isClearable}
            isSearchable={isSearchable}
            filterConfig={filterConfig}
            t={t}
            jobList={jobList}
            previousAdd={previousAdd}
            representative={representative}
            previousAddRef={previousAddRef}
            jobTitleRef={jobTitleRef}
            rolesSoughtRef={rolesSoughtRef}
            zipCodeRef={zipCodeRef}
            contactPersonRef={contactPersonRef}
            applicationSendingDeadlineRef={applicationSendingDeadlineRef}
            recruitmentTimeRef={recruitmentTimeRef}
            CreatAd_One_Loader={CreatAd_One_Loader}
            addressDetails={addressDetails}
            handleOnAddressSelect={handleOnAddressSelect}
            newAdvertisementId={newAdvertisementId}
            ad_type={location.state?.ad_type}
          />
        )
      case 2:
        return (
          <CreaAnnuncioTwo
            nextStep={() => handleNextStep('second')}
            prevStep={prevStep}
            CreatAd_Two={CreatAd_Two}
            errorCreatAd_Two={errorCreatAd_Two}
            setCreatAd_Two={setCreatAd_Two}
            setErrorCreatAd_Two={setErrorCreatAd_Two}
            educationLevels={educationLevels}
            subSubmenuOptions={subSubmenuOptions}
            driverlicenseData={driverlicenseData}
            handleOnCreatAd_Two={handleOnCreatAd_Two}
            validateDateTwo={validateDateTwo}
            birthDateRef={birthDateRef}
            addressRef={addressRef}
            isClearable={isClearable}
            mystyle={mystyle}
            t={t}
            selectedOptionsEducation={selectedOptionsEducation}
            educationLevelRef={educationLevelRef}
            validateEducationLevel={validateEducationLevel}
            handleOnCreatAdTwoEducationLevel={handleOnCreatAdTwoEducationLevel}
            handleCreatAdTwoSubEducation={handleCreatAdTwoSubEducation}
            validateSubEducationLevel={validateSubEducationLevel}
            subEducationalRef={subEducationalRef}
            jobStartingDateRef={jobStartingDateRef}
            isLoading={isLoading}
            newAdvertisementId={newAdvertisementId}
            handleCreatTwoDriver={handleCreatTwoDriver}
            validateCreateTwoDriverlicense={validateCreateTwoDriverlicense}
            driverLicenseRef={driverLicenseRef}
            selectedOptionsDrivelicense={selectedOptionsDrivelicense}
            error={error}
            setError={setError}
            setStepParam={setStepParam}
            ad_type={CreatAd_One.ad_type}
          />
        )
      case 3:
        return (
          <CreaAnnuncioThree
            nextStep={() => handleNextStep('third')}
            prevStep={prevStep}
            CreatAd_Three={CreatAd_Three}
            setCreatAd_Three={setCreatAd_Three}
            validateFormthirdJobTitle={validateFormthirdJobTitle}
            errorCreatAd_Three={errorCreatAd_Three}
            handleOnCreatAdThird={handleOnCreatAdThird}
            // handleOnPostionTitle={handleOnPostionTitle}
            validatethirdFormFile={validatethirdFormFile}
            validateFormThirdCkeditor={validateFormThirdCkeditor}
            positionErrorRef={positionErrorRef}
            fileErrorRef={fileErrorRef}
            isClearable={isClearable}
            isSearchable={isSearchable}
            filterConfig={filterConfig}
            mystyle={mystyle}
            t={t}
            jobList={jobList}
            error={error}
            setError={setError}
            newAdvertisementId={newAdvertisementId}
            setStepParam={setStepParam}
            ad_type={CreatAd_One.ad_type}
          />
        )
      case 4:
        return (
          <CreaAnnuncioFour
            nextStep={() => handleNextStep('four')}
            prevStep={prevStep}
            CreatAd_Four={CreatAd_Four}
            errorCreatAd_Four={errorCreatAd_Four}
            contractTypesData={contractTypesData}
            availabilitiesData={availabilitiesData}
            filterConfig={filterConfig}
            validateDuration={validateDuration}
            validateAvailability={validateAvailability}
            validateContractType={validateContractType}
            contractTypeRef={contractTypeRef}
            contractDurationRef={contractDurationRef}
            durationStringRef={durationStringRef}
            availabilityRef={availabilityRef}
            mystyle={mystyle}
            t={t}
            handleCreateFourType={handleCreateFourType}
            isClearable={isClearable}
            isSearchable={isSearchable}
            selectedOptionsAvailabilityType={selectedOptionsAvailabilityType}
            handleCreateAvailability={handleCreateAvailability}
            selectedOptionsContractType={selectedOptionsContractType}
            handleOnCreatAd_Four={handleOnCreatAd_Four}
            handleContractDuration={handleContractDuration}
            error={error}
            setError={setError}
            newAdvertisementId={newAdvertisementId}
            setStepParam={setStepParam}
            ad_type={CreatAd_One.ad_type}
          />
        )
      case 5:
        return (
          <CreaAnnuncioFive
            nextStep={() => handleNextStep('five')}
            prevStep={prevStep}
            CreatAd_Five={CreatAd_Five}
            setCreatAd_Five={setCreatAd_Five}
            errorCreatAd_Five={errorCreatAd_Five}
            additionalWagesData={additionalWagesData}
            benefitsData={benefitsData}
            handleInputChange={handleInputChange}
            filterConfig={filterConfig}
            mystyle={mystyle}
            t={t}
            setErrorCreatAd_Five={setErrorCreatAd_Five}
            handleCreateFiveAdditionalWages={handleCreateFiveAdditionalWages}
            handleCreateFiveBenefits={handleCreateFiveBenefits}
            validateForFixAmount={validateForFixAmount}
            validateForSalaryRecurrence={validateForSalaryRecurrence}
            validateForMinAmount={validateForMinAmount}
            validateForMaxAmount={validateForMaxAmount}
            validateAdditionalWages={validateAdditionalWages}
            validateBenefit={validateBenefit}
            selectedOptionsBenefits={selectedOptionsBenefits}
            selectedOptionsAdditionalWages={selectedOptionsAdditionalWages}
            minAmountRef={minAmountRef}
            maxAmountRef={maxAmountRef}
            fixedAmountRef={fixedAmountRef}
            salaryRecurrenceRef={salaryRecurrenceRef}
            additionalWagesRef={additionalWagesRef}
            benefitRef={benefitRef}
            error={error}
            setError={setError}
            newAdvertisementId={newAdvertisementId}
            setStepParam={setStepParam}
            ad_type={CreatAd_One.ad_type}
          />
        )
      case 6:
        return (
          <CreaAnnuncioSix
            nextStep={() => handleNextStep('six')}
            prevStep={prevStep}
            CreatAd_Six={CreatAd_Six}
            errorCreatAd_Six={errorCreatAd_Six}
            handleOnAddQuestions={handleOnAddQuestions}
            handleRemoveQuestions={handleRemoveQuestions}
            handleOnCreatAdSixQuestion={handleOnCreatAdSixQuestion}
            validateFormSixQuestion={validateFormSixQuestion}
            formFocusQuestionRefs={formFocusQuestionRefs}
            mystyle={mystyle}
            t={t}
            newAdvertisementId={newAdvertisementId}
            setStepParam={setStepParam}
            ad_type={CreatAd_One.ad_type}
          />
        )
      case 7:
        return (
          <CreaAnnuncioSeven
            prevStep={prevStep}
            CreatAd_Seven={CreatAd_Seven}
            errorCreatAd_Seven={errorCreatAd_Seven}
            handleOnCreatAd_Seven={handleOnCreatAd_Seven}
            validateAdSponsorDate={validateAdSponsorDate}
            validateforSpent={validateforSpent}
            validateForSponsorRecurrency={validateForSponsorRecurrency}
            handleOnPatchAdvertisement={handleOnPatchAdvertisement}
            handleOnCreatSponsorType={handleOnCreatSponsorType}
            isClearable={isClearable}
            isSearchable={isSearchable}
            filterConfig={filterConfig}
            spentRef={spentRef}
            sponsorRecurrencyRef={sponsorRecurrencyRef}
            sponsorDueDateRef={sponsorDueDateRef}
            error={error}
            setError={setError}
            mystyle={mystyle}
            t={t}
            application_sending_deadline={
              CreatAd_One.application_sending_deadline
            }
            newAdvertisementId={newAdvertisementId}
            setStepParam={setStepParam}
            ad_type={CreatAd_One.ad_type}
          />
        )
      default:
        return null
    }
  }

  return renderStepComponent()
}

const mapStateToProps = state => {
  return { company: state.company, candidate: state.candidate }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestGetList,
      requestGetEducationLevelList,
      requestGetJobList,
      requestGetJobListUserId,
      requestGetrecTime,
      requestGetContractTypes,
      requestGetAvailabilities,
      requestGetAdditionalWages,
      requestGetBenefits,
      requestGetRepresentatives,
      requestCreateAdvertisement,
      requestPatchAdvertisement,
      requestCompanyDetails,
      requestCompanyUpdate,
      userLogout
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(FinalForm)
