import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  requestGetList,
  requestGetCandidate,
  requestCompanyJWT,
  requestCompanyReference,
  requestCompanyDetails,
  requestCompanyUpdate,
  userLogout
} from '../../Redux/actions'
import { toast } from 'react-toastify'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { useTranslation } from 'react-i18next'
import TermsAndService from '../../Components/common/register-fields/TermsAndService'
import { useNavigate } from 'react-router-dom'
import LocationForm from '../../Components/common/register-fields/location/LocationForm'
import VatValidator from './VatValidator'
import SectorLevel from '../../Components/common/register-fields/SectorLevel'
import SDICode from './SDICode'
import Referent from './Referent'
import Swal from 'sweetalert2'
import Select, { createFilter } from 'react-select'
// import { SignJWT } from 'jose';
import { encode as base64Encode } from 'base-64'
import PasswordResetConfirm from "../../Components/forggot-password/Password-Reset-Confirm";
// import React, { useEffect, useState, useRef } from "react";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import {
//     requestGetList,
//     requestGetCandidate,
//     requestCompanyJWT,
//     requestCompanyReference,
//     requestCompanyRegister,
//     userLogout,
// } from "../../Redux/actions";
// import { toast } from "react-toastify";
// import Header from "../../Components/Header";
// import Footer from "../../Components/Footer";
// import { useTranslation } from "react-i18next";
// import TermsAndService from "../../Components/common/register-fields/TermsAndService";
// import { useNavigate } from "react-router-dom";
// import LocationForm from "../../Components/common/register-fields/location/LocationForm";
// import VatValidator from "./VatValidator";
// import SectorLevel from "../../Components/common/register-fields/SectorLevel";
// import SDICode from "./SDICode";
// import Referent from "./Referent";
// import Swal from "sweetalert2";

function CompanyProfile (props) {
  const [showModal, setShowModal] = useState('');

  const [selectedOptionsSector, setSelectedOptionsSector] = useState({
    submenu: '',
    subSubmenu: ''
  })

  const [data, setData] = useState({
    country: 'Italia',
    phone: '+39'
  })

  const [reference, setReference] = useState([
    {
      email: '',
      first_name: '',
      last_name: '',
      phone: ''
    }
  ])

  const [errorreference, setErrorReference] = useState([
    {
      email: '',
      first_name: '',
      last_name: '',
      phone: ''
    }
  ])

  const [companySizes, setCompanySizes] = useState([])
  //===================referent ====================
  const sdiCodeRef = useRef(null)
  const companyEmailRef = useRef(null)
  const sectorRef = useRef(null)
  const subsectorRef = useRef(null)
  const streetNumberRef = useRef(null)
  const zipCodeRef = useRef(null)
  const cityRef = useRef(null)
  const provinceRef = useRef(null)
  const countryRef = useRef(null)
  const eula_consentRef = useRef(null)
  const privacy_consentRef = useRef(null)

  const companyNameRef = useRef(null)
  const dimensionRef = useRef(null)
  const activityRef = useRef(null)
  const vatNumberRef = useRef(null)

  const ReferIndexRef = useRef(null)
  const formFocusRefs = {
    reference: useRef([])
  }

  const [error, setError] = useState(false)
  const [errorCompanyEmail, setErrorCompanyEmail] = useState('')
  const [errorSector, setErrorSector] = useState('')
  const [errorSubSector, setErrorSubSector] = useState('')
  const [errorStreetnumber, seterrorStreetnumber] = useState('')
  const [errorCountry, seterrorCountry] = useState('')
  const [errorCity, seterrorCity] = useState('')
  const [errorProvince, seterrorProvince] = useState('')
  const [errorZipcode, seterrorZipcode] = useState('')
  const [countryData, setCountryData] = useState([])
  const [municipalities, setMunicipalities] = useState([])
  const [autopassword, setAutoPassword] = useState('')
  const [selectedCity, setSelectedCity] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [loadingForRefer, setLoadingForRefer] = useState(false)
  const [emailVerify, setEmailVerify] = useState([])

  const [privacyAccepted, setPrivacyAccepted] = useState(false)
  const [errorprivacyAccepted, setErrorPrivacyAccepted] = useState('')
  const [privacyContentAccepted, setPrivacyContentAccepted] = useState(false)
  const [errorPrivacyContentAccepted, setErrorPrivacyContentAccepted] =
    useState('')

  //===========================================================================================
  const [errorCompanyname, setErrorCompanyname] = useState('')
  const [errorSDIcode, setErrorSDIcode] = useState('')
  const [errorDimension, setErrorDimension] = useState('')
  const [errorVatNumber, setErrorVatNumber] = useState('')
  const [errorActivity, setErrorActivity] = useState('')

  const navigate = useNavigate()
  const { t } = useTranslation()

  const isClearable = true
  const isSearchable = true
  const matchFromStart = true

  const filterConfig = {
    matchFrom: matchFromStart ? 'start' : 'any'
  }

  const mystyle = {
    color: '#D10000',
    padding: '3px 10px',
    width: '100%'
  }

  useEffect(() => {
    props.requestGetList()
  }, [])

  useEffect(() => {
    let levelData = props.candidate.getlistData
    if (levelData !== undefined) {
      if (levelData?.data?.Status === 'Success') {
        const processedCountries = levelData.data.Message.countries.map(
          item => {
            const name = item.nativeCountryName
            return name
          }
        )
        const uniqueCountries = [...new Set(processedCountries)]
        const uniqueCountryData = uniqueCountries.map(name => ({
          nativeCountryName: name
        }))

        setCountryData(uniqueCountryData)
        const municipalit = levelData?.data?.Message?.municipalities
        const uniqueMunicipalities = []
        const municipalityNames = new Set()

        municipalit.forEach(municipality => {
          if (!municipalityNames.has(municipality.name)) {
            municipalityNames.add(municipality.name)
            const municipalityObject = {
              name: `${municipality.name}, ${municipality.provincia.name}`
            }
            uniqueMunicipalities.push(municipalityObject)
          }
        })
        const transformedCityData =
          uniqueMunicipalities?.map((city, index) => ({
            value: index,
            label: city?.name
          })) || []

        setMunicipalities(transformedCityData)
        const transformedCompanySizes =
          levelData.data.Message.companySizes?.map((item, index) => ({
            value: index,
            label: item.size
          })) || []

        setCompanySizes(transformedCompanySizes)
      }
    }
  }, [props.candidate.getlistData])

  const sortedCountryData = [...countryData].sort((a, b) => {
    if (a.nativeCountryName === 'Italia') return -1
    if (b.nativeCountryName === 'Italia') return 1
    return 0
  })

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      const updatedRequestData = {
        token: token
      }
      props.requestCompanyDetails({ data: updatedRequestData })
    }
  }, [])

  useEffect(() => {
    let companyDetails = props.company.getCompanyData
    if (companyDetails !== undefined) {
      if (companyDetails?.data?.Status === 'Success') {
        const UpdateData = companyDetails?.data?.Message || {}

        const CompanyObj = {
          company_name: UpdateData.name || '',
          dimension: UpdateData?.size?.size || '',
          vat_number: UpdateData.vat_number || '',
          activity: UpdateData.type || '',
          sdi_code: UpdateData.sdi || '',
          company_email: UpdateData.e_mail || '',
          sector: UpdateData?.sector?.sectorName || '',
          subsector: UpdateData?.sector?.subsectorName || '',
          street_number: UpdateData?.address?.[0]?.street,
          zip_code: UpdateData?.address?.[0]?.zip_code,
          country: UpdateData?.address?.[0]?.country,
          city: UpdateData?.address?.[0]?.city,
          province: UpdateData?.address?.[0]?.province
        }
        setData(CompanyObj)
        setPrivacyAccepted(UpdateData?.eula_consent || false)
        setPrivacyContentAccepted(UpdateData?.privacy_consent || false)

        const sectorLevel = UpdateData.sector?.sectorName || ''
        const [submenu = '', subSubmenu = ''] = sectorLevel.split(', ')

        setSelectedOptionsSector({
          submenu: submenu || '',
          subSubmenu: subSubmenu || ''
        })

        if (CompanyObj.country === 'Italia') {
          const UpdateCity = {
            value: municipalities.length,
            label: `${CompanyObj.city}, ${CompanyObj.province}`
          }
          setSelectedCity(UpdateCity)
        }

        const processEducation = () => {
          const referenceData = UpdateData.representative || []
          // console.log(referenceData, "referenceData");

          const newData = []
          const updatedEmailVerify = [...emailVerify]

          referenceData.forEach((item, index) => {
            updatedEmailVerify[index] = true

            const newObj = {
              email: item.e_mail,
              first_name: item.name,
              last_name: item.surname,
              phone: item.cellphone
            }

            newData.push(newObj)
          })

          setEmailVerify(updatedEmailVerify)

          // console.log(newData, "newData");

          setReference(newData)

          const eduError =
            newData.length > 0
              ? newData.map(() => ({
                  email: '',
                  first_name: '',
                  last_name: '',
                  phone: ''
                }))
              : [
                  {
                    email: '',
                    first_name: '',
                    last_name: '',
                    phone: ''
                  }
                ]

          setErrorReference(eduError)
        }

        processEducation()
      }
    }
  }, [props.company.getCompanyData])

  function validateCompany () {
    const companyName = companyNameRef.current.value
    if (!companyName) {
      setErrorCompanyname('Inserisci la tua Ragione Sociale.')
      return false
    }
    const trimmedCompanyName = companyName.trim()
    if (!trimmedCompanyName) {
      setErrorCompanyname('Regione sociale non può essere vuoto.')
      return false
    }
    setErrorCompanyname('')
    return true
  }

  // function validateDimension() {
  //     const dimension = dimensionRef.current.value;
  //     if (!dimension) {
  //         setErrorDimension("Inserisci la dimensione della tua azienda");
  //         return false;
  //     }
  //     const trimmedDimension = dimension.trim();
  //     if (!trimmedDimension) {
  //         setErrorDimension("Dimensione non può essere vuoto.");
  //         return false;
  //     }
  //     if (!/^[0-9-]+$/.test(trimmedDimension)) {
  //         setErrorDimension("Inserisci solo numeri e trattini.");
  //         return false;
  //     }
  //     setErrorDimension("");
  //     return true;
  // }
  function validateDimension () {
    const dimension = dimensionRef?.current?.props?.value
    if (!dimension) {
      setErrorDimension('Inserisci la dimensione della tua azienda')
      return false
    }
    setErrorDimension('')
    return true
  }

  const validateSDIcode = () => {
    const sdiCode = data?.sdi_code ? data.sdi_code.trim() : ''

    if (data?.sdi_code && sdiCode.length === 0) {
      setErrorSDIcode(
        'Il Codice univoco SDI non può essere solo spazi. Per favore, inserisci un codice valido.'
      )
      return false
    }

    /*if (sdiCode.length === 0) {
      setErrorSDIcode('Inserisci il tuo codice univoco SDI')
      return false
    }*/

    setErrorSDIcode('')
    return true
  }

  const validateVatNumber = () => {
    const vatNumber = data?.vat_number || ''

    if (vatNumber.length === 0) {
      setErrorVatNumber('Inserisci la tua partita IVA')
      return false
    }

    if (vatNumber.length !== 11) {
      setErrorVatNumber('Il numero di Partita IVA deve essere di 11 cifre.')
      return false
    }

    if (vatNumber.length === 11) {
      const vatDigits = vatNumber.split('').map(Number)

      const sumX =
        vatDigits[0] + vatDigits[2] + vatDigits[4] + vatDigits[6] + vatDigits[8]
      const sumY =
        (vatDigits[1] * 2 > 9 ? vatDigits[1] * 2 - 9 : vatDigits[1] * 2) +
        (vatDigits[3] * 2 > 9 ? vatDigits[3] * 2 - 9 : vatDigits[3] * 2) +
        (vatDigits[5] * 2 > 9 ? vatDigits[5] * 2 - 9 : vatDigits[5] * 2) +
        (vatDigits[7] * 2 > 9 ? vatDigits[7] * 2 - 9 : vatDigits[7] * 2) +
        (vatDigits[9] * 2 > 9 ? vatDigits[9] * 2 - 9 : vatDigits[9] * 2)

      const totalSum = sumX + sumY
      const checkDigit = totalSum % 10
      const calculatedEleventhNumber = (10 - checkDigit) % 10

      if (calculatedEleventhNumber !== vatDigits[10]) {
        setErrorVatNumber('Numero di Partita IVA non valido.')
        return false
      }
    }

    setErrorVatNumber('')
    return true
  }

  function validateActivity () {
    const activity = activityRef.current.value
    if (!activity) {
      setErrorActivity('Inserisci la tua attività')
      return false
    }
    const trimmedActivity = activity.trim()
    if (!trimmedActivity) {
      setErrorActivity('Attivita non può essere vuoto.')
      return false
    }
    if (!/^[a-zA-Z\s]+$/.test(trimmedActivity)) {
      setErrorActivity('Inserisci solo caratteri alfabetici.')
      return false
    }
    setErrorActivity('')
    return true
  }

  function validateCompanyEmail () {
    const company_email = companyEmailRef.current.value
    if (!company_email) {
      setErrorCompanyEmail('Inserisci la tua e-mail.')
      return false
    }
    const trimmedCompanyEmail = company_email.trim()
    if (!trimmedCompanyEmail) {
      setErrorCompanyEmail("L'e-mail non può essere vuota.")
      return false
    }
    if (
      !trimmedCompanyEmail.match(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      )
    ) {
      setErrorCompanyEmail('Inserisci un ID e-mail valido.')
      return false
    }
    setErrorCompanyEmail('')
    return true
  }

  function validateEmail (i) {
    let formIsValid = true

    if (!formFocusRefs.reference.current[i]) {
      formFocusRefs.reference.current[i] = {}
    }

    const emailFilled = formFocusRefs.reference.current[i].email?.value

    if (!emailFilled) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].email = 'Inserisci la tua e-mail.'
      setErrorReference(updatedErrorReference)
      const updatedEmailVerify = [...emailVerify]
      updatedEmailVerify[i] = false
      setEmailVerify(updatedEmailVerify)
    }

    const trimmedEmail = emailFilled?.trim()

    if (formIsValid && !trimmedEmail) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].email = "L'e-mail non può essere vuota."
      setErrorReference(updatedErrorReference)
      const updatedEmailVerify = [...emailVerify]
      updatedEmailVerify[i] = false
      setEmailVerify(updatedEmailVerify)
    }

    if (
      formIsValid &&
      !trimmedEmail?.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    ) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].email = 'Inserisci un ID e-mail valido.'
      setErrorReference(updatedErrorReference)
      const updatedEmailVerify = [...emailVerify]
      updatedEmailVerify[i] = false
      setEmailVerify(updatedEmailVerify)
    }

    if (formIsValid) {
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].email = ''
      setErrorReference(updatedErrorReference)
    }

    return formIsValid
  }

  function validateFname (i) {
    let formIsValid = true
    const firstNameFilled = formFocusRefs.reference.current[i].first_name.value
    if (!firstNameFilled) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].first_name = 'Inserisci il tuo nome.'
      setErrorReference(updatedErrorReference)
    }

    const trimmedFirstName = firstNameFilled.trim()

    if (formIsValid && !trimmedFirstName) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].first_name = 'Il nome non può essere vuoto.'
      setErrorReference(updatedErrorReference)
    }

    if (formIsValid && !/^[a-zA-Z\s]+$/.test(trimmedFirstName)) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].first_name =
        'Inserisci solo caratteri alfabetici.'
      setErrorReference(updatedErrorReference)
    }

    if (formIsValid) {
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].first_name = ''
      setErrorReference(updatedErrorReference)
    }

    return formIsValid
  }

  function validateLname (i) {
    let formIsValid = true
    const lastNameFilled = formFocusRefs.reference.current[i].last_name.value

    if (!lastNameFilled) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].last_name = 'Inserisci il tuo cognome.'
      setErrorReference(updatedErrorReference)
    }

    const trimmedLastName = lastNameFilled.trim()

    if (formIsValid && !trimmedLastName) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].last_name = 'Il cognome non può essere vuoto.'
      setErrorReference(updatedErrorReference)
    }

    if (formIsValid && !/^[a-zA-Z\s]+$/.test(trimmedLastName)) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].last_name =
        'Inserisci solo caratteri alfabetici.'
      setErrorReference(updatedErrorReference)
    }

    if (formIsValid) {
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].last_name = ''
      setErrorReference(updatedErrorReference)
    }

    return formIsValid
  }

  const validatePhone = i => {
    let formIsValid = true
    const phone_no = formFocusRefs.reference.current[i].phone.props.value

    if (!phone_no) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].phone = 'Inserisci il numero di telefono.'
      setErrorReference(updatedErrorReference)
    } else if (phone_no.length < 5) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].phone =
        'Il numero di telefono deve contenere almeno 5 cifre.'
      setErrorReference(updatedErrorReference)
    }

    if (formIsValid) {
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].phone = ''
      setErrorReference(updatedErrorReference)
    }

    return formIsValid
  }

  //================= Validation Sector ===============================

  const validateSector = () => {
    if (!sectorRef?.current?.props?.value) {
      setErrorSector('Seleziona il tuo Settore.')
      return false
    } else {
      setErrorSector('')
      return true
    }
  }

  // useEffect(() => {
  //   validateSubSector();
  // }, [selectedOptionsSector]);

  const validateSubSector = () => {
    //   if (subsectorRef?.current) {
    //     const subSectorMenu = subsectorRef.current.value;
    //     if (!subSectorMenu) {
    //       setErrorSubSector("Seleziona il tuo Sottosettore.");
    //       return false;
    //     }
    //   }
    //   setErrorSubSector("");
    return true
  }

  function validateStreetNumber () {
    const street_number = streetNumberRef.current.value
    if (!street_number) {
      seterrorStreetnumber('Inserisci il numero civico.')
      return false
    }
    const trimmedStreetnumber = street_number.trim()
    if (!trimmedStreetnumber) {
      seterrorStreetnumber('Il numero civico non può essere vuoto.')
      return false
    }
    seterrorStreetnumber('')
    return true
  }

  function validateZipCode () {
    const zip_code = zipCodeRef.current.value
    if (!zip_code) {
      seterrorZipcode('Inserisci il tuo Cap.')
      return false
    }
    const trimmedZipcode = zip_code.trim()
    if (!trimmedZipcode) {
      seterrorZipcode('Il CAP non può essere vuoto.')
      return false
    }
    if (!/^\d+$/.test(trimmedZipcode)) {
      seterrorZipcode('Il CAP può contenere solo numeri.')
      return false
    }
    if (trimmedZipcode.length < 5) {
      seterrorZipcode('Il CAP deve contenere almeno 5 cifre.')
      return false
    }
    seterrorZipcode('')
    return true
  }

  function validateCountry () {
    if (countryRef.current !== null) {
      const country = countryRef.current.value
      if (!country) {
        seterrorCountry('Nazione non selezionato.')
        return false
      }
      seterrorCountry('')
      return true
    }
  }

  function validateProvince () {
    if (provinceRef?.current !== null) {
      const province = provinceRef?.current?.value
      if (!province) {
        seterrorProvince('Inserisci la tua provincia.')
        return false
      }
      seterrorProvince('')
      return true
    } else {
      return true
    }
  }

  const validateCity = () => {
    let city

    if (data?.country === 'Italia') {
      if (data !== 'undefined') {
        if (!data?.city || !data?.province) {
          seterrorCity('Comune non selezionato.')
          return false
        }
      }
    } else {
      if (cityRef?.current) {
        city = cityRef.current.value ?? ''
        if (!city.trim()) {
          seterrorCity('Inserisci la tua Comune.')
          return false
        }
      }
    }
    seterrorCity('')
    return true
  }

  const validatePrivacy = () => {
    const privacy = eula_consentRef.current.checked
    if (!privacy) {
      setErrorPrivacyAccepted('Questo campo è obbligatorio.')
      return false
    } else {
      setErrorPrivacyAccepted('')
      return true
    }
  }

  const validatePrivacyContent = () => {
    const privacy = privacy_consentRef.current.checked
    if (!privacy) {
      setErrorPrivacyContentAccepted('Questo campo è obbligatorio.')
      return false
    } else {
      setErrorPrivacyContentAccepted('')
      return true
    }
  }

  const generatePassword = () => {
    const pattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/
    const characters =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@$!%*?&'
    let password = ''
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      password += characters.charAt(randomIndex)
    }
    if (!pattern.test(password)) {
      return generatePassword()
    }
    return password
  }

  const handleAutoGeneratePassword = () => {
    const newPassword = generatePassword()
    setAutoPassword(newPassword)
  }

  const onChangeData = e => {
    const { name, value, type, files } = e.target
    let newValue

    if (type === 'number') {
      newValue = value.replace(/\D/g, '').slice(0, 5)
    } else if (type === 'file') {
      newValue = files[0]
    } else {
      newValue = value
    }

    if (name === 'country') {
      if (value === 'Italia') {
        setData(prevData => ({
          ...prevData,
          country: newValue,
          city: '',
          province: ''
        }))
      } else {
        setData(prevData => ({
          ...prevData,
          country: newValue,
          city: '',
          province: ''
        }))
      }
    } else {
      setData(prevData => ({ ...prevData, [name]: newValue }))
    }
  }

  const handleChangeSDICode = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleOnVatnumber = e => {
    const value = e.target.value
    const trimmedValue = value.replace(/\D/g, '').slice(0, 11)
    setData({ ...data, [e.target.name]: trimmedValue })
  }

  //===========================Sector Level ==============================

  const handleSectorLevelChange = e => {
    setIsLoading(true)
    if (e !== null) {
      setTimeout(() => {
        const updatedSelectedOptions = e ? e.label : ''
        setData(prevData => ({
          ...prevData,
          sector: updatedSelectedOptions
        }))
        setSelectedOptionsSector(prevOptions => ({
          ...prevOptions,
          submenu: updatedSelectedOptions,
          subSubmenu: ''
        }))
        setIsLoading(false)
        setErrorSector('')
      }, 50)
    } else {
      setData(prevData => ({ ...prevData, sector: '' }))
      setSelectedOptionsSector(prevOptions => ({
        ...prevOptions,
        submenu: '',
        subSubmenu: ''
      }))
      setIsLoading(false)
      validateSector()
    }
  }

  const handleSubSectorLevelChange = e => {
    const { value } = e.target

    setSelectedOptionsSector(prevOptions => {
      const newOptions = {
        ...prevOptions,
        subSubmenu: value
      }

      const sector = `${newOptions.submenu}, ${value}`
      setData(prevData => ({
        ...prevData,
        sector
      }))

      return newOptions
    })
  }

  const handleInputReference = (e, i) => {
    let { name, value } = e.target || { name: 'phone', value: e }

    if (name === 'phone' && value && !value.startsWith('+')) {
      value = '+' + value
    }

    const newList = [...reference]
    newList[i][name] = value
    setReference(newList)
  }

  const handleOnEmailVerifyfinal = async (x, i) => {
    const isEmailValid = validateEmail(i)
    if (!isEmailValid) {
      const newList = [...reference]
      newList[i].email = x.email
      setReference(newList)
      return
    }

    setLoadingForRefer(true)

    try {
      await props.requestGetCandidate({
        data: {
          username: x.email
        }
      })

      ReferIndexRef.current = i

      // await new Promise(resolve => setTimeout(resolve, 3000));

      // const candidateDetails = await props.candidate.GetCandidateData;
      // console.log(candidateDetails, "candidateDetails");

      // const newList = [...reference];
      // if (candidateDetails !== undefined) {
      //     if (candidateDetails?.data?.Status === "Success") {
      //         const updateData = candidateDetails?.data?.Message[0] || {};
      //         newList[i] = {
      //             ...newList[i],
      //             email: x.email,
      //             first_name: updateData.name || "",
      //             last_name: updateData.surname || "",
      //             phone: updateData.cellphone || "",
      //         };
      //         toast.success(candidateDetails?.data?.Status, { theme: "colored", autoClose: 8000 });
      //         props.candidate.GetCandidateData = undefined;
      //     } else {
      //         newList[i] = {
      //             ...newList[i],
      //             email: x.email,
      //             first_name: "",
      //             last_name: "",
      //             phone: "+39",
      //         };
      //         toast.error(candidateDetails?.data?.Message, { theme: "colored", autoClose: 8000 });
      //         props.candidate.GetCandidateData = undefined;
      //     }

      //     const updatedEmailVerify = [...emailVerify];
      //     updatedEmailVerify[i] = true;
      //     setEmailVerify(updatedEmailVerify);

      // } else {
      //     newList[i].email = x.email;
      // }
      // setReference(newList);
    } catch (error) {
      console.error('Failed to fetch candidate data', error)
    } finally {
      setLoadingForRefer(false)
    }
  }

  // const handleOnEmailVerify = async (x, i) => {
  //     const isEmailValid = validateEmail(i);

  //     if (!isEmailValid) {
  //         const newList = [...reference];
  //         newList[i].email = x.email;
  //         setReference(newList);
  //         return;
  //     }

  //     try {
  //         await props.requestGetCandidate({
  //             data: { username: x.email },
  //         });

  //         ReferIndexRef.current = i;
  //     } catch (error) {
  //         console.error("Failed to fetch candidate data", error);
  //     }
  // };

  // const handleOnEmailVerify = async (x, i) => {
  //     const isEmailValid = validateEmail(i);

  //     if (!isEmailValid) {
  //         const newList = [...reference];
  //         newList[i].email = x.email;
  //         setReference(newList);
  //         return;
  //     }

  //     try {
  //         const payload = { email: x.email };
  //         const secretKey = new TextEncoder().encode('GU#<#0~9r-kC(SUEb<qOw_ya,,a`mGl[SwNrlvGQ(U>*hS<l#mM:3>){$Aooih!');

  //         const token = await new SignJWT(payload)
  //             .setProtectedHeader({ alg: 'HS256' })
  //             .setExpirationTime('5m')
  //             .sign(secretKey);

  //         await props.requestCompanyJWT({
  //             data: {
  //                 token: token
  //             }
  //         })
  //         if (props.company.companyJwt !== undefined) {
  //             const tokenVerify = props.company.companyJwt
  //             if (tokenVerify.data.Status === "Success") {
  //                 await props.requestCompanyReference({
  //                     data: {
  //                         username: x.email,
  //                     },
  //                     token: token
  //                 });
  //                 ReferIndexRef.current = i;
  //             }
  //         } else {
  //             props.company.companyJwt = undefined;
  //         }
  //     } catch (error) {
  //         console.error('Failed to fetch candidate data', error);
  //     }
  // };

  const handleOnEmailVerify = async (x, i) => {
    const isEmailValid = validateEmail(i)

    if (!isEmailValid) {
      const newList = [...reference]
      newList[i].email = x.email
      setReference(newList)
      return
    }

    try {
      const header = {
        alg: 'none',
        typ: 'JWT'
      }

      const payload = {
        email: x.email,
        exp: Math.floor(Date.now() / 1000) + 5 * 60
      }

      const base64UrlHeader = btoa(JSON.stringify(header))
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')

      const base64UrlPayload = btoa(JSON.stringify(payload))
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')

      const token = `${base64UrlHeader}.${base64UrlPayload}.`

      console.log(token, 'Token')
      const jwtResponse = await props.requestCompanyJWT({ data: { token } })
      const jwtToken = jwtResponse.obj?.data?.token

      console.log('JWT Response:', jwtResponse)
      console.log('Extracted Token:', jwtToken)

      if (jwtToken) {
        const tokenVerify = jwtResponse.obj?.data?.token
        if (tokenVerify) {
          await props.requestCompanyReference({
            data: { username: x.email },
            token: tokenVerify
          })
          ReferIndexRef.current = i
        } else {
          console.warn('Token is not available in the JWT response')
        }
      } else {
        console.warn('JWT token response is undefined')
      }
    } catch (error) {
      console.error('Failed to fetch candidate data:', error)
    }
  }

  // useEffect(() => {
  //     const candidateDetails = props.candidate.GetCandidateData;

  //     if (candidateDetails) {
  //         const newList = [...reference];
  //         const updateData = candidateDetails?.data?.Message[0] || {};
  //         const indexToUpdate = ReferIndexRef.current;

  //         if (indexToUpdate !== -1) {
  //             if (candidateDetails?.data?.Status === "Success") {
  //                 newList[indexToUpdate] = {
  //                     ...newList[indexToUpdate],
  //                     email: updateData.e_mail || "",
  //                     first_name: updateData.name || "",
  //                     last_name: updateData.surname || "",
  //                     phone: updateData.cellphone || "",
  //                 };
  //                 toast.success(candidateDetails?.data?.Status, { theme: "colored", autoClose: 8000 });
  //                 props.candidate.GetCandidateData = undefined;
  //             } else {
  //                 newList[indexToUpdate] = {
  //                     ...newList[indexToUpdate],
  //                     email: reference[indexToUpdate]?.email || "",
  //                     first_name: "",
  //                     last_name: "",
  //                     phone: "+39",
  //                 };
  //                 toast.error(candidateDetails?.data?.Message, { theme: "colored", autoClose: 8000 });
  //                 props.candidate.GetCandidateData = undefined;
  //             }

  //             const updatedEmailVerify = [...emailVerify];
  //             updatedEmailVerify[indexToUpdate] = true;
  //             setEmailVerify(updatedEmailVerify);
  //             setReference(newList);
  //         }
  //     }
  // }, [props.candidate.GetCandidateData]);

  useEffect(() => {
    const candidateReferData = props.company.referenceData

    if (candidateReferData !== undefined) {
      setLoadingForRefer(true)
      const timer = setTimeout(() => {
        const newList = [...reference]
        const indexToUpdate = ReferIndexRef.current

        if (indexToUpdate !== -1) {
          if (candidateReferData?.data?.Status === 'Success') {
            const updateData = candidateReferData?.data?.Message || {}
            newList[indexToUpdate] = {
              ...newList[indexToUpdate],
              email: reference[indexToUpdate]?.email || '',
              first_name: updateData.name || '',
              last_name: updateData.surname || '',
              phone: updateData.cellphone || ''
            }
            toast.success(candidateReferData?.data?.Status, {
              theme: 'colored',
              autoClose: 8000
            })
          } else {
            newList[indexToUpdate] = {
              ...newList[indexToUpdate],
              email: reference[indexToUpdate]?.email || '',
              first_name: '',
              last_name: '',
              phone: '+39'
            }
            // toast.error(candidateReferData?.data?.Message, { theme: "colored", autoClose: 8000 });
          }

          const updatedEmailVerify = [...emailVerify]
          updatedEmailVerify[indexToUpdate] = true
          setEmailVerify(updatedEmailVerify)
          setReference(newList)
        }
        setLoadingForRefer(false)
        props.company.referenceData = undefined
        // props.userLogout();
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [props.company.referenceData])

  // useEffect(() => {
  //     const candidateDetails = props.candidate.GetCandidateData;

  //     if (candidateDetails !== undefined) {
  //         setLoadingForRefer(true);
  //         const timer = setTimeout(() => {
  //             const newList = [...reference];
  //             if (candidateDetails?.data?.detail) {
  //                 toast.error(candidateDetails?.data?.detail, { theme: "colored", autoClose: 8000 });
  //                 setLoadingForRefer(false);
  //                 props.userLogout();
  //             } else {
  //                 const updateData = candidateDetails?.data?.Message[0] || {};
  //                 const indexToUpdate = ReferIndexRef.current;

  //                 if (indexToUpdate !== -1) {
  //                     if (candidateDetails?.data?.Status === "Success") {
  //                         newList[indexToUpdate] = {
  //                             ...newList[indexToUpdate],
  //                             email: updateData.e_mail || "",
  //                             first_name: updateData.name || "",
  //                             last_name: updateData.surname || "",
  //                             phone: updateData.cellphone || "",
  //                         };
  //                         toast.success(candidateDetails?.data?.Status, { theme: "colored", autoClose: 8000 });
  //                     } else {
  //                         newList[indexToUpdate] = {
  //                             ...newList[indexToUpdate],
  //                             email: reference[indexToUpdate]?.email || "",
  //                             first_name: "",
  //                             last_name: "",
  //                             phone: "+39",
  //                         };
  //                         toast.error(candidateDetails?.data?.Message, { theme: "colored", autoClose: 8000 });
  //                     }

  //                     const updatedEmailVerify = [...emailVerify];
  //                     updatedEmailVerify[indexToUpdate] = true;
  //                     setEmailVerify(updatedEmailVerify);
  //                     setReference(newList);
  //                 }
  //                 setLoadingForRefer(false);
  //                 props.candidate.GetCandidateData = undefined;
  //                 props.userLogout()
  //             }
  //         }, 2000);

  //         return () => clearTimeout(timer);
  //     }
  // }, [props.candidate.GetCandidateData]);

  const handleAddReference = () => {
    let length = reference.length
    if (length === 0) {
      setReference([
        ...reference,
        {
          email: '',
          first_name: '',
          last_name: '',
          phone: ''
        }
      ])

      setErrorReference([
        ...errorreference,
        {
          email: '',
          first_name: '',
          last_name: '',
          phone: ''
        }
      ])
    } else if (
      reference[length - 1].email === '' ||
      reference[length - 1].first_name === '' ||
      reference[length - 1].last_name === '' ||
      reference[length - 1].phone === ''
    ) {
      Swal.fire(
        'Errore!',
        'Dopo aver completato quello precedente, puoi aggiungerne uno nuovo.',
        'error'
      )
      return
    } else {
      setReference([
        ...reference,
        {
          email: '',
          first_name: '',
          last_name: '',
          phone: ''
        }
      ])
      setErrorReference([
        ...errorreference,
        {
          email: '',
          first_name: '',
          last_name: '',
          phone: ''
        }
      ])
    }
  }

  const handleRemoveReference = index => {
    let updateReference = [...reference]
    console.log(updateReference)
    if (index) {
      updateReference.splice(index, 1)
    } else {
      updateReference = [
        {
          email: '',
          first_name: '',
          last_name: '',
          phone: ''
        }
      ]
    }
    setReference(updateReference)

    const updateErrorReference = [...errorreference]
    index && updateErrorReference.splice(index, 1)
    setErrorReference(updateErrorReference)
  }

  const handleChangeCity = async selectedOptions => {
    setIsLoading(true)
    if (selectedOptions !== null) {
      setTimeout(() => {
        const [city, province] = selectedOptions?.label.split(', ')
        setData(prevData => ({
          ...prevData,
          city: city,
          province: province
        }))
        setSelectedCity(selectedOptions)
        setIsLoading(false)
        seterrorCity('')
      }, 1000)
    } else {
      setData(prevData => ({
        ...prevData,
        city: '',
        province: ''
      }))
      setSelectedCity(null)
      setIsLoading(false)
    }
  }

  const handleOndimension = selectedOption => {
    if (selectedOption) {
      setData({ ...data, dimension: selectedOption.label })
    } else {
      setData({ ...data, dimension: '' })
    }
  }

  const handleCheckboxChange1 = e => {
    setPrivacyAccepted(e.target.checked)
    if (e.target.checked) {
      setErrorPrivacyAccepted('')
    }
    validatePrivacy()
  }

  const handleCheckboxChange2 = e => {
    setPrivacyContentAccepted(e.target.checked)
    if (e.target.checked) {
      setErrorPrivacyContentAccepted('')
    }
    validatePrivacyContent()
  }

  const validateReferenceForm = () => {
    if (reference.length > 0) {
      return reference.every((_, i) => validateEmail(i))
    }
    return false
  }

  useEffect(() => {
    reference?.forEach((lang, i) => {
      if (emailVerify[i]) {
        if (validateEmail(i)) {
          validateFname(i)
          validateLname(i)
          validatePhone(i)
        }
      }
    })
  }, [reference])

  function validateForm () {
    let companyN = validateCompany()
    let dimensionV = validateDimension()
    let sdiCodeV = validateSDIcode()
    let activityV = validateActivity()
    let vatnumberV = validateVatNumber()
    let Companyemail = validateCompanyEmail()
    let sectorV = validateSector()
    // let subsectorV = validateSubSector();
    let streetNum = validateStreetNumber()

    let referalData = validateReferenceForm()
    let zipCode = validateZipCode()
    let countryV = validateCountry()
    let cityV = validateCity()
    let provinceV = validateProvince()
    let privacy = validatePrivacy()
    let privacyContent = validatePrivacyContent()

    const validall =
      companyN &&
      dimensionV &&
      sdiCodeV &&
      activityV &&
      vatnumberV &&
      Companyemail &&
      sectorV &&
      // subsectorV &&
      referalData &&
      streetNum &&
      zipCode &&
      countryV &&
      cityV &&
      provinceV &&
      privacy &&
      privacyContent
    return validall
  }

  useEffect(() => {
    const focusErrorField = () => {
      if (error) {
        if (errorCompanyname && companyNameRef.current) {
          companyNameRef?.current?.focus()
          return true
        }
        if (errorDimension && dimensionRef.current) {
          dimensionRef?.current?.focus()
          return true
        }
        if (errorSDIcode && sdiCodeRef.current) {
          sdiCodeRef.current.focus()
          return true
        }
        if (errorVatNumber && vatNumberRef.current) {
          vatNumberRef?.current?.focus()
          return true
        }
        if (errorActivity && activityRef.current) {
          activityRef?.current?.focus()
          return true
        }
        if (errorCompanyEmail && companyEmailRef.current) {
          companyEmailRef.current.focus()
          return true
        }
        if (errorSector && sectorRef.current) {
          sectorRef.current.focus()
          return true
        }
        if (errorSubSector && subsectorRef.current) {
          subsectorRef.current.focus()
          return true
        }

        for (let i = 0; i < errorreference.length; i++) {
          const referError = errorreference[i]
          if (referError.email) {
            formFocusRefs.reference.current[i]?.email?.focus()
            return true
          } else if (referError.first_name) {
            formFocusRefs.reference.current[i]?.first_name?.focus()
            return true
          } else if (referError.last_name) {
            formFocusRefs.reference.current[i]?.last_name?.focus()
            return true
          }
          // else if (referError.phone) {
          //     formFocusRefs.reference.current[i]?.phone.focus();
          //     return true;
          // }
        }
        if (errorCountry && countryRef.current) {
          countryRef.current.focus()
          return true
        }
        if (errorStreetnumber && streetNumberRef.current) {
          streetNumberRef.current.focus()
          return true
        }
        if (errorCity && cityRef.current) {
          cityRef.current.focus()
          return true
        }
        if (errorProvince && provinceRef.current) {
          provinceRef.current.focus()
          return true
        }
        if (errorZipcode && zipCodeRef.current) {
          zipCodeRef.current.focus()
          return true
        }
        if (errorprivacyAccepted && eula_consentRef.current) {
          eula_consentRef.current.focus()
          return true
        }
        if (errorPrivacyContentAccepted && privacy_consentRef.current) {
          privacy_consentRef.current.focus()
          return true
        }
      }
      return false
    }

    if (focusErrorField()) {
      setError(false)
    }
  }, [
    error,
    errorCompanyname,
    errorDimension,
    errorSDIcode,
    errorVatNumber,
    errorActivity,
    errorSector,
    errorSubSector,
    formFocusRefs,
    errorreference,
    errorCountry,
    errorCity,
    errorProvince,
    errorStreetnumber,
    errorZipcode,
    errorprivacyAccepted,
    errorPrivacyContentAccepted
  ]);

  const handlePasswordUpdate = (newPassword) => {
    const token = localStorage.getItem("token");
    if (token) {
        props.requestCompanyUpdate({
            data: {
                password: newPassword.password1,
                password2: newPassword.password2,
            },
            token: token
        })
    }
};

  function onSubmitForm (e) {
    e.preventDefault()
    // props.userLogout();
    if (reference.length === 0) {
      setReference([
        ...reference,
        {
          email: '',
          first_name: '',
          last_name: '',
          phone: ''
        }
      ])
      setErrorReference([
        ...errorreference,
        {
          email: '',
          first_name: '',
          last_name: '',
          phone: ''
        }
      ])
    }

    if (validateForm()) {
      const token = localStorage.getItem('token')

      // const modifiedReference = reference.map((rep) => ({
      //     ...rep,
      //     username: rep.email,
      //     password: autopassword,
      //     password2: autopassword,
      //     eula_consent: privacyAccepted,
      //     privacy_consent: privacyContentAccepted,
      //     cellphone:rep.phone
      // }));

      const modifiedReference = reference.map(({ phone, ...rest }) => ({
        ...rest,
        username: rest.email,
        password: autopassword,
        password2: autopassword,
        eula_consent: privacyAccepted,
        privacy_consent: privacyContentAccepted,
        cellphone: phone
      }))

      props.requestCompanyUpdate({
        data: {
          name: data.company_name,
          e_mail: data.company_email,
          type: data.activity,
          sector: {
            sectorName: data.sector
          },
          subsector: {
            subSector_name: data.subsector ?? '-'
          },
          vat_number: data.vat_number,
          size: data.dimension,
          reps: modifiedReference,
          sdi: data.sdi_code,

          address: {
            street: data.street_number,
            city: data.city,
            zip_code: data.zip_code,
            state: null,
            province: data.province,
            country: data.country
          },

          eula_consent: privacyAccepted,
          privacy_consent: privacyContentAccepted
        },
        token: token
      })

      setError(false)
    } else {
      setError(true)
    }
  }

  useEffect(() => {
    let companyUpdateData = props.company.companyUpdateData
    if (companyUpdateData !== undefined) {
      if (companyUpdateData.data.Status === 'Success') {
        toast.success(companyUpdateData.data.Status, {
          theme: 'colored',
          autoClose: 8000
        })
        navigate('/')
        props.company.companyUpdateData = undefined
      } else {
        toast.error(companyUpdateData.data.Message, {
          theme: 'colored',
          autoClose: 8000
        })
        setError(true)
        props.company.companyUpdateData = undefined
      }
    }
  }, [props.company.companyUpdateData])

  useEffect(() => {}, [props.company.getCompanyData]);

  const handleOnPasswordConfirm = (name) => {
    setShowModal(name);
};

const handleCloseModal = () => {
    setShowModal('');
};

  return (
    <>
      <Header />
      <div className='registration_sec'>
        <div className='container'>
          <div className='new_research_btn mt-4 text-end'>
            <button
              className='btn btn_default btn_orange_outline'
              type='button'
              onClick={() => handleOnPasswordConfirm('password-confirm')}
            >
              Cambia password
            </button>
          </div>
          <form className='registration_form' onSubmit={onSubmitForm}>
            <div className='registration_title'>
              <h3>Profilo Azienda</h3>
            </div>
            <div className='row registration_row g-4'>
              <div className='registration_col form-group col-md-6 col-12'>
                <label htmlFor='company_name' className='label'>
                  Ragione Sociale
                </label>
                <input
                  type='text'
                  className={`form-control set_max_width ${
                    errorCompanyname ? 'error' : ''
                  }`}
                  placeholder='Ragione Sociale'
                  id='company_name'
                  name='company_name'
                  ref={companyNameRef}
                  value={data.company_name || ''}
                  onChange={onChangeData}
                  onBlur={validateCompany}
                />
                {errorCompanyname && (
                  <div style={mystyle}>{errorCompanyname}</div>
                )}
              </div>
              <div className='registration_col form-group col-md-6 col-12'>
                <label htmlFor='dimension' className='label'>
                  Dimensione
                </label>
                {/* <input
                                        type="text"
                                        className={`form-control set_max_width ${errorDimension ? "error" : ""
                                            }`}
                                        placeholder="Dimensione"
                                        id="dimension"
                                        name="dimension"
                                        ref={dimensionRef}
                                        value={data.dimension || ""}
                                        onChange={onChangeData}
                                        onBlur={validateDimension}
                                    /> */}

                <Select
                  name='dimension'
                  options={companySizes}
                  className={`form-control set_max_width select_dropdown ${
                    errorDimension ? 'error' : ''
                  }`}
                  value={
                    data.dimension
                      ? { label: data.dimension, value: data.dimension }
                      : null
                  }
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  ref={dimensionRef}
                  onChange={handleOndimension}
                  onBlur={validateDimension}
                  filterOption={createFilter(filterConfig)}
                  placeholder='Dimensione'
                />
                {errorDimension && <div style={mystyle}>{errorDimension}</div>}
              </div>

              <SDICode
                data={data}
                handleChangeSDICode={handleChangeSDICode}
                sdiCodeRef={sdiCodeRef}
                //validateSDIcode={validateSDIcode}
                errorSDIcode={errorSDIcode}
                mystyle={mystyle}
                t={t}
              />

              <VatValidator
                data={data}
                handleOnVatnumber={handleOnVatnumber}
                validateVatNumber={validateVatNumber}
                errorVatNumber={errorVatNumber}
                vatNumberRef={vatNumberRef}
                mystyle={mystyle}
              />
              <div className='registration_col form-group col-md-6 col-12'>
                <label htmlFor='activity' className='label'>
                  Attivita
                </label>
                <input
                  type='text'
                  className={`form-control set_max_width ${
                    errorActivity ? 'error' : ''
                  }`}
                  placeholder='Inserisci l’attività che offri'
                  id='activity'
                  name='activity'
                  ref={activityRef}
                  value={data.activity || ''}
                  onChange={onChangeData}
                  onBlur={validateActivity}
                />
                {errorActivity && <div style={mystyle}>{errorActivity}</div>}
              </div>

              <div className='registration_col form-group col-md-6 col-12'>
                <label htmlFor='email' className='label'>
                  {/* E-mail */}
                  {t('register.email')}
                </label>
                <input
                  type='email'
                  className={`form-control set_max_width ${
                    errorCompanyEmail ? 'error' : ''
                  }`}
                  placeholder={t('register.email')}
                  id='company_email'
                  name='company_email'
                  ref={companyEmailRef}
                  value={data.company_email || ''}
                  onChange={onChangeData}
                  onBlur={validateCompanyEmail}
                />
                {errorCompanyEmail && (
                  <div style={mystyle}>{errorCompanyEmail}</div>
                )}
              </div>

              <SectorLevel
                selectedOptionsSector={selectedOptionsSector}
                handleSectorLevelChange={handleSectorLevelChange}
                validateSector={validateSector}
                sectorRef={sectorRef}
                errorSector={errorSector}
                handleSubSectorLevelChange={handleSubSectorLevelChange}
                validateSubSector={validateSubSector}
                subsectorRef={subsectorRef}
                errorSubSector={errorSubSector}
                mystyle={mystyle}
                t={t}
              />

              {/* <div className="registration_col form-group col-md-6 col-12">
                                    <label htmlFor="phone" className="label">
                                        Numero di telefono
                                    </label>
                                    <PhoneInput
                                        className={`form-control set_max_width numero_cellulare ${errorphone ? "error" : ""
                                            }`}
                                        country={"it"}
                                        localization={it}
                                        value={data?.phone || ""}
                                        ref={phonRef}
                                        placeholder={t("register.cellphone")}
                                        onChange={(e) => handlePhoneChange(e)}
                                        onBlur={validatePhone}
                                        enableLongNumbers={18}
                                    />
                                    {errorphone && <div style={mystyle}>{errorphone}</div>}
                                </div> */}
            </div>

            <Referent
              //===============email======================

              reference={reference}
              errorreference={errorreference}
              handleInputReference={handleInputReference}
              formFocusRefs={formFocusRefs}
              validateEmail={validateEmail}
              loadingForRefer={loadingForRefer}
              //===============first name=====================

              validateFname={validateFname}
              //===============last name======================

              validateLname={validateLname}
              //===============telephone======================
              validatePhone={validatePhone}
              handleAddReference={handleAddReference}
              handleRemoveReference={handleRemoveReference}
              mystyle={mystyle}
              t={t}
              handleOnEmailVerify={handleOnEmailVerify}
              emailVerify={emailVerify}
            />

            <div className='contry_form'>
              <h6 className='contry_form_title'>INDIRIZZO</h6>
              <LocationForm
                data={data}
                //==================Country================

                countryRef={countryRef}
                onChangeData={onChangeData}
                validateCountry={validateCountry}
                sortedCountryData={sortedCountryData}
                errorCountry={errorCountry}
                //===========Street=========================

                validateStreetNumber={validateStreetNumber}
                streetNumberRef={streetNumberRef}
                errorStreetnumber={errorStreetnumber}
                //=============ProvinceCommue===============

                municipalities={municipalities}
                selectedCity={selectedCity}
                isLoading={isLoading}
                handleChangeCity={handleChangeCity}
                cityRef={cityRef}
                validateCity={validateCity}
                errorCity={errorCity}
                provinceRef={provinceRef}
                validateProvince={validateProvince}
                errorProvince={errorProvince}
                //=======ZipCode=====================

                zipCodeRef={zipCodeRef}
                validateZipCode={validateZipCode}
                errorZipcode={errorZipcode}
                mystyle={mystyle}
                t={t}
              />
            </div>

            <TermsAndService
              privacyAccepted={privacyAccepted}
              privacyContentAccepted={privacyContentAccepted}
              handleCheckboxChange1={handleCheckboxChange1}
              handleCheckboxChange2={handleCheckboxChange2}
              validatePrivacy={validatePrivacy}
              validatePrivacyContent={validatePrivacyContent}
              errorprivacyAccepted={errorprivacyAccepted}
              errorPrivacyContentAccepted={errorPrivacyContentAccepted}
              eula_consentRef={eula_consentRef}
              privacy_consentRef={privacy_consentRef}
              mystyle={mystyle}
              t={t}
            />

            <div className='registration_button text-center'>
              <button
                type='submit'
                className='btn'
                onClick={handleAutoGeneratePassword}
              >
                {/* REgistra la mia azienda! */}
                aggiorna i miei dati
              </button>
            </div>
          </form>
        </div>
      </div>
       {/* Update Pasword Modal */}
       {showModal === 'password-confirm' && (
                <div className="modal fade show custom_modal" tabIndex="-1" aria-labelledby="passwordConFirmModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <PasswordResetConfirm
                                    onPasswordUpdate={handlePasswordUpdate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
      <Footer />
    </>
  )
}

const mapStateToProps = state => {
  return { company: state.company, candidate: state.candidate }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestGetList,
      requestGetCandidate,
      requestCompanyJWT,
      requestCompanyReference,
      requestCompanyDetails,
      requestCompanyUpdate,
      userLogout
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile)
