import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../images/logo.png";
import PswHide from "../../images/psw_hide.png";
import PswVisible from "../../images/psw_visible.svg";

function PasswordResetConfirm({ onPasswordUpdate }) {
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [errorpassword1, seterrorpassword1] = useState("");
    const [errorpassword2, seterrorpassword2] = useState("");
    const [error, setError] = useState(false);
    const [updateData, setUpdateData] = useState({});
    const { t } = useTranslation();

    const mystyle = {
        color: "#D10000",
        padding: "3px 10px",
    };

    function onChangeDataPass1(event) {
        const { name, value } = event.target;
        const truncatedValue = value.slice(0, 28);
        setUpdateData({
            ...updateData,
            [name]: truncatedValue.trim(),
        });
    }

    function onChangeDataPass2(event) {
        const { name, value } = event.target;
        const truncatedValue = value.slice(0, 28);
        setUpdateData({
            ...updateData,
            [name]: truncatedValue.trim(),
        });
    }

    function validatePassword1() {
        let password1 = updateData.password1;

        if (!password1) {
            seterrorpassword1("Inserisci la tua password.");
            return false;
        } else if (password1.length < 8) {
            seterrorpassword1("La password deve contenere almeno 8 caratteri.");
            return false;
        } else {
            seterrorpassword1("");
            return true;
        }
    }

    function validatePassword2() {
        let password2 = updateData.password2;

        if (!password2) {
            seterrorpassword2("Inserisci la tua password.");
            return false;
        } else if (password2.length < 8) {
            seterrorpassword2("La password deve contenere almeno 8 caratteri.");
            return false;
        } else if (updateData.password1 !== password2) {
            seterrorpassword2("Le password non coincidono.");
            return false;
        } else {
            seterrorpassword2("");
            return true;
        }
    }

    function validateForm() {
        let pass1 = validatePassword1();
        let pass2 = validatePassword2();
        let valid = pass1 && pass2;
        return valid;
    }

    function onSubmitForm(e) {
        e.preventDefault();
        if (validateForm()) {
            onPasswordUpdate(updateData);
            setError(false);
        } else {
            setError(true);
        }
    }

    useEffect(() => {
        if (error) {
            if (errorpassword1) {
                document.getElementById("password1").focus();
            } else if (errorpassword2) {
                document.getElementById("password2").focus();
            }
            setError(false);
        }
    }, [error]);

    const isFormValid = !errorpassword1 && !errorpassword2 && updateData.password1 && updateData.password2;

    return (
        <>
            <div className="login_sec">
                <div className="container">
                    <div className="login_sec_inner">
                        <div className="login_form_social">
                            <div className="login_form">
                                <div className="min_logo">
                                    <img src={Logo} alt="Main Logo" />
                                </div>
                                <div className="login_title">
                                    <h1>Password Update</h1>
                                </div>
                                <form onSubmit={onSubmitForm}>
                                    <div className="form-group">
                                        <div className="psw_label">
                                            <label htmlFor="password1" className="label">
                                                Password
                                            </label>
                                            <div className="psw_show_btn">
                                                <a onClick={() => setShowPassword1(!showPassword1)}>
                                                    {showPassword1 ? (
                                                        <>
                                                            <img src={PswHide} alt="Hide" /> Nascondi
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img src={PswVisible} alt="Show" /> Scopri
                                                        </>
                                                    )}
                                                </a>
                                            </div>
                                        </div>
                                        <input
                                            type={showPassword1 ? "text" : "password"}
                                            className={`form-control ${errorpassword1 ? "error" : ""}`}
                                            id="password1"
                                            placeholder="Password"
                                            name="password1"
                                            value={updateData.password1 || ""}
                                            onChange={onChangeDataPass1}
                                            onBlur={validatePassword1}
                                        />
                                        {errorpassword1 && <div style={mystyle}>{errorpassword1}</div>}
                                    </div>

                                    <div className="form-group">
                                        <div className="psw_label">
                                            <label htmlFor="password2" className="label">
                                                Conferma Password
                                            </label>
                                            <div className="psw_show_btn">
                                                <a onClick={() => setShowPassword2(!showPassword2)}>
                                                    {showPassword2 ? (
                                                        <>
                                                            <img src={PswHide} alt="Hide" /> Nascondi
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img src={PswVisible} alt="Show" /> Scopri
                                                        </>
                                                    )}
                                                </a>
                                            </div>
                                        </div>
                                        <input
                                            type={showPassword2 ? "text" : "password"}
                                            className={`form-control ${errorpassword2 ? "error" : ""}`}
                                            id="password2"
                                            placeholder="Conferma Password"
                                            name="password2"
                                            value={updateData.password2 || ""}
                                            onChange={onChangeDataPass2}
                                            onBlur={validatePassword2}
                                        />
                                        {errorpassword2 && <div style={mystyle}>{errorpassword2}</div>}
                                    </div>

                                    <button type="submit" className="btn submit_btn" disabled={!isFormValid}>
                                        Update
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PasswordResetConfirm;
