import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
    const { i18n } = useTranslation();

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);

    useEffect(() => {
        localStorage.setItem('language', i18n.language);
    }, [i18n.language]);

    const changeLanguage = (event) => {
        const selectedLanguage = event.target.value;
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem('language', selectedLanguage); 
    };

    return (
        <div className="language_select">
            <select onChange={changeLanguage} value={i18n.language}>
                <option value="en">English</option>
                <option value="it">Italian</option>
            </select>
        </div>
    );
};

export default LanguageSelector;
