import React from 'react'
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import BackButton from '../common/BackButton';
import NextButton from '../common/NextButton';

function CreaAnnuncioSix(props) {
    const {
        nextStep,
        prevStep,
        CreatAd_Six,
        errorCreatAd_Six,
        handleOnAddQuestions,
        handleRemoveQuestions,
        handleOnCreatAdSixQuestion,
        validateFormSixQuestion,
        formFocusQuestionRefs,
        mystyle,
        t,
        newAdvertisementId,
        setStepParam
    } = props


    return (
        <>
            <Header />
            <div className="seekng_job_sec create-an-ad">
                <div className="container">
                    <form>
                        <div className="seekng_job_title_note">
                            <div className="seekng_job_title">
                                <h2>
                                {setStepParam != null ? "Modifica l'annuncio"
                                     : "Inserisci un nuovo annuncio per la ricerca di dipendenti"
                                    }
                                </h2>
                                <h6>Domande di preselezione</h6>
                            </div>
                        </div>
                        <div className="seeking_job_inner">
                            <div className="seeking_job_form">
                                <div className="seeking_form_full m-0">
                                    <div className="row g-3">
                                        <div className="col-12 mt-0">
                                            <h6>Domande per il candidato</h6>
                                            {CreatAd_Six.map((x, i) => (
                                                <div key={i} className="questions_blocks">
                                                    <div className="questions_block_inner">
                                                        <button
                                                            type="button"
                                                            className="btn"
                                                            onClick={() => handleRemoveQuestions(i)}>
                                                            <FontAwesomeIcon icon={faTrashCan} />
                                                        </button>
                                                        <div className="questions_block">
                                                            <label className="form-label">Domanda {i + 1}</label>
                                                            <input
                                                                type="text"
                                                                className={`form-control  ${CreatAd_Six[i]?.question?.length === 0 && errorCreatAd_Six[i]?.question ? "error" : ""}`}
                                                                id={`question${i}`}
                                                                name="question"
                                                                value={x.question || ""}
                                                                ref={(el) => {
                                                                    formFocusQuestionRefs.question.current[i] =
                                                                        formFocusQuestionRefs.question.current[i] || {};
                                                                    formFocusQuestionRefs.question.current[i].question = el;
                                                                }}
                                                                onChange={(e) => handleOnCreatAdSixQuestion(e, i)}
                                                                onBlur={() => validateFormSixQuestion(i)}
                                                                placeholder="Inserisci la tua domanda"
                                                            />
                                                            {CreatAd_Six[i]?.question?.length === 0 && errorCreatAd_Six[i]?.question && (
                                                                <div style={mystyle}>{errorCreatAd_Six[i]?.question}</div>
                                                            )}
                                                        </div>
                                                        <div className="add_questions_btn">
                                                            <button className="btn" type='button' onClick={handleOnAddQuestions}>AGGIUNGI DOMANDA</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="seeking_form_btn">
                            <BackButton
                                prevStep={prevStep}
                            />
                            <NextButton
                                nextStep={nextStep}
                            />
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default CreaAnnuncioSix


