import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import SatispayLogo from "../../../images/satispay.png";
import CryptoJS from 'crypto-js';
import forge from 'node-forge';


const SatispayButton = (props) => {
    const navigate = useNavigate();
    const [product, setProduct] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
   

    useEffect(() => {
        const data = props?.product;
        console.log(data, "data")
        if (!data) {
            navigate('/company/payment-system');
        } else {
            setProduct(data);
        }
    }, [navigate, props.product]);

    
    useEffect(() => {
        const script = document.createElement('script');
      
        //script.src = "https://online.satispay.com/web-button.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);

     
      function handleSatispayPayment(e) {
        e.preventDefault()
        /*var satispay = SatispayWebButton.configure({
            paymentId: '8f27fbbc-ff4b-45eb-98d0-1cadb3a0afaa',
            completed: function() {
              // executed on payment success or failure
            }
          })
        satispay.open()*/
      }
 

    return (
        <div className='satispay_btn'>
                        <button onClick={handleSatispayPayment} type='button'>
                        <img src="https://online.satispay.com/images/en-pay-red.svg" alt="Pay with Satispay" id="pay-with-satispay" style={{height: '50px', cursor: 'pointer'}} />

                        </button>
                    </div>
    );
};

export default SatispayButton;
