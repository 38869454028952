import React from 'react';
import Select, { createFilter } from 'react-select';

function LocationExp(props) {
    const {
        i,
        x,
        experience,
        setExperience,
        errorexperience,
        sortedCountryData,
        municipalities,
        validateExperienceCountry,
        validateExperienceCommon,
        validateExperienceProvince,
        handleInputExperience,
        handleChangeExprienceCity,
        focusRefs,
        isLoading,
        isClearable,
        isSearchable,
        filterConfig,
        mystyle,
        t
    } = props

    return (
        <>
            <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                <div className="form-group">
                    <label htmlFor="address.country">{t('location.country')}</label>
                    <select
                        className={`form-control ${errorexperience[i].address.country ? 'error' : ''}`}
                        name="address.country"
                        id={"country_experience" + i}
                        value={x.address.country}
                        ref={(el) => {
                            focusRefs.experience.current[i] = focusRefs.experience.current[i] || {};
                            focusRefs.experience.current[i].country_experience = el;
                        }}
                        onChange={(e) => handleInputExperience(e, i)}
                        onBlur={() => validateExperienceCountry(i)}
                        placeholder={t('location.country')}
                    >
                        <option value="">{t('location.country')}</option>
                        {sortedCountryData.map((option, index) => (
                            <option key={index} value={option.nativeCountryName}>
                                {option.nativeCountryName}
                            </option>
                        ))}
                    </select>
                    {errorexperience[i].address.country && (
                        <div style={mystyle}>
                            {errorexperience[i].address.country}
                        </div>
                    )}
                </div>
            </div>

            {experience[i]?.address?.country?.length > 0 && (
                <>
                    {experience[i]?.address?.country === "Italia" ? (
                        <>
                            <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                                <div className="form-group">
                                    <label>{t('location.city')}, {t('location.province')}</label>
                                    <Select
                                        className={`form-control select_dropdown ${errorexperience[i].address.city ? 'error' : ''}`}
                                        options={municipalities}
                                        value={x.address.city && x.address.province ? { label: `${x.address.city} ${x.address.province}` } : ""}
                                        isLoading={isLoading}
                                        isClearable={isClearable}
                                        onChange={(e) => {
                                            handleChangeExprienceCity(e, i);
                                            if (!e) {
                                                setExperience((prevExprience) => {
                                                    const newExprience = [...prevExprience];
                                                    newExprience[i].address.city = '';
                                                    newExprience[i].address.province = '';
                                                    return newExprience;
                                                });
                                            }
                                        }}
                                        onBlur={() => validateExperienceCommon(i)}
                                        isSearchable={isSearchable}
                                        ref={(el) => {
                                            focusRefs.experience.current[i] = focusRefs.experience.current[i] || {};
                                            focusRefs.experience.current[i].common_experience = el;
                                        }}
                                        placeholder="Seleziona comune e provincia"
                                        filterOption={createFilter(filterConfig)}
                                    />
                                  
                                    {errorexperience[i].address.city && (
                                        <div style={mystyle}>
                                            {errorexperience[i].address.city}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                                <div className="form-group">
                                    <label htmlFor="city">{t('location.city')}</label>
                                    <input
                                        className={`form-control ${errorexperience[i].address.city ? 'error' : ''}`}
                                        type="text"
                                        name="address.city"
                                        id={"common_experience" + i}
                                        value={x?.address?.city}
                                        ref={(el) => {
                                            focusRefs.experience.current[i] = focusRefs.experience.current[i] || {};
                                            focusRefs.experience.current[i].common_experience = el;
                                        }}
                                        onBlur={() => validateExperienceCommon(i)}
                                        onChange={(e) => handleInputExperience(e, i)}
                                        placeholder={t('location.city')}
                                    />
                                    {errorexperience[i].address.city && (
                                        <div style={mystyle}>
                                            {errorexperience[i].address.city}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                                <div className="form-group">
                                    <label htmlFor="address.province">{t('location.province')}</label>
                                    <input
                                        className={`form-control ${errorexperience[i].address.province ? 'error' : ''}`}
                                        type="text"
                                        name="address.province"
                                        id={"province_experience" + i}
                                        value={x?.address?.province}
                                        ref={(el) => {
                                            focusRefs.experience.current[i] = focusRefs.experience.current[i] || {};
                                            focusRefs.experience.current[i].province_experience = el;
                                        }}
                                        onBlur={() => validateExperienceProvince(i)}
                                        onChange={(e) => handleInputExperience(e, i)}
                                        placeholder={t('location.province')}
                                    />
                                    {errorexperience[i].address.province && (
                                        <div style={mystyle}>
                                            {errorexperience[i].address.province}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default LocationExp