import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestGetCandidate, userLogout } from "../../Redux/actions";
import { toast } from "react-toastify";
import Logo from "../../images/logo.png";
import { Link } from 'react-router-dom';
import axios from 'axios';


function PasswordReset(props) {
    const {
        setShowModal,
    } = props;
    const [data, setData] = useState({'csrfmiddlewaretoken': localStorage.getItem('cookie')});
    const [error, setError] = useState(false);
    const [erroremail, setErrorEmail] = useState("");
    const [success, setSuccess] = useState(false);

    const mystyle = {
        color: "#D10000",
        padding: "3px 10px",
    };

    function validateEmail() {
        const email = data.email;
        if (!email) {
            setErrorEmail("Inserisci la tua e-mail.");
            return false;
        }
        const trimmedEmail = email.trim();
        if (!trimmedEmail) {
            setErrorEmail("L'e-mail non può essere vuota.");
            return false;
        }
        if (!trimmedEmail.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
            setErrorEmail("Inserisci un ID e-mail valido.");
            return false;
        }
        setErrorEmail("");
        return true;
    }

    function generateResetLink(email) {
        // This should be done on the server side in real applications
        return `http://localhost:3000/static/password_reset.html`;
    }

    function onSubmitForm(e) {
        e.preventDefault();
       
        if (validateEmail()) {
            // const resetLink = generateResetLink(data.email);
           
            const config = {
                headers: {
                  'Content-Type': 'application/json',
                  //Authorization: `Bearer ${token}`,
                  //withCredentials: true,
                 // withXSRFToken: true,
                  //'X-CSRF-TOKEN': localStorage.getItem('cookie'),
                 // 'X-CSRFtoken': localStorage.getItem('cookie'),
                  //'Set-Cookie': `csrftoken=${localStorage.getItem('cookie')}`
                  //'Referer': 'https://jobportal.boiledbrains.it',
                 // cookie: `csrftoken=${localStorage.getItem('cookie')}`
                },
withCredentials: true
              }

            axios.get(`${process.env.REACT_APP_API_HOST}auth/passwordReset/`, {
                withCredentials: true
            })
            .then(response => {
                console.log(response);
axios.post(`${process.env.REACT_APP_API_HOST}auth/passwordReset/`,

                    data
                ,
                config
            )
            .then(response => {
                console.log(response);
                if(response.status === 200) {
                    setSuccess(true);
                }
              })
            });
            /*axios.post(`${process.env.REACT_APP_API_HOST}auth/passwordReset/`,
                
                    data
                ,
                config
            )
            .then(response => {
                console.log(response)
              })*/

            // props.requestSendPasswordResetLink({ email: data.email, link: resetLink })
            //     .then((response) => {
            //         setLoading(false);
            //         if (response.status === 200) {
            //             toast.success("Password reset link sent successfully.", {
            //                 theme: "colored",
            //                 autoClose: 8000,
            //             });
            //         } else {
            //             toast.error("Failed to send password reset link. Please try again.", {
            //                 theme: "colored",
            //                 autoClose: 8000,
            //             });
            //         }
            //     })
            //     .catch((error) => {
            //         setLoading(false);
            //         toast.error("An error occurred. Please try again.", {
            //             theme: "colored",
            //             autoClose: 8000,
            //         });
            //     });
        }
    }

   


    const onChangeData = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    const redirectToLogin = () => {
        setShowModal('login');
    }

    const redirectToRegister = () => {
        setShowModal('register');
    }

    const isFormValid = !erroremail && data.email;

    return (
        <>
            

            <div className="login_sec">
                <div className="container">
                    <div className="login_sec_inner">
                        <div className="login_form_social">
                            <div className="login_form">
                                <div className="min_logo">
                                    <Link to="/home">
                                        <img src={Logo} alt="Main Logo" />
                                    </Link>
                                </div>
                                {success ? (
                                    <>
                                    <div className="login_title">
                                    <h1>Link inviato tramite email</h1>
                                    </div>
                                    <p>Ti abbiamo inviato un link per il cambio password. Controlla anche la cartella spam della tua posta elettronica. Grazie!</p>
                                    </>
                                )
                            :
                            (
                                <>
                                <div className="login_title">
                                    <h1>Password dimenticata?</h1>
                                </div>
                                <form onSubmit={onSubmitForm}>
                                    <div className="form-group">
                                        <label htmlFor="email" className="label">
                                            Email
                                        </label>
                                        <input
                                            type="text"
                                            name="email"
                                            className={`form-control ${erroremail ? "error" : ""}`}
                                            placeholder="Email"
                                            value={data.email || ''}
                                            onChange={onChangeData}
                                            onBlur={validateEmail}
                                        />
                                        {erroremail && <div style={mystyle}>{erroremail}</div>}
                                    </div>

                                    <button type="submit" className="btn submit_btn" disabled={!isFormValid}>
                                        Submit
                                    </button>
                                </form>
                                </>
                            )}
                            </div>
                            <div className="login_social">
                                <div className="sign_in_account">
                                 
                                    <a style={{ cursor: "pointer" }} onClick={redirectToLogin}>Torna al login</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return { candidate: state?.candidate };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ requestGetCandidate, userLogout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
