import React, { useState } from "react";
import Header from "../Components/Header";
import Breadcrumbs from "../Components/Breadcrumbs";
import Footer from "../Components/Footer";
import FormBuilder from "../Components/FormBuilder";

const FIELDS = [
  {
    label: "Nome con cui presentarsi",
    name: "display_name",
    type: "text",
  },
  {
    label: "Codice Fiscale",
    name: "fiscal_code",
    type: "text",
  },
  {
    label: "Da che anno svolgi la tua attività?",
    name: "activity_year",
    type: "number",
  },
];

const DisplayName = () => {
  const handleSubmit = () => {};

  return (
    <div className="min-vh-100 d-flex flex-column">
      <Header />
      <Breadcrumbs />
      <div className="container mt-4 h-100">
        <div className="h-100">
          <div className="seekng_job_title mb-4">
            <h2>Alcune informazioni su di te</h2>
          </div>
          <FormBuilder
            fields={FIELDS}
            onSubmit={handleSubmit}
            submitLabel="Avanti"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DisplayName;
