import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import CompanyLogo from "../../../src/images/logo.png"
import { useNavigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import CandidateResumeDetails from "./Candidate-Resume-Details";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import {
    requestTwelveTokenSpent,
    requestCompanyDetails,
    userLogout
} from "../../Redux/actions";


function CandidateCVList(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [resumeIdDetails, setResumeIdDetails] = useState(null)
    const [visibleResume, setVisibleResume] = useState(null);

    const [hoveredIndex, setHoveredIndex] = useState(-1);

    const [resumeData, setResumeData] = useState([])


    useEffect(() => {
        const data = location.state?.resumeListData || [];
        if (data.length > 0) {
            setResumeData(data);
        }

    }, [location.state?.resumeListData]);

    useEffect(() => {
        if (hoveredIndex === resumeData.length - 1) {
            document.body.classList.remove('overflow-hidden');
        } else {
            document.body.classList.add('overflow-hidden');
        }

        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, [hoveredIndex, resumeData.length]);


    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000;
                if (decodedToken.exp > currentTime) {
                    setUser(decodedToken);
                } else {
                    console.warn("Token has expired");
                }
            } catch (error) {
                console.error("Invalid token", error);
            }
        }
    }, []);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            props.requestCompanyDetails({ data: { token } })
        }
    }, [])

    useEffect(() => {
        const getCompanyData = props.company.getCompanyData;
        if (getCompanyData !== undefined) {
            if (getCompanyData.data?.Status === "Success") {
                const CompanyDetails = getCompanyData?.data?.Message || {};
                console.log("CompanyDetails", CompanyDetails);
                setVisibleResume(CompanyDetails?.can_see_resumes);
            } else {
                props.company.getCompanyData = undefined;
            }
        } else {
            props.company.getCompanyData = undefined;
        }

    }, [props.company.getCompanyData])


    const getValue = (value) => (user ? value : "xxxxxxxxxxxxxxxx");

    const redirectToResumeDetails = (resume) => {
        const token = localStorage.getItem('token');
        console.log(visibleResume, "visibleResume")
        if (visibleResume) {
            setResumeIdDetails(resume);
        } else {
            Swal.fire({
                title: "Would you like to spend 12 tokens to view this resume?",
                text: "You need to spend 12 tokens to unlock resume details.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, spend tokens",
                cancelButtonText: "No"
            }).then((result) => {
                if (result.isConfirmed) {
                    props.requestTwelveTokenSpent({ token })
                    const tweleveTokensSpent = props.company.twelveTokenSpent
                    if (tweleveTokensSpent !== undefined) {
                        if (tweleveTokensSpent.data.Status === "Success") {
                            Swal.fire({
                                title: "Success!",
                                text: tweleveTokensSpent?.data?.Message,
                                icon: "success"
                            });
                            // toast.success(tweleveTokensSpent.data.Message, { theme: "colored", autoClose: 1500 })
                        } else {
                            // toast.error(tweleveTokensSpent.data.Message, { theme: "colored", autoClose: 1500 })
                            console.log(tweleveTokensSpent.data.Message)
                            Swal.fire({
                                title: tweleveTokensSpent?.data?.Message,
                                text: "You don't have enough tokens to view this resume.",
                                icon: "error"
                            });
                        }
                    }
                }

                if (result.dismiss) {
                    Swal.fire({
                        title: "Resume Locked",
                        text: "The resume will remain locked until tokens are spent.",
                        icon: "info"
                    });
                }
            });
        }
    };

    const redirectToCVFinder = () => {
        navigate("/company/candidate-finder", { state: { resumeListData: [] } });
    }

    return (
        <>
            <Header />
            <div className="container">
                <div className="new_research_btn mt-4 text-end">
                    <button className="btn btn_default btn_orange_outline" type="button"
                        onClick={redirectToCVFinder}>
                        Nuova ricerca
                    </button>
                </div>
                <div className="row justify-content-center candidate_cv_detail_sec">
                    <div className="col-md-6 col-12">
                        <div className="job_listing_sec">
                            <div className="job_listing_blocks">
                                {resumeData.length > 0 && resumeData.map((resume, index) => (

                                    <div key={index} className={`job_listing_block  ${visibleResume ? '' : 'blur_effect'}  ${hoveredIndex === index ? 'hovered' : ''}`} onMouseMove={() => setHoveredIndex(index)}>
                                        <a className="redirect_a" type="button" onClick={() => redirectToResumeDetails(resume)}>
                                            <div className="job_listing_block_inner">
                                                <div className="job_title">
                                                    <h2>{getValue(resume.e_mail || "")}</h2>
                                                </div>
                                                <div className="logo_detailbtn">
                                                    <div className="company_logo">
                                                        <img src={CompanyLogo} alt="company logo" />
                                                    </div>
                                                    <div className="detail_btn">
                                                        <button
                                                            className="btn btn_default btn_green_outline"
                                                            type="button"
                                                            onClick={() => redirectToResumeDetails(resume)}
                                                        >
                                                            dettagli candidato
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="sector_name">
                                                    <p>Presentazione se inserita, altrimenti vuoto</p>
                                                </div>
                                                <div className="sector_name">
                                                    <p>
                                                        <strong>Settore:&nbsp;&nbsp;</strong> {getValue(resume.sector?.sectorName || "")}
                                                    </p>
                                                </div>
                                                <div className="job_salary_address">
                                                    <div className="job_address">
                                                        <p>
                                                            <strong>Luogo:&nbsp;&nbsp;</strong>
                                                            <span> {getValue(
                                                                resume.address[0]
                                                                    ? `${resume.address[0]?.city ? resume.address[0]?.city : ""}${resume.address[0]?.province
                                                                        ? `, ${resume.address[0]?.province}`
                                                                        : ""
                                                                    }${resume.address[0]?.country
                                                                        ? `, ${resume.address[0]?.country}`
                                                                        : ""
                                                                    }`
                                                                    : ""
                                                            )}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="required_education_skills">
                                                    <div className="required_education">
                                                        <p>
                                                            <strong>Istruzione:&nbsp;&nbsp;</strong>
                                                            {resume.educationalLevel?.levelType ? <strong>Istruzione richiesta::&nbsp;&nbsp;</strong> : ""} {getValue(resume.educationalLevel?.levelType || "")}
                                                        </p>
                                                    </div>
                                                    {/* <div className="required_skills">
                                                        <p>
                                                            <strong>Competenze:&nbsp;&nbsp;</strong>
                                                            <span>Microsoft Office,</span>
                                                            <span>Gestione chiamate</span>
                                                        </p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}

                                {/* <div className="job_listing_block blur_effect">
                                    <a href="/" className="redirect_a">
                                        <div className="job_listing_block_inner">
                                            <div className="job_title">
                                                <h2>Giambruno Cosacchi</h2>
                                            </div>
                                            <div className="logo_detailbtn">
                                                <div className="company_logo">
                                                    <img src={CompanyLogo} alt="company logo" />
                                                </div>
                                                <div className="detail_btn">
                                                    <button
                                                        className="btn btn_default btn_green_outline"
                                                        type="button">
                                                        dettagli candidato
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="sector_name">
                                                <p>Presentazione se inserita, altrimenti vuoto</p>
                                            </div>
                                            <div className="sector_name">
                                                <p>
                                                    <strong>Settore:&nbsp;&nbsp;</strong> ICT
                                                </p>
                                            </div>
                                            <div className="job_salary_address">
                                                <div className="job_address">
                                                    <p>
                                                        <strong>Luogo:&nbsp;&nbsp;</strong>
                                                        <span>Abbadia Lariana, Lecco, Italia</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="required_education_skills">
                                                <div className="required_education">
                                                    <p>
                                                        <strong>Istruzione:&nbsp;&nbsp;</strong>
                                                        Scuola secondaria di secondo grado (superiori)
                                                    </p>
                                                </div>
                                                <div className="required_skills">
                                                    <p>
                                                        <strong>Competenze:&nbsp;&nbsp;</strong>
                                                        <span>Microsoft Office,</span>
                                                        <span>Gestione chiamate</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {resumeIdDetails && (
                        <div className="col-md-6 col-12">
                            <CandidateResumeDetails resumeIdDetails={resumeIdDetails} />
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
}


const mapStateToProps = (state) => {
    return { company: state.company, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            requestTwelveTokenSpent,
            requestCompanyDetails,
            userLogout,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CandidateCVList);