import React, { useEffect, useState } from "react";
import {
  useNavigate,
  Link,
  useSearchParams,
  useLocation,
  useParams,
} from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestLogin, userLogout } from "../../Redux/actions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Logo from "../../images/logo.png";
import PswHide from "../../images/psw_hide.png";
import PswVisible from "../../images/psw_visible.svg";
// import LoginContent from "./LoginContent";
import SocialLogin from "./SocialLogin";
import { useNavigateToRegisterDestination } from "../../utils/routing";

function Login(props) {
  const { setShowModal, handleCloseModal } = props;
  console.log(props);

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({});
  const [errorpassword, seterrorpassword] = useState("");
  const [errorusername, seterrorusername] = useState("");
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const navigateToRegisterDestination = useNavigateToRegisterDestination();

  const mystyle = {
    color: "#D10000",
    padding: "3px 10px",
  };

  function onChangeData(e) {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value.trim(),
    }));
  }

  function onChangeDataPass(event) {
    const { name, value } = event.target;

    const truncatedValue = value.slice(0, 28);
    setData({
      ...data,
      [name]: truncatedValue.trim(),
    });
  }

  function validateUsername() {
    const username = data.username;

    if (!username) {
      seterrorusername("Inserisci il tuo nome utente.");
      return false;
    }
    const trimmedUsername = username.trim();
    if (!trimmedUsername) {
      seterrorusername("Il nome utente non può essere vuoto.");
      return false;
    }
    seterrorusername("");
    return true;
  }

  function validatePassword() {
    let password = data.password;

    if (!password) {
      seterrorpassword("Inserisci la tua password.");
      return false;
    } else if (password.length < 8) {
      seterrorpassword("La password deve contenere almeno 8 caratteri .");
      return false;
    } else {
      seterrorpassword("");
      return true;
    }
  }

  const redirectToPasswordReset = () => {
    props.userLogout();
    setShowModal("password-reset");
  };

  function validateForm() {
    let usname = validateUsername();
    let pass = validatePassword();
    let valid = usname && pass;
    return valid;
  }

  function onSubmitForm(e) {
    e.preventDefault();
    props.userLogout();
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("userType");

    if (validateForm()) {
      props.requestLogin({
        data: {
          username: data.username,
          password: data.password,
        },
      });
      setError(false);
      setData({ username: "", password: "" });
    } else {
      setError(true);
    }
  }

  useEffect(() => {
    if (error) {
      if (errorusername) {
        document.getElementById("username").focus();
      } else if (errorpassword) {
        document.getElementById("password").focus();
      }
      setError(false);
    }
  }, [error]);

  

  //=============================Compnay not include ===========================

  const location = useLocation();

  const params = useParams();

  useEffect(() => {
    console.log(props);
    const loginData = props.candidate.loginData;
    console.log(loginData);

    if (
      localStorage.getItem("logging-for") && !loginData
    )
      return;

    if (loginData) {
      const { Status, Message: updateData } = loginData.data;

      if (Status === "Success") {
        toast.success("Completato", { theme: "colored", autoClose: 8000 });

        localStorage.setItem("token", updateData?.access);
        if (!updateData.flag_company) {
          localStorage.setItem("username", btoa(updateData?.username));
        }

        let userType = "noType";

        switch (true) {
          case updateData.flag_employee:
            userType = "employee";
            break;
          case updateData.flag_agen:
            userType = "agent";
            break;
          case updateData.flag_artisan:
            userType = "artisan";
            break;
          case updateData.flag_franchisor:
            userType = "franchisor";
            break;
          case updateData.flag_private:
            userType = "private";
            break;
          case updateData.flag_professional:
            userType = "professional";
            break;
          case updateData.flag_company:
            userType = "company";
            break;
          default:
            userType = "noType";
        }

        localStorage.setItem("userType", userType);

        switch (userType) {
          case "employee":
            navigate("/candidate/profile", {replace:true});
            break;
          case "noType":
            navigate(location.pathname, {
              replace: false,
              state: location.state,
            });
            // navigate("/candidate/profile", { replace: true });
            break;
          case "company":
            localStorage.setItem("can_see_resumes", btoa(updateData?.can_see_resumes));
            navigate("/company/profile", { replace: true });
            break;

          case "agent":
            navigate("/agent/profile", { replace: true });
            break;

          case "franchisor":
            navigate("/service/profile", { replace: true });
            break;

          default:
            navigate(location.pathname, {
              replace: true,
              state: location.state,
            });
        }

        window.location.reload();

        localStorage.removeItem("logging-for");

        handleCloseModal();
      } else {
        //handleCloseModal();

        //navigateToRegisterDestination();
        props.candidate.loginData = undefined;
        toast.error(loginData.data?.Message || "Login failed", {
          theme: "colored",
          autoClose: 8000,
        });
        setError(true);
      }
    }
  }, [props.candidate.loginData]);

  const isFormValid =
    !errorusername && !errorpassword && data.username && data.password;
  return (
    <>
      <div className="login_sec">
        <div className="container">
          <div className="login_sec_inner">
            <div className="login_form_social">
              <div className="login_form">
                <div className="min_logo">
                  <Link to="/">
                    <img src={Logo} alt="Main Logo" />
                  </Link>
                </div>
                <div className="login_title">
                  <h1>Accedi</h1>
                </div>
                <form onSubmit={onSubmitForm}>
                  {/* <div className="form-group reset_psw_btn">
                    <Link to="/home">Reset password</Link>
                  </div> */}
                  <div className="form-group">
                    <label htmlFor="username" className="label">
                      Email
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorusername ? "error" : ""}`}
                      placeholder={t("login.user_name")}
                      id="username"
                      name="username"
                      value={data.username || ""}
                      onChange={onChangeData}
                      onBlur={validateUsername}
                    />
                    {errorusername && (
                      <div style={mystyle}>{errorusername}</div>
                    )}
                  </div>

                  <div className="form-group">
                    <div className="psw_label">
                      <label htmlFor="password" className="label">
                        Password
                      </label>

                      <div className="psw_show_btn">
                        <a onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? (
                            <>
                              <img src={PswHide} alt="Main Logo" />
                              Nascondi
                            </>
                          ) : (
                            <>
                              <img src={PswVisible} alt="Main Logo" />
                              scopri
                            </>
                          )}
                        </a>
                      </div>
                    </div>
                    <input
                      type={showPassword ? "text" : "password"}
                      className={`form-control ${errorpassword ? "error" : ""}`}
                      id="password"
                      placeholder="Password"
                      name="password"
                      value={data.password || ""}
                      onChange={onChangeDataPass}
                      onBlur={validatePassword}
                    />
                    {errorpassword && (
                      <div style={mystyle}>{errorpassword}</div>
                    )}
                  </div>

                  <button
                    type="submit"
                    className="btn submit_btn"
                    disabled={!isFormValid}
                  >
                    Accedi
                  </button>
                </form>
                <div className="forget_psw">
                  {/* <Link to="/password-reset">Password dimenticata? Clicca qui</Link> */}
                  <a onClick={redirectToPasswordReset}>
                    Password dimenticata? Clicca qui
                  </a>
                </div>
              </div>
              <SocialLogin
                setShowModal={setShowModal}
                handleCloseModal={handleCloseModal}
              />
            </div>
            {/* <LoginContent /> */}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return { candidate: state.candidate, company: state.company };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestLogin, userLogout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
