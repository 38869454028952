import React from 'react';
import Select, { createFilter } from "react-select";

function ContractTypeAgent(props) {
    const {
        errorCreatAd_Four,
        contractTypeRef,
        contractTypesData,
        handleCreateFourType,
        validateContractType,
        filterConfig,
        mystyle,
        t,
        isSearchable,
        isClearable,
        selectedOptionsContractType
    } = props;

    return (
        <>
            <div className="seeking_form_left">
                <div className="row g-3">
                    <div className="col-12">
                        <label className="form-label">Tipo di agente</label>
                        <Select
                            className={`form-control select_dropdown  ${errorCreatAd_Four?.contract_type ? "error" : ""
                                }`}
                            options={contractTypesData}
                            onBlur={validateContractType}
                            value={selectedOptionsContractType}
                            placeholder="Seleziona una tipologia"
                            onChange={handleCreateFourType}
                            ref={contractTypeRef}
                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            isMulti
                            filterOption={createFilter(filterConfig)}
                        />
                        {errorCreatAd_Four?.contract_type && (
                            <div style={mystyle}>
                                {errorCreatAd_Four.contract_type}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="seeking_form_right">
                <div className="row g-3">
                    <div className="col-12">
                        <label>&nbsp;</label>
                        <div className="selected-tag d-flex flex-wrap gap-2">
                            {selectedOptionsContractType.length > 0 &&
                                selectedOptionsContractType.map((item, index) => (
                                    <span key={index}>
                                        {item.label}
                                    </span>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContractTypeAgent