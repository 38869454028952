import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestGetCandidate, userLogout } from "../../Redux/actions";
import { toast } from "react-toastify";
import Logo from "../../images/logo.png";
import { Link } from 'react-router-dom';


function PasswordReset(props) {
    const {
        setShowModal,
    } = props;
    const [data, setData] = useState({});
    const [error, setError] = useState(false);
    const [erroremail, setErrorEmail] = useState("");

    const mystyle = {
        color: "#D10000",
        padding: "3px 10px",
    };

    function validateEmail() {
        const email = data.email;
        if (!email) {
            setErrorEmail("Inserisci la tua e-mail.");
            return false;
        }
        const trimmedEmail = email.trim();
        if (!trimmedEmail) {
            setErrorEmail("L'e-mail non può essere vuota.");
            return false;
        }
        if (!trimmedEmail.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
            setErrorEmail("Inserisci un ID e-mail valido.");
            return false;
        }
        setErrorEmail("");
        return true;
    }

    function generateResetLink(email) {
        // This should be done on the server side in real applications
        return `http://localhost:3000/static/password_reset.html`;
    }

    function onSubmitForm(e) {
        e.preventDefault();
        if (validateEmail()) {
            // const resetLink = generateResetLink(data.email);
            // console.log("resetLink", resetLink);


            // props.requestSendPasswordResetLink({ email: data.email, link: resetLink })
            //     .then((response) => {
            //         setLoading(false);
            //         if (response.status === 200) {
            //             toast.success("Password reset link sent successfully.", {
            //                 theme: "colored",
            //                 autoClose: 2000,
            //             });
            //         } else {
            //             toast.error("Failed to send password reset link. Please try again.", {
            //                 theme: "colored",
            //                 autoClose: 2000,
            //             });
            //         }
            //     })
            //     .catch((error) => {
            //         setLoading(false);
            //         toast.error("An error occurred. Please try again.", {
            //             theme: "colored",
            //             autoClose: 2000,
            //         });
            //     });
        }
    }

    // function onSubmitForm(e) {
    //     props.userLogout();
    //     e.preventDefault();
    //     if (validateEmail()) {
    //         if (data.email) {
    //             props.requestGetCandidate({ data: { username: data.email } });
    //         }
    //         setError(false);
    //     } else {
    //         setError(true);
    //     }
    // }

    // useEffect(() => {
    //     const candidateDetails = props.candidate.GetCandidateData;
    //     if (candidateDetails !== undefined) {
    //         if (candidateDetails.data.Status === "Success") {
    //             const updateData = candidateDetails.data.Message[0] || {};
    //             if (updateData.username) {
    //                 console.log(updateData, "updateData");
    //                 setShowModal('password-confirm');
    //                 toast.success("User can successfully reset the password.", {
    //                     theme: "colored",
    //                     autoClose: 1000
    //                 });
    //                 props.candidate.GetCandidateData = undefined;
    //             }
    //         } else {
    //             toast.error(candidateDetails.data.Message, {
    //                 theme: "colored",
    //                 autoClose: 1000
    //             });
    //         }
    //     } else {
    //     }
    // }, [props.candidate.GetCandidateData]);


    const onChangeData = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    const redirectToLogin = () => {
        setShowModal('login');
    }

    const redirectToRegister = () => {
        setShowModal('register');
    }

    const isFormValid = !erroremail && data.email;

    return (
        <>
            {/* <div className="form-content my-3 p-3">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <div className="card shadow-lg border-0 rounded-lg mt-0 mb-3">
                                <div className="card-header justify-content-center">
                                    <h3 className="font-weight-light my-4 text-center">Password dimenticata?</h3>
                                </div>
                                {erroremail && (
                                    <div className="alert alert-danger alert-dismissible" role="alert">
                                        <div id="form_errors">
                                            <strong>{erroremail}</strong>
                                        </div>
                                        <button
                                            type="button"
                                            className="close"
                                            aria-label="Close"
                                            onClick={() => setErrorEmail("")}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                )}
                                <div className="card-body">
                                    <form onSubmit={onSubmitForm}>
                                        <div className="form-row">
                                            <div className="col-md-10 offset-md-1">
                                                <div className="form-group">
                                                    <label className="small mb-1" htmlFor="email">Email</label>
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        className={`form-control ${erroremail ? "error" : ""}`}
                                                        placeholder="Email"
                                                        onChange={onChangeData}
                                                        onBlur={validateEmail}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-10 offset-md-1">
                                                <div className="form-group mt-0 mb-1">
                                                    <button type="submit" className="col-md-12 btn btn-dark">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="card-footer text-center">
                                    <div className="small">
                                        <a style={{ cursor: "pointer" }} onClick={redirectToRegister}>Crea un nuovo account</a><br /><br />
                                        <a style={{ cursor: "pointer" }} onClick={redirectToLogin}>Back To Login</a><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="login_sec">
                <div className="container">
                    <div className="login_sec_inner">
                        <div className="login_form_social">
                            <div className="login_form">
                                <div className="min_logo">
                                    <Link to="/home">
                                        <img src={Logo} alt="Main Logo" />
                                    </Link>
                                </div>
                                <div className="login_title">
                                    <h1>Password dimenticata?</h1>
                                </div>
                                <form onSubmit={onSubmitForm}>
                                    <div className="form-group">
                                        <label htmlFor="email" className="label">
                                            Email
                                        </label>
                                        <input
                                            type="text"
                                            name="email"
                                            className={`form-control ${erroremail ? "error" : ""}`}
                                            placeholder="Email"
                                            value={data.email || ''}
                                            onChange={onChangeData}
                                            onBlur={validateEmail}
                                        />
                                        {erroremail && <div style={mystyle}>{erroremail}</div>}
                                    </div>

                                    <button type="submit" className="btn submit_btn" disabled={!isFormValid}>
                                        Submit
                                    </button>
                                </form>
                            </div>
                            <div className="login_social">
                                <div className="sign_in_account">
                                    <a style={{ cursor: "pointer" }} onClick={redirectToRegister}>Crea un nuovo account</a><br />
                                    <a style={{ cursor: "pointer" }} onClick={redirectToLogin}>Back To Login</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return { candidate: state?.candidate };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ requestGetCandidate, userLogout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
