import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  requestGetList,
  requestGetResumeDetails,
  requestGetEducationLevelList,
  requestGetSectorList,
  requestUpdateResumeDetails,
  requestDeleteResumeFile,
  requestDeleteLetterFile,
  userLogout,
} from "../../Redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SkillSelect from "./skill/SkillSelect";
import Header from "../Header";
import Footer from "../Footer";
import EducationForm from "./education/EducationForm";
import ExperienceForm from "./experience/ExperinceForm";
import LanguageForm from "./language/LanguageForm";
import ResumeFileToolbar from "./resume-file/ResumeFileToolbar";

const UpdateResume = (props) => {
  const [error, setError] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [selectedOptionsLanguage, setSelectedOptionsLanguage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorskill, seterrorSkill] = useState("");
  const [skillData, setSkillData] = useState([]);
  const [modifyskillData, setModifySkillData] = useState([]);

  //===============educational level ============================

  const [educationLevels, setEducationLevels] = useState([]);
  const [subSubmenuOptions, setSubSubmenuOptions] = useState([]);

  //================sectors level =================================

  const [sectorsLevel, setSectorsLevel] = useState([]);
  const [subSubSectorsLevel, setSubSubSectorsLevel] = useState([]);

  const [openSections, setOpenSections] = useState({
    0: true,
    1: true,
    2: true,
  });

  const { t } = useTranslation();
  const [ipAddress, setIpAddress] = useState("");

  const [education, setEducation] = useState([
    {
      educationalLevel: "",
      educationalSector: "",
      type: "",
      name: "",
      address: {
        country: "Italia",
        province: "",
        city: "",
      },
      yearFrom: "",
      monthFrom: "",
      yearTo: "",
      monthTo: "",
    },
  ]);

  // const [education, setEducation] = useState(() => {
  //     const savedEducation = localStorage.getItem('education');
  //     return savedEducation ? JSON.parse(savedEducation) : [
  //         {
  //             educationalLevel: "",
  //             educationalSector: "",
  //             type: "",
  //             name: "",
  //             address: {
  //                 country: "Italia",
  //                 province: "",
  //                 city: "",
  //             },
  //             yearFrom: "",
  //             monthFrom: "",
  //             yearTo: "",
  //             monthTo: "",
  //         },
  //     ];
  // });

  // const [selectedOptionsEducation, setSelectedOptionsEducation] = useState(() => {
  //     const savedEducationLevel = localStorage.getItem('educationalLevel');
  //     return savedEducationLevel ? JSON.parse(savedEducationLevel) : [
  //         {
  //             submenu: "",
  //             subSubmenu: ""
  //         },
  //     ];
  // });

  const [selectedOptionsEducation, setSelectedOptionsEducation] = useState([
    {
      submenu: "",
      subSubmenu: "",
    },
  ]);

  const [erroreducation, setErroreducation] = useState([
    {
      educationalLevel: "",
      // subeducationalLevel: "",
      educationalSector: "",
      type: "",
      name: "",
      address: {
        country: "",
        province: "",
        city: "",
      },
      yearFrom: "",
      monthFrom: "",
      yearTo: "",
      monthTo: "",
    },
  ]);

  // const [erroreducation, setErroreducation] = useState(() => {
  //     return education.map(() => ({
  //         educationalLevel: "",
  //         // subeducationalLevel: "",
  //         educationalSector: "",
  //         type: "",
  //         name: "",
  //         address: {
  //             country: "",
  //             province: "",
  //             city: "",
  //         },
  //         yearFrom: "",
  //         monthFrom: "",
  //         yearTo: "",
  //         monthTo: "",
  //     }));
  // });

  const [experience, setExperience] = useState([
    {
      sector: "",
      role: "",
      company: "",
      address: {
        country: "Italia",
        province: "",
        city: "",
      },
      yearFrom: "",
      monthFrom: "",
      yearTo: "",
      monthTo: "",
      reccomendationLetter: "",
      description: "",
    },
  ]);

  // const [experience, setExperience] = useState(() => {
  //     const savedExperience = localStorage.getItem('experience');
  //     return savedExperience ? JSON.parse(savedExperience) : [
  //         {
  //             sector: "",
  //             role: "",
  //             company: "",
  //             address: {
  //                 country: "Italia",
  //                 province: "",
  //                 city: "",
  //             },
  //             yearFrom: "",
  //             monthFrom: "",
  //             yearTo: "",
  //             monthTo: "",
  //             reccomendationLetter: "",
  //             description: "",
  //         },
  //     ];
  // });

  // const [selectedOptionsExperience, setSelectedOptionsExperience] = useState(() => {
  //     const savedExperienceSector = localStorage.getItem('experienceSector');
  //     return savedExperienceSector ? JSON.parse(savedExperienceSector) : [
  //         {
  //             submenu: "",
  //             subSubmenu: ""
  //         },
  //     ];
  // });

  const [selectedOptionsExperience, setSelectedOptionsExperience] = useState([
    {
      submenu: "",
      subSubmenu: "",
    },
  ]);

  const [errorexperience, setErrorexperience] = useState([
    {
      sector: "",
      role: "",
      company: "",
      address: {
        country: "",
        province: "",
        city: "",
      },
      yearFrom: "",
      monthFrom: "",
      yearTo: "",
      monthTo: "",
      reccomendationLetter: "",
      description: "",
    },
  ]);

  // const [errorexperience, setErrorexperience] = useState(() => {
  //     return experience.map(() => ({
  //         sector: "",
  //         role: "",
  //         company: "",
  //         address: {
  //             country: "",
  //             province: "",
  //             city: "",
  //         },
  //         yearFrom: "",
  //         monthFrom: "",
  //         yearTo: "",
  //         monthTo: "",
  //         reccomendationLetter: "",
  //         description: "",
  //     }));
  // });

  const [languages, setLanguages] = useState([
    {
      language: "",
      levelListening: "",
      levelReading: "",
      levelWritten: "",
      levelSpoken: "",
    },
  ]);
  // const [languages, setLanguages] = useState(() => {
  //     const savedLanguages = localStorage.getItem('languages');
  //     return savedLanguages ? JSON.parse(savedLanguages) : [
  //         {
  //             language: "",
  //             levelListening: "",
  //             levelReading: "",
  //             levelWritten: "",
  //             levelSpoken: "",
  //         },
  //     ];
  // });

  const [errorlanguages, setErrorlanguages] = useState([
    {
      language: "",
      levelListening: "",
      levelReading: "",
      levelWritten: "",
      levelSpoken: "",
    },
  ]);

  // const [errorlanguages, setErrorlanguages] = useState(() => {
  //     return languages.map(() => ({
  //         language: "",
  //         levelListening: "",
  //         levelReading: "",
  //         levelWritten: "",
  //         levelSpoken: "",
  //     }));
  // });

  // const [selectedSkills, setSelectedSkills] = useState(() => {
  //     const savedSkills = localStorage.getItem('skills');
  //     return savedSkills ? JSON.parse(savedSkills) : [];
  // });

  const [selectedSkills, setSelectedSkills] = useState([]);

  const navigate = useNavigate();

  //=====================================Resume File==========================================

  const [resume, setResume] = useState(null);
  const [errorResume_upload, setErrorResume_upload] = useState("");
  const resumeUploadRef = useRef(null);
  const pdfIconRef = useRef(null);

  //=====================================Resume File==========================================

  const focusRefs = {
    education: useRef([]),
    experience: useRef([]),
    languages: useRef([]),
  };

  const skillRef = useRef(null);

  const deleteIndexRef = useRef(null);

  const isClearable = true;
  const isSearchable = true;
  const matchFromStart = true;

  const mystyle = {
    color: "#D10000",
    padding: "3px 10px",
    width: "100%",
  };

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        setError("Error fetching IP address");
      }
    };
    fetchIpAddress();
  }, [openSections]);

  //=========================== Get Resume Data ================================

  useEffect(() => {
    props.requestGetList();
    props.requestGetEducationLevelList();
    props.requestGetSectorList();
    const username = atob(localStorage.getItem("username"));
    const token = localStorage.getItem("token");
    if (username) {
      props.requestGetResumeDetails({
        data: {
          username: username,
        },
        token: token
      });
    }
  }, []);

  useEffect(() => {
    const { candidate } = props;
    const resumeDetails = candidate.GetResumeData;

    if (resumeDetails?.data?.Status === "Success") {
      const GetResumeDetails =
        resumeDetails?.data?.Message?.resumes[0]?.resume || {};

      setResume(GetResumeDetails?.resume_file);
      console.log(GetResumeDetails);

      const processEducation = () => {
        const education = GetResumeDetails?.education || [];
        setEducation(education);

        const transformedEducationLevel = education.map((edu) => {
          const [submenu, subSubmenu] = edu?.educationalLevel.split(", ") || [];
          return { submenu: submenu || "", subSubmenu: subSubmenu || "" };
        });
        setSelectedOptionsEducation(transformedEducationLevel);

        const eduError = education.length
          ? education.map(() => ({
              educationalLevel: "",
              educationalSector: "",
              type: "",
              name: "",
              address: { country: "", province: "", city: "" },
              yearFrom: "",
              monthFrom: "",
              yearTo: "",
              monthTo: "",
            }))
          : [
              {
                educationalLevel: "",
                educationalSector: "",
                type: "",
                name: "",
                address: { country: "", province: "", city: "" },
                yearFrom: "",
                monthFrom: "",
                yearTo: "",
                monthTo: "",
              },
            ];
        setErroreducation(eduError);
      };

      const processExperience = () => {
        const works = GetResumeDetails?.works || [];
        setExperience(works);

        const transformedSectorLevel = works.map((exp) => {
          const [submenu, subSubmenu] = exp?.sector.split(", ") || [];
          return { submenu: submenu || "", subSubmenu: subSubmenu || "" };
        });
        setSelectedOptionsExperience(transformedSectorLevel);

        const expError = works.length
          ? works.map(() => ({
              sector: "",
              role: "",
              company: "",
              address: { country: "", province: "", city: "" },
              yearFrom: "",
              monthFrom: "",
              yearTo: "",
              monthTo: "",
              reccomendationLetter: "",
              description: "",
            }))
          : [
              {
                sector: "",
                role: "",
                company: "",
                address: { country: "", province: "", city: "" },
                yearFrom: "",
                monthFrom: "",
                yearTo: "",
                monthTo: "",
                reccomendationLetter: "",
                description: "",
              },
            ];
        setErrorexperience(expError);
      };

      const processLanguages = () => {
        const languages = GetResumeDetails?.languages || [];
        setLanguages(languages);

        const lngError = languages.length
          ? languages.map(() => ({
              language: "",
              levelListening: "",
              levelReading: "",
              levelWritten: "",
              levelSpoken: "",
            }))
          : [
              {
                language: "",
                levelListening: "",
                levelReading: "",
                levelWritten: "",
                levelSpoken: "",
              },
            ];
        setErrorlanguages(lngError);
      };

      const processSkills = () => {
        const skills = GetResumeDetails?.skills || [];
        const transformedSkillData = skills.map((skill, index) => ({
          value: index,
          label: skill.skillName,
        }));
        setSelectedSkills(transformedSkillData);

        const skillTypes = skills
          ? skills.map((option) => ({ skillName: option.skillName }))
          : [];
        setModifySkillData(skillTypes);
      };

      processEducation();
      processExperience();
      processLanguages();
      processSkills();

      candidate.GetResumeData = undefined;
    }
  }, [props.candidate.GetResumeData]);

  // useEffect(() => {
  //     const resumeDetails = props.candidate.GetResumeData;
  //     if (resumeDetails?.data?.Status === "Success") {
  //         const GetResumeDetails = resumeDetails?.data?.Message?.resumes[0]?.resume || {};

  //         if (GetResumeDetails?.education) {
  //             setEducation(GetResumeDetails?.education);
  //         }

  //         const transformedEducationLevel = GetResumeDetails?.education?.map((edu) => {
  //             const [submenu, subSubmenu] = edu?.educationalLevel.split(", ");
  //             return {
  //                 submenu: submenu || "",
  //                 subSubmenu: subSubmenu || ""
  //             };
  //         }) || [];

  //         setSelectedOptionsEducation(transformedEducationLevel);

  //         let edu_error = [];
  //         if (!GetResumeDetails?.education?.length) {
  //             edu_error.push({
  //                 educationalLevel: "",
  //                 educationalSector: "",
  //                 type: "",
  //                 name: "",
  //                 address: {
  //                     country: "",
  //                     province: "",
  //                     city: "",
  //                 },
  //                 yearFrom: "",
  //                 monthFrom: "",
  //                 yearTo: "",
  //                 monthTo: "",
  //             });
  //         } else {
  //             GetResumeDetails.education.forEach(() => {
  //                 edu_error.push({
  //                     educationalLevel: "",
  //                     educationalSector: "",
  //                     type: "",
  //                     name: "",
  //                     address: {
  //                         country: "",
  //                         province: "",
  //                         city: "",
  //                     },
  //                     yearFrom: "",
  //                     monthFrom: "",
  //                     yearTo: "",
  //                     monthTo: "",
  //                 });
  //             });
  //         }
  //         setErroreducation(edu_error);

  //         //================ experience ==========================

  //         if (GetResumeDetails?.works) {
  //             setExperience(GetResumeDetails?.works);
  //         }

  //         const transformedSectorLevel = GetResumeDetails?.works?.map((exp) => {
  //             const [submenu, subSubmenu] = exp?.sector.split(", ");
  //             return {
  //                 submenu: submenu || "",
  //                 subSubmenu: subSubmenu || ""
  //             };
  //         }) || [];

  //         setSelectedOptionsExperience(transformedSectorLevel)

  //         let exp_error = [];
  //         if (!GetResumeDetails?.works?.length) {
  //             exp_error.push({
  //                 sector: "",
  //                 role: "",
  //                 company: "",
  //                 address: {
  //                     country: "",
  //                     province: "",
  //                     city: "",
  //                 },
  //                 yearFrom: "",
  //                 monthFrom: "",
  //                 yearTo: "",
  //                 monthTo: "",
  //                 reccomendationLetter: "",
  //                 description: "",
  //             });
  //         } else {
  //             GetResumeDetails.works.forEach(() => {
  //                 exp_error.push({
  //                     sector: "",
  //                     role: "",
  //                     company: "",
  //                     address: {
  //                         country: "",
  //                         province: "",
  //                         city: "",
  //                     },
  //                     yearFrom: "",
  //                     monthFrom: "",
  //                     yearTo: "",
  //                     monthTo: "",
  //                     reccomendationLetter: "",
  //                     description: "",
  //                 });
  //             });
  //         }
  //         setErrorexperience(edu_error);

  //         //================= language =======================

  //         if (GetResumeDetails?.languages) {
  //             setLanguages(GetResumeDetails?.languages);
  //         }

  //         let lng_error = [];
  //         if (!GetResumeDetails?.languages?.length) {
  //             lng_error.push({
  //                 language: "",
  //                 levelListening: "",
  //                 levelReading: "",
  //                 levelWritten: "",
  //                 levelSpoken: "",
  //             });
  //         } else {
  //             GetResumeDetails?.languages.forEach(() => {
  //                 lng_error.push({
  //                     language: "",
  //                     levelListening: "",
  //                     levelReading: "",
  //                     levelWritten: "",
  //                     levelSpoken: "",
  //                 });
  //             });
  //         }
  //         setErrorlanguages(lng_error);

  //         const transformedSkillData = GetResumeDetails?.skills?.map((skill, index) => ({
  //             value: index,
  //             label: skill.skillName
  //         })) || [];

  //         setSelectedSkills(transformedSkillData)
  //         props.candidate.GetResumeData = undefined;
  //     }
  // }, [props.candidate.GetResumeData]);

  useEffect(() => {
    let levelData = props.candidate.getlistData;
    if (levelData !== undefined) {
      if (levelData?.data?.Status === "Success") {
        const processedCountries = levelData.data.Message.countries.map(
          (item) => {
            const name = item.nativeCountryName;
            return name;
          }
        );
        const uniqueCountries = [...new Set(processedCountries)];
        const uniqueCountryData = uniqueCountries.map((name) => ({
          nativeCountryName: name,
        }));

        setCountryData(uniqueCountryData);

        const municipalit = levelData?.data?.Message?.municipalities;
        const uniqueMunicipalities = [];
        const municipalityNames = new Set();

        municipalit.forEach((municipality) => {
          if (!municipalityNames.has(municipality.name)) {
            municipalityNames.add(municipality.name);
            const municipalityObject = {
              name: `${municipality.name}, ${municipality.provincia.name}`,
            };
            uniqueMunicipalities.push(municipalityObject);
          }
        });

        const transformedCityData =
          uniqueMunicipalities?.map((city, index) => ({
            value: index,
            label: city?.name,
          })) || [];

        setMunicipalities(transformedCityData);

        const transformedSkillData =
          levelData?.data?.Message?.skills?.map((skill, index) => ({
            value: index,
            label: skill.skillName,
          })) || [];

        setSkillData(transformedSkillData);
      }
    }
  }, [props.candidate.getlistData]);

  const sortedCountryData = [...countryData].sort((a, b) => {
    if (a.nativeCountryName === "Italia") return -1;
    if (b.nativeCountryName === "Italia") return 1;
    return 0;
  });

  //=================================educational level ==================================
  useEffect(() => {
    const educationlevelData = props.candidate.EducationlevelData;
    if (educationlevelData?.data?.Status === "Success") {
      const uniqueLevels = {};

      educationlevelData.data.Message.forEach((level) => {
        if (!uniqueLevels[level.levelType]) {
          uniqueLevels[level.levelType] = {
            value: level.id,
            label: level.levelType,
          };
        }
      });
      const transformedEducationlevelData = Object.values(uniqueLevels);
      setEducationLevels(transformedEducationlevelData);

      const filteredSubMenuOptions = educationlevelData.data.Message.filter(
        (level) => level.specialisation
      ).map((level) => ({
        levelType: level.levelType,
        specialisation: level.specialisation,
      }));

      setSubSubmenuOptions(filteredSubMenuOptions);
    }
  }, [props.candidate.EducationlevelData]);

  //====================== sector level ===============================================

  useEffect(() => {
    const sectorsLevelData = props.candidate.SectorData;
    if (sectorsLevelData?.data?.Status === "Success") {
      const uniqueSectors = {};

      sectorsLevelData.data.Message.forEach((sector) => {
        if (!uniqueSectors[sector.sectorName]) {
          uniqueSectors[sector.sectorName] = {
            value: sector.id,
            label: sector.sectorName,
          };
        }
      });
      const transformedSectorlevelData = Object.values(uniqueSectors);
      setSectorsLevel(transformedSectorlevelData);

      const filteredSubMenuOptions = sectorsLevelData.data.Message.filter(
        (level) => level.subSector
      ).map((level) => ({
        sectorName: level.sectorName,
        subSector: level.subSector,
      }));

      setSubSubSectorsLevel(filteredSubMenuOptions);
    }
  }, [props.candidate.SectorData]);

  const filterConfig = {
    matchFrom: matchFromStart ? "start" : "any",
  };

  useEffect(() => {
    if (
      education?.length === 0 ||
      experience?.length === 0 ||
      languages?.length === 0
    ) {
      if (education.length === 0) {
        setEducation([
          ...education,
          {
            educationalLevel: "",
            // educationalSector: "",
            type: "",
            name: "",
            address: {
              country: "Italia",
              province: "",
              city: "",
            },
            // grade: "",
            yearFrom: "",
            monthFrom: "",
            yearTo: "",
            monthTo: "",
          },
        ]);

        setErroreducation([
          ...erroreducation,
          {
            educationalLevel: "",
            // educationalSector: "",
            type: "",
            name: "",
            address: {
              country: "",
              province: "",
              city: "",
            },
            // grade: "",
            yearFrom: "",
            monthFrom: "",
            yearTo: "",
            monthTo: "",
          },
        ]);
      }
      if (experience?.length === 0) {
        setExperience([
          ...experience,
          {
            sector: "",
            role: "",
            company: "",
            address: {
              country: "Italia",
              province: "",
              city: "",
            },
            yearFrom: "",
            monthFrom: "",
            yearTo: "",
            monthTo: "",
            reccomendationLetter: "",
            description: "",
          },
        ]);
        setErrorexperience([
          ...errorexperience,
          {
            sector: "",
            role: "",
            company: "",
            address: {
              country: "",
              province: "",
              city: "",
            },
            yearFrom: "",
            monthFrom: "",
            yearTo: "",
            monthTo: "",
            reccomendationLetter: "",
            description: "",
          },
        ]);
      }
      if (languages?.length === 0) {
        setLanguages([
          ...languages,
          {
            language: "",
            levelListening: "",
            levelReading: "",
            levelWritten: "",
            levelSpoken: "",
          },
        ]);
        setErrorlanguages([
          ...errorlanguages,
          {
            language: "",
            levelListening: "",
            levelReading: "",
            levelWritten: "",
            levelSpoken: "",
          },
        ]);
      }
    }
  }, [education, experience, languages]);

  // ========================== education fuction =======================================

  const handleInputEducation = (e, index) => {
    const { name, value } = e.target;
    const newList = [...education];

    if (name.includes("address")) {
      const [, addressField] = name.split(".");
      newList[index].address[addressField] = value;

      if (addressField === "country") {
        if (value === "Italia") {
          newList[index].address.city = "";
          newList[index].address.province = "";
        } else {
          newList[index].address.city = "";
          newList[index].address.province = "";
        }
      }
    } else {
      if (name === "educationalLevel") {
        if (value) {
          if (value === "Università" || value === "Master") {
            newList[index].type = "academy";
          } else {
            newList[index].type = "school";
          }
        } else {
          newList[index].type = "";
        }
      }

      newList[index][name] = value;
    }

    setEducation(newList);
    // setSelectedOptionsEducation(selectedOptionsCopy);
  };

  //=============== single educational level inserted and  select educational level =========================

  const handleChangeEducationLevel = (selectedOption, i) => {
    if (selectedOption !== null) {
      setSelectedOptionsEducation((prevOptions) => ({
        ...prevOptions,
        [i]: {
          ...prevOptions[i],
          submenu: selectedOption?.label || "",
          subSubmenu: "",
        },
      }));

      if (education) {
        const updatedEducation = education.map((edu, index) =>
          index === i
            ? { ...edu, educationalLevel: selectedOption?.label }
            : edu
        );
        setEducation(updatedEducation);
      }
      const list = [...erroreducation];
      list[i].educationalLevel = "";
      setErroreducation(list);
      // validateSubEducationLevel(i)
    } else {
      setSelectedOptionsEducation((prevOptions) => ({
        ...prevOptions,
        [i]: {
          ...prevOptions[i],
          submenu: "",
          subSubmenu: "",
        },
      }));

      if (education) {
        const updatedEducation = education.map((edu, index) =>
          index === i ? { ...edu, educationalLevel: "" } : edu
        );
        setEducation(updatedEducation);
      }
      validateEducationLevel(i);
    }
  };

  const handleSubsubmenuChange = (e, index) => {
    const selectedValue = e.target.value;

    setSelectedOptionsEducation((prevOptions) => ({
      ...prevOptions,
      [index]: {
        ...prevOptions[index],
        subSubmenu: selectedValue,
      },
    }));

    setEducation((prevEducation) => {
      const updatedEducation = [...prevEducation];
      updatedEducation[index] = {
        ...updatedEducation[index],
        educationalLevel: `${
          selectedOptionsEducation[index]?.submenu || ""
        }, ${selectedValue}`,
      };
      return updatedEducation;
    });
    // validateSubEducationLevel(index)
  };

  const handleChangeEducationCity = async (e, index) => {
    setIsLoading(true);
    if (e !== null) {
      setTimeout(() => {
        const list = [...education];
        const [city, province] = e?.label.split(", ");
        if (list[index] && list[index].address) {
          list[index].address.city = city;
          list[index].address.province = province;
        }
        setEducation(list);
        setIsLoading(false);
        validateEducationCommon(index);
      }, 1000);
    } else {
      validateEducationCommon(index);
      setIsLoading(false);
    }
  };

  const handleRemoveEducation = (index) => {
    const updatedEducation = [...education];
    updatedEducation.splice(index, 1);
    setEducation(updatedEducation);

    const updatedErrorEducation = [...erroreducation];
    updatedErrorEducation.splice(index, 1);
    setErroreducation(updatedErrorEducation);

    const updatedSelectedOptions = [...selectedOptionsEducation];
    updatedSelectedOptions.splice(index, 1);
    setSelectedOptionsEducation(updatedSelectedOptions);

    if (updatedEducation.length === 0) {
      setOpenSections((prev) => ({
        ...prev,
        0: false,
      }));
    }
  };

  const handleAddEducation = () => {
    let length = education.length;
    if (length === 0) {
      setEducation([
        ...education,
        {
          educationalLevel: "",
          // educationalSector: "",
          type: "",
          name: "",
          address: {
            country: "Italia",
            province: "",
            city: "",
          },
          // grade: "",
          yearFrom: "",
          monthFrom: "",
          yearTo: "",
          monthTo: "",
        },
      ]);

      setErroreducation([
        ...erroreducation,
        {
          educationalLevel: "",
          // educationalSector: "",
          type: "",
          name: "",
          address: {
            country: "",
            province: "",
            city: "",
          },
          // grade: "",
          yearFrom: "",
          monthFrom: "",
          yearTo: "",
          monthTo: "",
        },
      ]);
    } else if (
      education[length - 1].educationalLevel === "" ||
      // education[length - 1].educationalSector === "" ||
      // education[length - 1].type === "" ||
      education[length - 1].name === "" ||
      education[length - 1].address.country === "" ||
      education[length - 1].address.province === "" ||
      education[length - 1].address.city === "" ||
      // education[length - 1].grade === "" ||
      education[length - 1].yearFrom === "" ||
      education[length - 1].yearTo === ""
    ) {
      Swal.fire(
        "Errore!",
        "Dopo aver completato quello precedente, puoi aggiungerne uno nuovo.",
        "error"
      );
      return;
    } else {
      setEducation([
        ...education,
        {
          educationalLevel: "",
          // educationalSector: "",
          type: "",
          name: "",
          address: {
            country: "Italia",
            province: "",
            city: "",
          },
          // grade: "",
          yearFrom: "",
          monthFrom: "",
          yearTo: "",
          monthTo: "",
        },
      ]);
      setErroreducation([
        ...erroreducation,
        {
          educationalLevel: "",
          // educationalSector: "",
          type: "",
          name: "",
          address: {
            country: "",
            province: "",
            city: "",
          },
          // grade: "",
          yearFrom: "",
          monthFrom: "",
          yearTo: "",
          monthTo: "",
        },
      ]);
    }

    setSelectedOptionsEducation((prevOptions) => ({
      ...prevOptions,
      [length]: {
        submenu: "",
        subSubmenu: "",
      },
    }));
  };

  // ============================ exprience fuction ========================

  const handleInputExperience = (e, index) => {
    const { name, value } = e.target;
    const list = [...experience];
    if (name.includes("address")) {
      const [_, addressField] = name.split(".");
      list[index].address[addressField] = value;

      if (addressField === "country") {
        if (value === "Italia") {
          list[index].address.city = "";
          list[index].address.province = "";
        } else {
          list[index].address.city = "";
          list[index].address.province = "";
        }
      }
    } else {
      list[index][name] = value;
    }
    setExperience(list);
  };

  //=============== single educational level inserted and  select educational level =========================

  const handleChangeSectorLevel = (selectedOption, i) => {
    if (selectedOption !== null) {
      setSelectedOptionsExperience((prevOptions) => ({
        ...prevOptions,
        [i]: {
          ...prevOptions[i],
          submenu: selectedOption?.label || "",
          subSubmenu: "",
        },
      }));

      if (education) {
        const updatedExperience = experience.map((edu, index) =>
          index === i ? { ...edu, sector: selectedOption?.label } : edu
        );
        setExperience(updatedExperience);
      }
      const list = [...errorexperience];
      list[i].sector = "";
      setErrorexperience(list);
    } else {
      setSelectedOptionsExperience((prevOptions) => ({
        ...prevOptions,
        [i]: {
          ...prevOptions[i],
          submenu: "",
          subSubmenu: "",
        },
      }));

      if (education) {
        const updatedExperience = experience.map((exp, index) =>
          index === i ? { ...exp, sector: "" } : exp
        );
        setExperience(updatedExperience);
      }
      validateExperienceSector(i);
    }
  };

  const handleSubMenuExpChange = (e, index) => {
    const selectedValue = e.target.value;

    setSelectedOptionsExperience((prevOptions) => ({
      ...prevOptions,
      [index]: {
        ...prevOptions[index],
        subSubmenu: selectedValue,
      },
    }));

    setExperience((prevExperience) => {
      const updatedExperience = [...prevExperience];
      updatedExperience[index] = {
        ...updatedExperience[index],
        sector: `${
          selectedOptionsExperience[index]?.submenu || ""
        }, ${selectedValue}`,
      };
      return updatedExperience;
    });
  };

  // const handleRemoveExperience = (index) => {
  //     const list = [...experience];
  //     list.splice(index, 1);
  //     setExperience(list);
  //     const errorlist = [...errorexperience];
  //     errorlist.splice(index, 1);
  //     setErrorexperience(errorlist);
  // };

  const handleRemoveExperience = (index) => {
    const updatedExperience = [...experience];
    updatedExperience.splice(index, 1);
    setExperience(updatedExperience);

    const updatedErrorExperience = [...errorexperience];
    updatedErrorExperience.splice(index, 1);
    setErrorexperience(updatedErrorExperience);

    const updatedSelectedOptions = [...selectedOptionsExperience];
    updatedSelectedOptions.splice(index, 1);
    setSelectedOptionsExperience(updatedSelectedOptions);

    if (updatedExperience.length === 0) {
      setOpenSections((prev) => ({
        ...prev,
        1: false,
      }));
    }
  };

  const handleAddExperience = () => {
    let length = experience.length;

    if (length === 0) {
      setExperience([
        ...experience,
        {
          sector: "",
          role: "",
          company: "",
          address: {
            country: "Italia",
            province: "",
            city: "",
          },
          yearFrom: "",
          monthFrom: "",
          yearTo: "",
          monthTo: "",
          reccomendationLetter: "",
          description: "",
        },
      ]);
      setErrorexperience([
        ...errorexperience,
        {
          sector: "",
          role: "",
          company: "",
          address: {
            country: "",
            province: "",
            city: "",
          },
          yearFrom: "",
          monthFrom: "",
          yearTo: "",
          monthTo: "",
          reccomendationLetter: "",
          description: "",
        },
      ]);
    } else if (
      experience[length - 1].sector === "" ||
      experience[length - 1].role === "" ||
      experience[length - 1].company === "" ||
      experience[length - 1].address.country === "" ||
      experience[length - 1].address.province === "" ||
      experience[length - 1].address.city === "" ||
      experience[length - 1].yearFrom === "" ||
      experience[length - 1].yearTo === ""
      // experience[length - 1].description === ""
    ) {
      Swal.fire(
        "Errore!",
        "Dopo aver completato quello precedente, puoi aggiungerne uno nuovo.",
        "error"
      );
      return;
    } else {
      setExperience([
        ...experience,
        {
          sector: "",
          role: "",
          company: "",
          address: {
            country: "Italia",
            province: "",
            city: "",
          },
          yearFrom: "",
          monthFrom: "",
          yearTo: "",
          monthTo: "",
          reccomendationLetter: "",
          description: "",
        },
      ]);
      setErrorexperience([
        ...errorexperience,
        {
          sector: "",
          role: "",
          company: "",
          address: {
            country: "",
            province: "",
            city: "",
          },
          yearFrom: "",
          monthFrom: "",
          yearTo: "",
          monthTo: "",
          reccomendationLetter: "",
          description: "",
        },
      ]);
    }

    setSelectedOptionsExperience((prevOptions) => ({
      ...prevOptions,
      [length]: {
        submenu: "",
        subSubmenu: "",
      },
    }));
  };

  const handleChangeExprienceCity = async (e, index) => {
    setIsLoading(true);
    if (e !== null) {
      setTimeout(() => {
        const list = [...experience];
        const [city, province] = e?.label.split(", ");
        if (list[index] && list[index].address) {
          list[index].address.city = city;
          list[index].address.province = province;
        }
        setExperience(list);
        setIsLoading(false);
        validateExperienceCommon(index);
      }, 1000);
    } else {
      setIsLoading(false);
    }
  };

  const handleInputExperience1 = (e, index, file) => {
    const { name } = e.target;
    const list = [...experience];

    if (file) {
      list[index][name] = file;
      console.log("File selected and updated");
    } else {
      list[index][name] = null;
      console.log("File canceled or no file selected");
    }

    setExperience(list);
  };

  //================== delete Experience letter ======================

  const deleteExperienceLetter = (e, isDelete = false) => {
    const fileName = e.target.fileName;
    if (isDelete) {
      const token = localStorage.getItem("token");
      if (token) {
        props.requestDeleteLetterFile({
          token: token,
          filename: fileName,
        });
      }
    }
  };

  useEffect(() => {
    const letterFileDelete = props.candidate.DeleteLetter;

    if (letterFileDelete) {
      const message = letterFileDelete?.data?.messages?.[0]?.message;

      if (message) {
        toast.error(message, { theme: "colored", autoClose: 1000 });
      }

      const { Status, Message } = letterFileDelete.data;

      if (Status === "Success") {
        toast.success(Status, { theme: "colored", autoClose: 1000 });
        const index = deleteIndexRef.current;
        const updatedExperience = experience.map((item, idx) => {
          if (idx === index) {
            return { ...item, reccomendationLetter: null };
          }
          return item;
        });
        setExperience(updatedExperience);
        setError(false);
      } else {
        toast.error(Message, { theme: "colored", autoClose: 1000 });
        setError(true);
      }
      props.candidate.DeleteLetter = undefined;
    }
  }, [props.candidate.DeleteLetter]);

  // ============================ language fuction ========================

  const handleInputLanguage = (e, index) => {
    const { name, value } = e.target;
    const list = [...languages];
    list[index][name] = value;
    setLanguages(list);
  };

  const handleChangeLanguage = (selectedOption, i) => {
    const updatedSelectedOptions = [...selectedOptionsLanguage];
    updatedSelectedOptions[i] = selectedOption || null;

    setSelectedOptionsLanguage(updatedSelectedOptions);

    if (languages) {
      const updatedLanguages = languages.map((lang, index) =>
        index === i ? { ...lang, language: selectedOption?.label } : lang
      );
      setLanguages(updatedLanguages);
    }
  };

  // const handleRemoveLanguage = (index) => {
  //     const list = [...languages];
  //     list.splice(index, 1);
  //     setLanguages(list);
  //     const errorlist = [...errorlanguages];
  //     errorlist.splice(index, 1);
  //     setErrorlanguages(errorlist);
  //     if (list.length === 0) {
  //         setOpenSections({ 2: false });
  //     }
  // };

  const handleRemoveLanguage = (index) => {
    const updatedLanguages = [...languages];
    updatedLanguages.splice(index, 1);
    setLanguages(updatedLanguages);

    const updatedErrors = [...errorlanguages];
    updatedErrors.splice(index, 1);
    setErrorlanguages(updatedErrors);

    if (updatedLanguages.length === 0) {
      setOpenSections((prev) => ({
        ...prev,
        2: false,
      }));
    }
  };

  const handleAddLanguages = () => {
    let length = languages.length;
    if (length === 0) {
      setLanguages([
        ...languages,
        {
          language: "",
          levelListening: "",
          levelReading: "",
          levelWritten: "",
          levelSpoken: "",
        },
      ]);
      setErrorlanguages([
        ...errorlanguages,
        {
          language: "",
          levelListening: "",
          levelReading: "",
          levelWritten: "",
          levelSpoken: "",
        },
      ]);
    } else if (
      languages[length - 1].language === "" ||
      languages[length - 1].levelListening === "" ||
      languages[length - 1].levelReading === "" ||
      languages[length - 1].levelWritten === "" ||
      languages[length - 1].levelSpoken === ""
    ) {
      Swal.fire(
        "Errore!",
        "Dopo aver completato quello precedente, puoi aggiungerne uno nuovo.",
        "error"
      );
      return;
    } else {
      setLanguages([
        ...languages,
        {
          language: "",
          levelListening: "",
          levelReading: "",
          levelWritten: "",
          levelSpoken: "",
        },
      ]);
      setErrorlanguages([
        ...errorlanguages,
        {
          language: "",
          levelListening: "",
          levelReading: "",
          levelWritten: "",
          levelSpoken: "",
        },
      ]);
    }
  };

  //============== skill function ====================

  const handleChangeSkill = (selectedSkills) => {
    setSelectedSkills(selectedSkills);
    const skillTypes = selectedSkills
      ? selectedSkills.map((option) => ({ skillName: option.label }))
      : [];
    setModifySkillData(skillTypes);
    seterrorSkill("");
  };

  const handleCreateSkillOption = (inputValue) => {
    const newIndex = skillData?.length;
    const newOption = { value: newIndex, label: inputValue };
    setSkillData((prevData) => {
      const updatedData = [...prevData, newOption];
      return updatedData;
    });
    setSelectedSkills((prevData) => {
      const updatedData = [...prevData, newOption];
      const skillTypes = updatedData?.map((option) => ({
        skillName: option.label,
      }));
      setModifySkillData(skillTypes);
      return updatedData;
    });
    seterrorSkill("");
  };

  //======================== education validation ======================================

  function validateEducationLevel(i) {
    let formIsValid = false;
    const trimmedEducationalLevel = education[i].educationalLevel;

    if (!trimmedEducationalLevel) {
      formIsValid = false;
      const list = [...erroreducation];
      list[i].educationalLevel =
        "Seleziona il nome della tua Livello istruzione.";
      setErroreducation(list);
    } else {
      formIsValid = true;
      const list = [...erroreducation];
      list[i].educationalLevel = "";
      setErroreducation(list);
    }
    return formIsValid;
  }

  //======================== sub  education validation ======================================

  // function validateSubEducationLevel(i) {
  //     let formIsValid = true;

  //     if (focusRefs.education.current[i]) {
  //         const subEducation = focusRefs.education.current[i].subeducationalLevel?.value;

  //         const list = [...erroreducation];

  //         if (!subEducation) {
  //             formIsValid = false;
  //             list[i].subeducationalLevel = "Seleziona il tuo Indirizzo / Specializzazione.";
  //         } else {
  //             list[i].subeducationalLevel = "";
  //         }
  //         setErroreducation(list);
  //     }

  //     return formIsValid;
  // }

  // function validateEducationStudysector(i) {
  //     let formIsValid = true;
  //     const trimmedStudysector = education[i]?.educationalSector;

  //     if (!trimmedStudysector || !/^[A-Za-z.\s]+$/.test(trimmedStudysector)) {
  //         formIsValid = false;
  //         const list = [...erroreducation];
  //         list[i].educationalSector = "Inserisci il nome del tuo settore di studio.";
  //         setErroreducation(list);
  //     } else {
  //         const list = [...erroreducation];
  //         list[i].educationalSector = "";
  //         setErroreducation(list);
  //     }

  //     return formIsValid;
  // }

  function validateEducationInstituteName(i) {
    let formIsValid = true;
    const trimmedInstituteName = education[i]?.name;

    if (!trimmedInstituteName || !/^[A-Za-z.\s]+$/.test(trimmedInstituteName)) {
      formIsValid = false;
      const list = [...erroreducation];
      list[i].name =
        "Inserisci il nome del tuo istituto (solo caratteri alfabetici e .).";
      setErroreducation(list);
    } else {
      const list = [...erroreducation];
      list[i].name = "";
      setErroreducation(list);
    }

    return formIsValid;
  }

  function validateEducationCountry(i) {
    let formIsValid = false;
    const trimmedCountryEducation = education[i].address.country;

    if (!trimmedCountryEducation) {
      formIsValid = false;
      const list = [...erroreducation];
      list[i].address.country = "Seleziona la tua country.";
      setErroreducation(list);
    } else {
      formIsValid = true;
      const list = [...erroreducation];
      list[i].address.country = "";
      setErroreducation(list);
    }
    return formIsValid;
  }

  function validateEducationProvince(i) {
    let formIsValid = false;
    const trimmedProvinceEducation = education[i]?.address?.province;

    if (!trimmedProvinceEducation) {
      formIsValid = false;
      const list = [...erroreducation];
      list[i].address.province = "Inserisci la tua provincia.";
      setErroreducation(list);
    } else {
      formIsValid = true;
      const list = [...erroreducation];
      list[i].address.province = "";
      setErroreducation(list);
    }
    return formIsValid;
  }

  function validateEducationCommon(i) {
    let formIsValid = true;
    if (education[i]?.address?.country === "Italia") {
      if (!education[i]?.address?.city) {
        formIsValid = false;
        const list = [...erroreducation];
        list[i].address.city = "Seleziona il tuo Comune.";
        setErroreducation(list);
      }
    } else {
      if (!education[i]?.address?.city) {
        formIsValid = false;
        const list = [...erroreducation];
        list[i].address.city = "Inserisci il nome del tuo Comune.";
        setErroreducation(list);
      }
    }
    if (formIsValid) {
      const list = [...erroreducation];
      list[i].address.city = "";
      setErroreducation(list);
    }

    return formIsValid;
  }

  //============================= exprience  validation ====================================

  function validateExperienceSector(i) {
    let formIsValid = false;
    const trimmedSectorExprience = experience[i]?.sector;
    if (!trimmedSectorExprience) {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].sector = "Seleziona il nome facoltativo settore";
      setErrorexperience(list);
    } else {
      formIsValid = true;
      const list = [...errorexperience];
      list[i].sector = "";
      setErrorexperience(list);
    }
    return formIsValid;
  }

  function validateExperienceRole(i) {
    let formIsValid = true;
    const trimmedRole = (experience[i]?.role || "").trim();
    if (!trimmedRole || !/^[A-Za-z\s]+$/.test(trimmedRole)) {
      formIsValid = false;
      const updatedErrorExperience = [...errorexperience];
      updatedErrorExperience[i].role =
        "Inserisci il tuo ruolo (solo caratteri alfabetici).";
      setErrorexperience(updatedErrorExperience);
    } else {
      const updatedErrorExperience = [...errorexperience];
      updatedErrorExperience[i].role = "";
      setErrorexperience(updatedErrorExperience);
    }

    return formIsValid;
  }

  function validateExperienceCompany(i) {
    let formIsValid = true;
    const trimmedCompany = (experience[i]?.company || "").trim();

    if (!trimmedCompany) {
      formIsValid = false;
      const updatedErrorExperience = [...errorexperience];
      updatedErrorExperience[i].company = "Inserisci il nome dell'azienda.";
      setErrorexperience(updatedErrorExperience);
    } else {
      const updatedErrorExperience = [...errorexperience];
      updatedErrorExperience[i].company = "";
      setErrorexperience(updatedErrorExperience);
    }
    return formIsValid;
  }

  // function validateExperienceDescription(i) {
  //     let formIsValid = true;
  //     const trimmedDescription = (experience[i]?.description || "").trim();

  //     if (!trimmedDescription) {
  //         formIsValid = false;
  //         const updatedErrorExperience = [...errorexperience];
  //         updatedErrorExperience[i].description = "Inserisci la descrizione del lavoro.";
  //         setErrorexperience(updatedErrorExperience);
  //     } else {
  //         const updatedErrorExperience = [...errorexperience];
  //         updatedErrorExperience[i].description = "";
  //         setErrorexperience(updatedErrorExperience);
  //     }
  //     return formIsValid;
  // }

  function validateExperienceCountry(i) {
    let formIsValid = false;
    const trimmedCountryExprience = experience[i].address.country;

    if (!trimmedCountryExprience) {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].address.country = "Seleziona la tua country.";
      setErrorexperience(list);
    } else {
      formIsValid = true;
      const list = [...errorexperience];
      list[i].address.country = "";
      setErrorexperience(list);
    }
    return formIsValid;
  }

  function validateExperienceProvince(i) {
    let formIsValid = false;
    const trimmedProvinceExprience = experience[i]?.address?.province;

    if (!trimmedProvinceExprience) {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].address.province = "Inserisci la tua provincia.";
      setErrorexperience(list);
    } else {
      formIsValid = true;
      const list = [...errorexperience];
      list[i].address.province = "";
      setErrorexperience(list);
    }
    return formIsValid;
  }

  function validateExperienceCommon(i) {
    let formIsValid = true;
    if (experience[i]?.address?.country === "Italia") {
      if (!experience[i]?.address?.city) {
        formIsValid = false;
        const list = [...errorexperience];
        list[i].address.city = "Seleziona il tuo Comune.";
        setErrorexperience(list);
      }
    } else {
      if (!experience[i]?.address?.city) {
        formIsValid = false;
        const list = [...errorexperience];
        list[i].address.city = "Inserisci il nome del tuo Comune.";
        setErrorexperience(list);
      }
    }
    if (formIsValid) {
      const list = [...errorexperience];
      list[i].address.city = "";
      setErrorexperience(list);
    }

    return formIsValid;
  }

  function validateExperienceYearFrom(i) {
    let formIsValid = true;

    if (!experience[i].yearFrom) {
      formIsValid = false;
      const updatedErrors = [...errorexperience];
      updatedErrors[i].yearFrom = "Seleziona l'anno di inizio.";
      setErrorexperience(updatedErrors);
    } else {
      const updatedErrors = [...errorexperience];
      updatedErrors[i].yearFrom = "";
      setErrorexperience(updatedErrors);
    }

    return formIsValid;
  }

  function validateExperienceYearTo(i) {
    let formIsValid = true;
    if (!experience[i].yearTo) {
      formIsValid = false;
      const updatedErrors = [...errorexperience];
      updatedErrors[i].yearTo = "Seleziona l'anno di fine.";
      setErrorexperience(updatedErrors);
    } else if (experience[i].yearTo < experience[i].yearFrom) {
      formIsValid = false;
      const updatedErrors = [...errorexperience];
      updatedErrors[i].yearTo =
        "L'anno di fine deve essere maggiore dell'anno di inizio.";
      setErrorexperience(updatedErrors);
    } else {
      const updatedErrors = [...errorexperience];
      updatedErrors[i].yearTo = "";
      setErrorexperience(updatedErrors);
    }

    return formIsValid;
  }

  // const validateExperienceLetter = (i) => {
  //     const fileInput = focusRefs?.experience?.current[i]?.reccomendationLetterexp?.files;

  //     if (fileInput && fileInput.length > 0) {
  //         const file1 = fileInput[0];
  //         const allowedFormats = ['.pdf'];
  //         const fileExtension = file1.name.substring(file1.name.lastIndexOf('.')).toLowerCase();

  //         if (!allowedFormats.includes(fileExtension)) {
  //             const list = [...errorexperience];
  //             list[i] = { ...list[i], reccomendationLetter: "Formato file non valido. Si prega di caricare un file PDF." };
  //             setErrorexperience(list);
  //             focusRefs.experience.current[i].reccomendationLetterexp.value = "";
  //             return false;
  //         }
  //     }

  //     const list = [...errorexperience];
  //     list[i] = { ...list[i], reccomendationLetter: "" };
  //     setErrorexperience(list);
  //     return true;
  // };

  const validateExperienceLetter = (i) => {
    const fileInput =
      focusRefs?.experience?.current[i]?.reccomendationLetterexp?.files;

    if (fileInput && fileInput.length > 0) {
      const file1 = fileInput[0];
      const allowedFormats = [".pdf"];
      const fileExtension = file1.name
        .substring(file1.name.lastIndexOf("."))
        .toLowerCase();

      if (!allowedFormats.includes(fileExtension)) {
        const list = [...errorexperience];
        list[i] = {
          ...list[i],
          reccomendationLetter:
            "Formato file non valido. Si prega di caricare un file PDF.",
        };
        setErrorexperience(list);
        return false;
      }
    }

    const list = [...errorexperience];
    list[i] = { ...list[i], reccomendationLetter: "" };
    setErrorexperience(list);
    return true;
  };

  //==================== languages validation =======================================

  function validateEducationYearFrom(i) {
    let formIsValid = true;
    const today = new Date().getFullYear();

    if (!education[i].yearFrom) {
      formIsValid = false;
      const updatedErrors = [...erroreducation];
      updatedErrors[i].yearFrom = "Seleziona l'anno di inizio.";
      setErroreducation(updatedErrors);
    } else if (education[i].yearFrom >= today) {
      formIsValid = false;
      const updatedErrors = [...erroreducation];
      updatedErrors[i].yearFrom =
        "L'anno di inizio dovrebbe essere nel passato.";
      setErroreducation(updatedErrors);
    } else {
      const updatedErrors = [...erroreducation];
      updatedErrors[i].yearFrom = "";
      setErroreducation(updatedErrors);
    }

    return formIsValid;
  }

  function validateEducationYearTo(i) {
    let formIsValid = true;

    if (!education[i].yearTo) {
      formIsValid = false;
      const updatedErrors = [...erroreducation];
      updatedErrors[i].yearTo = "Seleziona l'anno di fine.";
      setErroreducation(updatedErrors);
    } else if (education[i].yearTo < education[i].yearFrom) {
      formIsValid = false;
      const updatedErrors = [...erroreducation];
      updatedErrors[i].yearTo =
        "L'anno finale deve essere maggiore dell'anno iniziale.";
      setErroreducation(updatedErrors);
    } else {
      const updatedErrors = [...erroreducation];
      updatedErrors[i].yearTo = "";
      setErroreducation(updatedErrors);
    }
    return formIsValid;
  }

  function validateLevelListening(i) {
    let formIsValid = true;
    const languageFilled =
      focusRefs?.languages?.current[i]?.language?.props?.value;

    if (
      languageFilled &&
      (!languages[i]?.levelListening ||
        typeof languages[i]?.levelListening === "undefined")
    ) {
      formIsValid = false;
      const updatedErrorLanguages = [...errorlanguages];
      updatedErrorLanguages[i].levelListening =
        "Seleziona il livello di ascolto.";
      setErrorlanguages(updatedErrorLanguages);
    } else {
      const updatedErrorLanguages = [...errorlanguages];
      updatedErrorLanguages[i].levelListening = "";
      setErrorlanguages(updatedErrorLanguages);
    }

    return formIsValid;
  }

  function validateLevelReading(i) {
    let formIsValid = true;
    const languageFilled =
      focusRefs?.languages?.current[i]?.language?.props?.value;

    if (
      languageFilled &&
      (!languages[i]?.levelReading ||
        typeof languages[i]?.levelReading === "undefined")
    ) {
      formIsValid = false;
      const updatedErrorLanguages = [...errorlanguages];
      updatedErrorLanguages[i].levelReading =
        "Seleziona il livello di lettura.";
      setErrorlanguages(updatedErrorLanguages);
    } else {
      const updatedErrorLanguages = [...errorlanguages];
      updatedErrorLanguages[i].levelReading = "";
      setErrorlanguages(updatedErrorLanguages);
    }

    return formIsValid;
  }

  function validateLevelWritten(i) {
    let formIsValid = true;
    const languageFilled =
      focusRefs?.languages?.current[i]?.language?.props?.value;

    if (
      languageFilled &&
      (!languages[i]?.levelWritten ||
        typeof languages[i]?.levelWritten === "undefined")
    ) {
      formIsValid = false;
      const updatedErrorLanguages = [...errorlanguages];
      updatedErrorLanguages[i].levelWritten =
        "Seleziona il livello dello scritto.";
      setErrorlanguages(updatedErrorLanguages);
    } else {
      const updatedErrorLanguages = [...errorlanguages];
      updatedErrorLanguages[i].levelWritten = "";
      setErrorlanguages(updatedErrorLanguages);
    }

    return formIsValid;
  }

  function validateLevelSpoken(i) {
    let formIsValid = true;
    const languageFilled =
      focusRefs?.languages?.current[i]?.language?.props?.value;

    if (
      languageFilled &&
      (!languages[i]?.levelSpoken ||
        typeof languages[i]?.levelSpoken === "undefined")
    ) {
      formIsValid = false;
      const updatedErrorLanguages = [...errorlanguages];
      updatedErrorLanguages[i].levelSpoken =
        "Seleziona il livello del parlato.";
      setErrorlanguages(updatedErrorLanguages);
    } else {
      const updatedErrorLanguages = [...errorlanguages];
      updatedErrorLanguages[i].levelSpoken = "";
      setErrorlanguages(updatedErrorLanguages);
    }

    return formIsValid;
  }

  // ======================= skills validation =========================================

  const validateSkill = () => {
    if (selectedSkills?.length === 0) {
      seterrorSkill("Seleziona almeno una skill.");
      return false;
    } else {
      seterrorSkill("");
      return true;
    }
  };

  //=====================================Resume File==========================================

  const handleChangeResume = (e) => {
    const { type, files } = e.target;
    let newValue;

    if (type === "file") {
      newValue = files[0];
      setResume(newValue);
    } else {
      setResume(null);
    }
  };

  //===========================resume delete ==================================

  const onDeleteResume = () => {
    const token = localStorage.getItem("token");

    if (token) {
      props.requestDeleteResumeFile({ token });
    }
    // setResume(null);
  };

  useEffect(() => {
    const resumeFileDelete = props.candidate.DeleteResume;

    if (resumeFileDelete) {
      const message = resumeFileDelete?.data?.messages?.[0]?.message;

      if (message) {
        toast.error(message, { theme: "colored", autoClose: 1000 });
      }

      const { Status, Message } = resumeFileDelete.data;

      if (Status === "Success") {
        toast.success(Status, { theme: "colored", autoClose: 1000 });
        setResume(null);
        setError(false);
      } else {
        toast.error(Message, { theme: "colored", autoClose: 1000 });
        setError(true);
      }
      props.candidate.DeleteResume = undefined;
    }
  }, [props.candidate.DeleteResume]);

  //===========================resume delete ==================================

  const validateResume_upload = () => {
    const selectedFile = resumeUploadRef.current.files[0];
    if (selectedFile) {
      const allowedFormats = [".pdf", ".doc", ".docx"];
      const fileExtension = selectedFile.name
        .substring(selectedFile.name.lastIndexOf("."))
        .toLowerCase();

      if (!allowedFormats.includes(fileExtension)) {
        setErrorResume_upload(
          "Formato file non valido. Si prega di caricare un file PDF, DOC o DOCX."
        );
        return false;
      }
    }
    setErrorResume_upload("");
    return true;
  };

  //=====================================Resume File==========================================

  // const validateEducationForm = () => {
  //     return education.every((_, i) => {
  //         return [
  //             validateEducationLevel(i),
  //             // validateSubEducationLevel(i),
  //             validateEducationInstituteName(i),
  //             validateEducationCountry(i),
  //             validateEducationProvince(i),
  //             validateEducationCommon(i),
  //             validateEducationYearFrom(i),
  //             validateEducationYearTo(i)
  //         ].every(validation => validation);
  //     });
  // };

  const validateEducationForm = () => {
    if (education.length > 0) {
      return education.every((_, i) => {
        return [
          validateEducationLevel(i),
          validateEducationInstituteName(i),
          validateEducationCountry(i),
          validateEducationProvince(i),
          validateEducationCommon(i),
          validateEducationYearFrom(i),
          validateEducationYearTo(i),
        ].every((validation) => validation);
      });
    }
    return false;
  };

  const validateExperienceForm = () => {
    if (experience.length > 0) {
      return experience.every((_, i) => {
        return [
          validateExperienceSector(i),
          validateExperienceRole(i),
          validateExperienceCountry(i),
          validateExperienceCommon(i),
          validateExperienceProvince(i),
          validateExperienceCompany(i),
          validateExperienceYearFrom(i),
          validateExperienceYearTo(i),
          validateExperienceLetter(i),
          // validateExperienceDescription(i)
        ].every((validation) => validation);
      });
    }
    return false;
  };

  const validateLanguagesForm = () => {
    return languages.every((_, i) => {
      return [
        validateLevelListening(i),
        validateLevelReading(i),
        validateLevelWritten(i),
        validateLevelSpoken(i),
      ].every((validation) => validation);
    });
  };

  useEffect(() => {
    languages.forEach((lang, i) => {
      if (lang.language) {
        validateLevelListening(i);
        validateLevelReading(i);
        validateLevelWritten(i);
        validateLevelSpoken(i);
      }
    });
  }, [languages]);

  // ===============final upload resume Data ==================

  const validateForm = () => {
    const resumeValid = validateResume_upload();
    const educationValid = validateEducationForm();
    const experienceValid = validateExperienceForm();
    const languageValid = validateLanguagesForm();
    const skillValid = validateSkill();

    return (
      resumeValid &&
      educationValid &&
      experienceValid &&
      languageValid &&
      skillValid
    );
  };

  const toggleAccordion = (index) => {
    setOpenSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // const toggleAccordion = (index) => {
  //     setOpenSections((prev) => {
  //         const newState = {
  //             ...prev,
  //             [index]: !prev[index],
  //         };
  //         if (newState[0] && education.length === 0) {
  //             setEducation([
  //                 ...education,
  //                 {
  //                     educationalLevel: "",
  //                     type: "",
  //                     name: "",
  //                     address: {
  //                         country: "Italia",
  //                         province: "",
  //                         city: ""
  //                     },
  //                     yearFrom: "",
  //                     monthFrom: "",
  //                     yearTo: "",
  //                     monthTo: "",
  //                 },
  //             ]);

  //             setErroreducation([
  //                 ...erroreducation,
  //                 {
  //                     educationalLevel: "",
  //                     type: "",
  //                     name: "",
  //                     address: {
  //                         country: "",
  //                         province: "",
  //                         city: ""
  //                     },
  //                     yearFrom: "",
  //                     monthFrom: "",
  //                     yearTo: "",
  //                     monthTo: "",
  //                 },
  //             ]);
  //         } else if (newState[1] && experience.length === 0) {
  //             setExperience([
  //                 ...experience,
  //                 {
  //                     sector: "",
  //                     role: "",
  //                     company: "",
  //                     address: {
  //                         country: "Italia",
  //                         province: "",
  //                         city: "",
  //                     },
  //                     yearFrom: "",
  //                     monthFrom: "",
  //                     yearTo: "",
  //                     monthTo: "",
  //                     reccomendationLetter: "",
  //                     description: "",
  //                 },
  //             ]);

  //             setErrorexperience([
  //                 ...errorexperience,
  //                 {
  //                     sector: "",
  //                     role: "",
  //                     company: "",
  //                     address: {
  //                         country: "",
  //                         province: "",
  //                         city: ""
  //                     },
  //                     yearFrom: "",
  //                     monthFrom: "",
  //                     yearTo: "",
  //                     monthTo: "",
  //                     reccomendationLetter: "",
  //                     description: ""
  //                 },
  //             ]);
  //         } else if (newState[2] && languages.length === 0) {
  //             setLanguages([
  //                 ...languages,
  //                 {
  //                     language: "",
  //                     levelListening: "",
  //                     levelReading: "",
  //                     levelWritten: "",
  //                     levelSpoken: "",
  //                 },
  //             ]);

  //             setErrorlanguages([
  //                 ...errorlanguages,
  //                 {
  //                     language: "",
  //                     levelListening: "",
  //                     levelReading: "",
  //                     levelWritten: "",
  //                     levelSpoken: "",
  //                 },
  //             ]);
  //         }
  //         return newState;
  //     });
  // };

  const handleDownloadPdf = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    setOpenSections({ 0: true, 1: true, 2: true });

   

    if (validateForm()) {
      props.requestUpdateResumeDetails({
        data: {
          userIp: ipAddress,
          username: atob(localStorage.getItem("username")),
          resume_file: resume,
          educations: education,
          works: experience,
          languages: languages,
          skills: modifyskillData,
        },
        token: token
      });
      setError(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    const updateResumDrata = props?.candidate?.UpdateResumeData;

    if (updateResumDrata) {
      const { Status, Message } = updateResumDrata.data;
      if (Status === "Success") {
        toast.success(Status, { theme: "colored", autoClose: 1000 });
        navigate("/");
      } else {
        toast.error(Message, { theme: "colored", autoClose: 1000 });
        setError(true);
      }
      props.candidate.UpdateResumeData = undefined;
    }
  }, [props?.candidate?.UpdateResumeData]);

  useEffect(() => {
    const focusErrorField = () => {
      if (error) {
        if (errorResume_upload && pdfIconRef.current) {
          pdfIconRef.current.focus();
          return true;
        }

        if (errorskill && skillRef.current) {
          skillRef.current.focus();
          return true;
        }

        for (let i = 0; i < erroreducation.length; i++) {
          const eduError = erroreducation[i];
          if (eduError.educationalLevel) {
            focusRefs.education.current[i]?.educationalLevel?.focus();
            return true;
          } else if (eduError.educationalSector) {
            focusRefs.education.current[i]?.educationalSector?.focus();
            return true;
          } else if (eduError.type) {
            focusRefs.education.current[i]?.type?.focus();
            return true;
          } else if (eduError.name) {
            focusRefs.education.current[i]?.name?.focus();
            return true;
          } else if (eduError.yearFrom) {
            focusRefs.education.current[i]?.yearFromedu?.focus();
            return true;
          } else if (eduError.yearTo) {
            focusRefs.education.current[i]?.yearToedu?.focus();
            return true;
          } else if (eduError.address?.country) {
            focusRefs.education.current[i]?.country_education?.focus();
            return true;
          } else if (eduError.address?.city) {
            focusRefs.education.current[i]?.common_education?.focus();
            return true;
          } else if (eduError.address?.province) {
            focusRefs.education.current[i]?.province_education?.focus();
            return true;
          }
        }

        for (let i = 0; i < errorexperience.length; i++) {
          const expError = errorexperience[i];
          if (expError?.sector) {
            focusRefs.experience.current[i]?.sector?.focus();
            return true;
          } else if (expError?.role) {
            focusRefs.experience.current[i]?.role?.focus();
            return true;
          } else if (expError?.address?.country) {
            focusRefs.experience.current[i]?.country_experience?.focus();
            return true;
          } else if (expError?.address?.city) {
            focusRefs.experience.current[i]?.common_experience?.focus();
            return true;
          } else if (expError?.address?.province) {
            focusRefs.experience.current[i]?.province_experience?.focus();
            return true;
          } else if (expError?.company) {
            focusRefs.experience.current[i]?.company?.focus();
            return true;
          } else if (expError?.yearFrom) {
            focusRefs.experience.current[i]?.yearFromexp?.focus();
            return true;
          } else if (expError?.yearTo) {
            focusRefs.experience.current[i]?.yearToexp?.focus();
            return true;
          } else if (expError?.reccomendationLetter) {
            focusRefs.experience.current[i]?.reccomendationLetterexp?.focus();
            return true;
          }
        }

        for (let i = 0; i < errorlanguages.length; i++) {
          const lanError = errorlanguages[i];
          if (lanError.language) {
            focusRefs.languages.current[i]?.language?.focus();
            return true;
          } else if (lanError.levelListening) {
            focusRefs.languages.current[i]?.levelListening?.focus();
            return true;
          } else if (lanError.levelReading) {
            focusRefs.languages.current[i]?.levelReading?.focus();
            return true;
          } else if (lanError.levelWritten) {
            focusRefs.languages.current[i]?.levelWritten?.focus();
            return true;
          } else if (lanError.levelSpoken) {
            focusRefs.languages.current[i]?.levelSpoken?.focus();
            return true;
          }
        }
      }
      return false;
    };

    if (focusErrorField()) {
      setError(false);
    }
  }, [
    error,
    errorResume_upload,
    pdfIconRef,
    errorskill,
    erroreducation,
    errorexperience,
    errorlanguages,
    skillRef,
    focusRefs,
  ]);

  return (
    <>
      <Header />
      <div className="curriculum_sec">
        <div className="container">
          <div className="curriculum_inner">
            <div className="curriculum_title_btn">
              <div className="curriculum_title">
                <h2>Il mio Curriculum Vitae</h2>
              </div>
            </div>
            <div className="curriculum_btn">
              <button
                type="submit"
                className="btn btn_default btn_green"
                onClick={handleDownloadPdf}
              >
                Aggiorna CV
              </button>
            </div>
            <ResumeFileToolbar
              handleChangeResume={handleChangeResume}
              validateResume_upload={validateResume_upload}
              errorResume_upload={errorResume_upload}
              resumeUploadRef={resumeUploadRef}
              pdfIconRef={pdfIconRef}
              resume={resume}
              onDeleteResume={onDeleteResume}
            />

            <div className="curriculum_dropdown">
              <div className="curriculum_dropdown-title">
                <h4>Competenze</h4>
              </div>

              <div className="single-section-form bg-white border-0">
                <SkillSelect
                  skillData={skillData}
                  selectedSkills={selectedSkills}
                  handleChangeSkill={handleChangeSkill}
                  handleCreateSkillOption={handleCreateSkillOption}
                  validateSkill={validateSkill}
                  isClearable={isClearable}
                  errorskill={errorskill}
                  mystyle={mystyle}
                  skillRef={skillRef}
                  t={t}
                />
              </div>
            </div>

            <div className="accordion curriculum_acc" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className={`accordion-button ${
                      openSections[0] ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => toggleAccordion(0)}
                    aria-expanded={openSections[0]}
                    aria-controls="collapseOne"
                  >
                    Formazione
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className={`accordion-collapse collapse ${
                    openSections[0] ? "show" : ""
                  }`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <EducationForm
                      education={education}
                      setEducation={setEducation}
                      erroreducation={erroreducation}
                      sortedCountryData={sortedCountryData}
                      municipalities={municipalities}
                      validateEducationLevel={validateEducationLevel}
                      // validateSubEducationLevel={validateSubEducationLevel}
                      // validateEducationStudysector={validateEducationStudysector}
                      validateEducationInstituteName={
                        validateEducationInstituteName
                      }
                      validateEducationYearFrom={validateEducationYearFrom}
                      validateEducationYearTo={validateEducationYearTo}
                      validateEducationCountry={validateEducationCountry}
                      validateEducationCommon={validateEducationCommon}
                      validateEducationProvince={validateEducationProvince}
                      handleInputEducation={handleInputEducation}
                      handleSubsubmenuChange={handleSubsubmenuChange}
                      handleRemoveEducation={handleRemoveEducation}
                      handleChangeEducationCity={handleChangeEducationCity}
                      handleAddEducation={handleAddEducation}
                      setErroreducation={setErroreducation}
                      FontAwesomeIcon={FontAwesomeIcon}
                      faTrashCan={faTrashCan}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isSearchable={isSearchable}
                      filterConfig={filterConfig}
                      mystyle={mystyle}
                      focusRefs={focusRefs}
                      error={error}
                      setError={setError}
                      selectedOptionsEducation={selectedOptionsEducation}
                      t={t}
                      handleChangeEducationLevel={handleChangeEducationLevel}
                      educationLevels={educationLevels}
                      subSubmenuOptions={subSubmenuOptions}
                    />
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className={`accordion-button ${
                      openSections[1] ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => toggleAccordion(1)}
                    aria-expanded={openSections[1]}
                    aria-controls="collapseTwo"
                  >
                    Esperienze professionali
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className={`accordion-collapse collapse ${
                    openSections[1] ? "show" : ""
                  }`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ExperienceForm
                      experience={experience}
                      setExperience={setExperience}
                      errorexperience={errorexperience}
                      sortedCountryData={sortedCountryData}
                      municipalities={municipalities}
                      validateExperienceSector={validateExperienceSector}
                      validateExperienceRole={validateExperienceRole}
                      validateExperienceCountry={validateExperienceCountry}
                      validateExperienceCommon={validateExperienceCommon}
                      validateExperienceProvince={validateExperienceProvince}
                      validateExperienceCompany={validateExperienceCompany}
                      validateExperienceYearFrom={validateExperienceYearFrom}
                      validateExperienceYearTo={validateExperienceYearTo}
                      validateExperienceLetter={validateExperienceLetter}
                      // validateExperienceDescription={validateExperienceDescription}
                      handleInputExperience={handleInputExperience}
                      handleSubMenuExpChange={handleSubMenuExpChange}
                      handleInputExperience1={handleInputExperience1}
                      handleRemoveExperience={handleRemoveExperience}
                      handleChangeExprienceCity={handleChangeExprienceCity}
                      handleAddExperience={handleAddExperience}
                      FontAwesomeIcon={FontAwesomeIcon}
                      faTrashCan={faTrashCan}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isSearchable={isSearchable}
                      filterConfig={filterConfig}
                      mystyle={mystyle}
                      focusRefs={focusRefs}
                      error={error}
                      setError={setError}
                      selectedOptionsExperience={selectedOptionsExperience}
                      t={t}
                      handleChangeSectorLevel={handleChangeSectorLevel}
                      sectorsLevel={sectorsLevel}
                      subSubSectorsLevel={subSubSectorsLevel}
                      deleteExperienceLetter={deleteExperienceLetter}
                      deleteIndexRef={deleteIndexRef}
                    />
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className={`accordion-button ${
                      openSections[2] ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => toggleAccordion(2)}
                    aria-expanded={openSections[2]}
                    aria-controls="collapseThree"
                  >
                    Lingue conosciute
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className={`accordion-collapse collapse ${
                    openSections[2] ? "show" : ""
                  }`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <LanguageForm
                      languages={languages}
                      errorlanguages={errorlanguages}
                      validateLevelListening={validateLevelListening}
                      validateLevelReading={validateLevelReading}
                      validateLevelWritten={validateLevelWritten}
                      validateLevelSpoken={validateLevelSpoken}
                      handleInputLanguage={handleInputLanguage}
                      handleChangeLanguage={handleChangeLanguage}
                      handleRemoveLanguage={handleRemoveLanguage}
                      handleAddLanguages={handleAddLanguages}
                      // selectedOptionsLanguage={selectedOptionsLanguage}
                      mystyle={mystyle}
                      isClearable={isClearable}
                      FontAwesomeIcon={FontAwesomeIcon}
                      faTrashCan={faTrashCan}
                      focusRefs={focusRefs}
                      error={error}
                      setError={setError}
                      t={t}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="curriculum_btn">
              <button
                type="submit"
                className="btn btn_default btn_green"
                onClick={handleDownloadPdf}
              >
                Aggiorna CV
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return { candidate: state?.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestGetList,
      requestGetResumeDetails,
      requestGetEducationLevelList,
      requestGetSectorList,
      requestUpdateResumeDetails,
      requestDeleteResumeFile,
      requestDeleteLetterFile,
      userLogout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UpdateResume);
