import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestPatchAdvertisement } from '../../Redux/actions'

function PermissionAdvertisment (props) {
  const { item } = props
  const itemId = item.id;
  const navigate = useNavigate()
  const [checkedDraft, setCheckedDraft] = useState([])
  const [checkedPublished, setCheckedPublished] = useState([])
  const [checkedSuspended, setCheckedSuspended] = useState([]);
  const [checkedClosed, setCheckedClosed] = useState([]);
  const [disabledAd, setDisabledAd] = useState(false);
  const [draftId, setDraftId] = useState(item.draft_id);

  const handleDraftChange = itemId => {
    setCheckedDraft(prevCheckedItems => ({
      ...prevCheckedItems,
      [itemId]: !prevCheckedItems[itemId]
    }));
    console.log(item.draft_id, 'draft_id');
  }

  const handlePublishedChange = itemId => {
    const token = localStorage.getItem("token");
    if (!item.have_been_sponsored) {
      const data = {
        published: true,
        suspended: false
      };
      props.requestPatchAdvertisement({
        data: data,
        token: token,
        id: itemId
      });
     
    } else {
      navigate('/company/CreaAnnuncio7_bis')
    }
    setCheckedPublished(prevCheckedItems => ({
      ...prevCheckedItems,
      [itemId]: !prevCheckedItems[itemId]
    }))

    if (checkedSuspended[itemId] === true) {
      setCheckedSuspended(prevCheckedItems => ({
        ...prevCheckedItems,
        [itemId]: false
      }))
      
    }
  }

  const handleSuspendedChange = itemId => {
    console.log(itemId, 'itemId');
    const token = localStorage.getItem("token");
    const data = {
      published: false,
      suspended: true
    };
    props.requestPatchAdvertisement({
      data: data,
      token: token,
      id: itemId
    });
    setCheckedSuspended(prevCheckedItems => ({
      ...prevCheckedItems,
      [itemId]: !prevCheckedItems[itemId]
    }));
    console.log(checkedPublished[itemId], 'checkedPublished[itemId]');
    console.log(checkedSuspended, 'checkedSuspended');
    if (checkedPublished[itemId] === true) {
      setCheckedPublished(prevCheckedItems => ({
        ...prevCheckedItems,
        [itemId]: false
      }));
      
    }
  }

  const isDraftChecked = item.draft_id && item.draft_id !== null ? false : true

  // const isPublishedChecked = (item.published === null || item.published === false || item.have_been_sponsored === null || item.have_been_sponsored === false ) ? false : true;
  useEffect(() => {
    setCheckedPublished(prevItem => ({
      ...prevItem,
      [item.id]: item.published
    }));
  }, []);
  

  const isSuspendedChecked = item.suspended && !item.published

  const isClosedChecked = item.closed && !item.published

  const handleClosedChange = itemId => {
    setCheckedClosed(prevCheckedItems => ({
      ...prevCheckedItems,
      [itemId]: !prevCheckedItems[itemId]
    }))

    setCheckedPublished(prevCheckedItems => ({
      ...prevCheckedItems,
      [itemId]: false
    }))

    setCheckedSuspended(prevCheckedItems => ({
      ...prevCheckedItems,
      [itemId]: false
    }))

    setDisabledAd(true)

    if (isClosedChecked) {
      //navigate("/company/advertisement-details/" + itemId);
    }
  }

  const handleEditDraftClick = itemId => {
    navigate("/company/create-adv/?setStep=1&adId=" + draftId);
  }

  const handleCreateDraftClick = itemId => {
    
    const token = localStorage.getItem("token");
    props.requestPatchAdvertisement({
      
      token: token,
      id: item.id
    });
  }

  return (
    <td>
      <div className='advertisement_status'>
        <form className='advertisement_status_form'>
          <div className='form-group'>
            <p>Bozza</p>
                      {draftId == null ? (
                        checkedPublished ? (
                          <a onClick={handleCreateDraftClick}>Crea bozza</a>
                        )
                        :

                        <>
                        <input
                            type="checkbox"
                            id={`${item.id}_draft`}
                            checked={!!checkedDraft[item.id] || isDraftChecked}
                            onChange={() => handleDraftChange(item.id)}
                        />
                        
                        <label htmlFor={`${item.id}_draft`}></label>
                        </>
                      ) : (
                        <a onClick={handleEditDraftClick}>Modifica bozza</a>
                      )
                      }
            
          </div>

          <div className='form-group'>
            <p>Pubblicato</p>

            <input
              type='checkbox'
              id={`${item.id}_published`}
              checked={checkedPublished[item.id]}
              onChange={() => handlePublishedChange(item.id)}
              disabled={disabledAd}
            />
            <label htmlFor={`${item.id}_published`}></label>
          </div>

          <div className='form-group'>
            <p>Sospeso</p>
            <input
              type='checkbox'
              id={`${item.id}_suspended`}
              checked={!!checkedSuspended[item.id] || isSuspendedChecked}
              onChange={() => handleSuspendedChange(item.id)}
              disabled={disabledAd}
            />
            <label htmlFor={`${item.id}_suspended`}></label>
          </div>

          <div className='form-group'>
            <p>Chiuso</p>
            <input
              type='checkbox'
              id={`${item.id}_closed`}
              checked={!!checkedClosed[item.id] || isClosedChecked}
              onChange={() => handleClosedChange(item.id)}
              // disabled
            />
            <label htmlFor={`${item.id}_closed`}></label>
          </div>
        </form>
      </div>
    </td>
  )
}

const mapStateToProps = state => {
  return { company: state.company, candidate: state.candidate }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestPatchAdvertisement
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionAdvertisment)
