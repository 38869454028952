import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "./Header";
import Footer from "./Footer";
import { requestGetCandidate, userLogout } from "../Redux/actions";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CookieBanner from "./CookieBanner";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import { useAppNavigation } from "../utils/routing";
//======================= session exipire ================================================
// import { jwtDecode } from "jwt-decode";
//======================= session exipire ================================================

function Home(props) {
  const [showModal, setShowModal] = useState("");
  const [userType, setUserType] = useState("");
  // const [userType, setUserType] = useState(() => {
  //   return atob(localStorage.getItem("userType")) || null;
  // });
  const { t } = useTranslation();
  const location = useLocation();

  const [_, setReload] = useState();

  useEffect(() => {
    const storedUserType = localStorage.getItem("userType");
    const decodedUserType = storedUserType ? atob(storedUserType) : "";
    setUserType(decodedUserType);

    try {
      if (location?.state?.showModal !== undefined) {
        setShowModal(location.state.showModal);
      }
    } catch (error) {
      console.error("Error decoding username:", error);
    }
  }, [location?.state?.showModal, userType]);

  const { navigateTo } = useAppNavigation();

  useEffect(() => {
    console.log("props agent");
    console.log(props.agent);
    if (props.candidate && "getlistData" in props.candidate) {
      props.candidate.getlistData = undefined;
      props.candidate.cvListData = undefined;
      console.log("Setting candidate GetJobListData to undefined");
    }
    if (props.company && "getCompanyData" in props.company) {
      props.company.getCompanyData = undefined;
      props.company.cvListData = undefined;
      console.log("Setting company GetJobListData to undefined");
    }
    if (props.agent && "getlistData" in props.agent) {
      props.agent.getlistData = undefined;
      props.agent.cvListData = undefined;
      console.log("Setting agent GetJobListData to undefined");
    }
    if (props.franchisor && "getlistData" in props.franchisor) {
      props.franchisor.getlistData = undefined;
    }

    if (location.state?.jobListData?.length) {
      navigateTo(location.pathname, { state: { jobListData: [] } });
    }

    setReload((prevReload) => {
      const newReload = Math.random();
      return newReload;

  });
  }, [
    location.state?.jobListData,
    props.candidate?.GetJobListData,
    props.company?.GetJobListData,
    props.agent?.GetJobListData,
    props.franchisor?.GetJobListData,
    location
  ]);

  return (
    <>
      <Header showModal={showModal} setShowModal={setShowModal} />
      <Breadcrumbs />
      <div className="main_sec">
        <div className="container">
          <div className="main_sec_inner">
            <div className="look_for_main">
              <div className="look_for_title">
                <h2>{t("home.tab-first.title")}</h2>
              </div>
              <div className="look_for_blocks">
                {/* {userType === "employee" ? (
                  <div className="look_for_block">
                    <Link to={"/candidate/profile"}>
                      {t('home.tab-first.sub-title-1')}
                    </Link>
                  </div>
                ) : userType === "noType" ? (
                  <div className="look_for_block">
                    <Link to={"/candidate/profile"}>
                      {t('home.tab-first.sub-title-1')}
                    </Link>
                  </div>
                ) : (
                  <div className="look_for_block">
                    <Link to={"/candidate/register"}>
                      {t('home.tab-first.sub-title-1')}
                    </Link>
                  </div>
                )
                } */}

                {userType === "employee" ? (
                  <div className="look_for_block">
                    <Link to={"/job-finder"} state={{ jobListData: [] }}>
                      {t("home.tab-first.sub-title-1")}
                    </Link>
                  </div>
                ) : userType === "noType" ? (
                  <div className="look_for_block">
                    <Link to={"/job-finder"} state={{ jobListData: [] }}>
                      {t("home.tab-first.sub-title-1")}
                    </Link>
                  </div>
                ) : userType === "company" ? (
                  <div className="look_for_block">
                    <Link to={"/job-finder"} state={{ jobListData: [] }}>
                      {t("home.tab-first.sub-title-1")}
                    </Link>
                  </div>
                ) : (
                  <div className="look_for_block">
                    <Link to={"/job-finder"} state={{ jobListData: [] }}>
                      {t("home.tab-first.sub-title-1")}
                    </Link>
                  </div>
                )}

                <div className="look_for_block">
                  <Link
                    to={"/agent/opportunity-finder"}
                    state={{ jobListData: [] }}
                  >
                    {t("home.tab-first.sub-title-2")}
                  </Link>

                  {/* {userType === "employee" ? (
                    <div className="look_for_block">
                      <Link to={"/candidate/profile"}>
                        {t('home.tab-first.sub-title-2')}
                      </Link>
                    </div>
                  ) : userType === "noType" ? (
                    <div className="look_for_block">
                      <Link to={"/candidate/profile"}>
                        {t('home.tab-first.sub-title-2')}
                      </Link>
                    </div>
                  ) : (
                    <div className="look_for_block">
                      <Link to={"/candidate/register"}>
                        {t('home.tab-first.sub-title-2')}
                      </Link>
                    </div>
                  )
                  } */}
                </div>
                <div className="look_for_block">
                  <Link
                    to={"/franchisor/search"}
                    state={{ jobListData: [], searching: true }}
                  >
                    {t("home.tab-first.sub-title-3")}
                  </Link>
                </div>
                <div className="look_for_block">
                  <Link to={"/franchisor/register"}>
                    {t("home.tab-first.sub-title-4")}
                  </Link>
                </div>
                <div className="look_for_block">
                  <Link to={"/"}>{t("home.tab-first.sub-title-5")}</Link>
                </div>
              </div>
            </div>
            <div className="offer_main">
              <div className="offer_title">
                <h2>{t("home.tab-second.title")}</h2>
              </div>
              <div className="offer_blocks">
                {userType === "company" ? (
                  <div className="offer_block">
                    <Link
                      to={"/company/candidate-finder"}
                      state={{ jobListData: [] }}
                    >
                      {t("home.tab-second.sub-title-1")}
                    </Link>
                  </div>
                ) : (
                  <div className="offer_block">
                    <Link
                      to={"/company/candidate-finder"}
                      state={{ jobListData: [] }}
                    >
                      {t("home.tab-second.sub-title-1")}
                    </Link>
                  </div>
                )}
                {/* <div className="offer_block">
                  <Link to={"/company/register"}>
                    {t('home.tab-second.sub-title-1')}
                  </Link>
                </div> */}
                <div className="offer_block">
                  <Link to={"/agent/search"} state={{ jobListData: [] }}>
                    {t("home.tab-second.sub-title-2")}
                  </Link>
                </div>
                <div className="offer_block">
                  <Link
                    to={
                      props.candidate?.loginData?.data.Message.flag_franchisor
                        ? "/franchisor/register2"
                        : "/franchisor/register"
                    }
                  >
                    {t("home.tab-second.sub-title-3")}
                  </Link>
                </div>
                <div className="offer_block">
                  <Link to={"/"}>{t("home.tab-second.sub-title-4")}</Link>
                </div>
                <div className="offer_block">
                  <Link to={"/"}>{t("home.tab-second.sub-title-5")}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CookieBanner />
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return { candidate: state?.candidate, company: state?.company, agent:state?.agent };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestGetCandidate, userLogout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
