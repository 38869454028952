// import React from 'react';
// import { useNavigate } from 'react-router-dom';

// const DropdownNavigation = () => {
//   const navigate = useNavigate();

//   const handleChange = (event) => {
//     const { value } = event.target;
//     navigate(value);
//   };

//   return (
//     <div className="dropdown_navigation">
//       <select onChange={handleChange}>
//         <option value="">Select</option>
//         <option value="/home">Home</option>
//         <option value="/candidate/profile">profile</option>
//       </select>
//     </div>
//   );
// };

// export default DropdownNavigation;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useAppNavigation } from "../utils/routing";
import { useTranslation } from "react-i18next";

const DropdownNavigation = (props) => {
  const [userType, setUserType] = useState("");
  const navigate = useNavigate();

  console.log("utente dropdown", props);

  const handleNavigate = (path) => {
    if(path === '/company/create-adv') {
      navigate(path, {
        state: {
          ad_id: null
        }
      })
    } else {
      navigate(path);
    }
  };

  const { t } = useTranslation();

  const { navigateTo } = useAppNavigation();

  useEffect(() => {
    const storedUserType = localStorage.getItem("userType");
    const decodedUserType = storedUserType ? atob(storedUserType) : "";
    setUserType(decodedUserType);
  }, []);

  return (
    <>
      {/* <div className="dropdown_navigation">
        <div className="dropdown">
          <a
            className="btn dropdown-toggle"
            href="/"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false">
            Select
          </a>
          <ul className="dropdown-menu">
            <li>
              <button
                className="dropdown-item"
                onClick={() => handleNavigate("/home")}
              >
                Home
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => handleNavigate("/candidate/profile")}
              >
                Profile
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => handleNavigate("/update-resume")}
              >
                Resume
              </button>
            </li>
          </ul>
        </div>
      </div> */}

      {/* <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          Menu
        </a>
        <ul className="dropdown-menu">
          <li>
            <button
              className="dropdown-item"
              onClick={() => handleNavigate("/home")}
            >
              Home
            </button>
          </li>
          <li>
            <button
              className="dropdown-item"
              onClick={() => handleNavigate("/candidate/profile")}
            >
              Profile
            </button>
          </li>
          <li>
            <button
              className="dropdown-item"
              onClick={() => handleNavigate("/update-resume")}
            >
              Resume
            </button>
          </li>
        </ul>
      </li> */}

      <li className="nav-item">
        <a className="nav-link">Menu</a>
        <ul className="sub-menu">
          <li>
            <button
              className="dropdown-item"
              onClick={() => handleNavigate("/")}
            >
              Home
            </button>
          </li>

          {(() => {
            if (props.agent.loginData?.data.Message.flag_agent) {
              return (
                <>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => navigateTo("/agent/profile")}
                    >
                      {t("header.dropdown.profile")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => navigateTo("/resume")}
                    >
                      {t("header.dropdown.resume")}
                    </button>
                  </li>
                </>
              );
            }

            if (props.agent.loginData?.data.Message.flag_candidate) {
              return (
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => navigateTo("/agent/profile")}
                  >
                    {t("header.dropdown.profile")}
                  </button>
                </li>
              );
            }

            if (props.agent.loginData?.data.Message.flag_franchisor) {
              return (
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => navigateTo("/company/profile")}
                  >
                    {t("header.dropdown.profile")}
                  </button>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        handleNavigate("/company/advertisement-list")
                      }
                    >
                      {t("header.dropdown.advertisement")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleNavigate("/company/PackageToken")}
                    >
                      {t("header.dropdown.packages")}
                    </button>
                  </li>
                  <li>
                <button
                  className="dropdown-item"
                  onClick={() => handleNavigate("/company/Creditmanagement")}>
                  {t("header.dropdown.credit-management")}
                </button>
              </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        handleNavigate("/company/candidate-finder")
                      }
                    >
                      {t("header.dropdown.cv-finder")}
                    </button>
                  </li>

                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleNavigate("/franchisor/register2")}
                    >
                      {t("header.dropdown.crea-annuncio")}
                    </button>
                  </li>
                </li>
              );
            }

            if (props.agent.loginData?.data.Message.flag_artisan) {
              return (
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => navigateTo("/agent/profile")}
                  >
                    {t("header.dropdown.profile")}
                  </button>
                </li>
              );
            }

            if (props.agent.loginData?.data.Message.flag_private) {
              return (
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => navigateTo("/agent/profile")}
                  >
                    {t("header.dropdown.profile")}
                  </button>
                </li>
              );
            }

            if (props.agent.loginData?.data.Message.flag_employee) {
              return (
                <>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleNavigate("/update-resume")}
                    >
                      {t("header.dropdown.resume")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleNavigate("/candidate/profile")}
                    >
                      {t("header.dropdown.profile")}
                    </button>
                  </li>
                </>
              );
            }

            if (props.agent.loginData?.data.Message.flag_professional) {
              return (
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => navigateTo("/agent/profile")}
                  >
                    {t("header.dropdown.profile")}
                  </button>
                </li>
              );
            }

            if (props.agent.loginData?.data.Message.flag_company) {
              return (
                <>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleNavigate("/company/profile")}
                    >
                      {t("header.dropdown.profile")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        handleNavigate("/company/advertisement-list")
                      }
                    >
                      {t("header.dropdown.advertisement")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleNavigate("/company/PackageToken")}
                    >
                      {t("header.dropdown.packages")}
                    </button>
                  </li>
                  <li>
                <button
                  className="dropdown-item"
                  onClick={() => handleNavigate("/company/Creditmanagement")}>
                  {t("header.dropdown.credit-management")}
                </button>
              </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        handleNavigate("/company/candidate-finder")
                      }
                    >
                      {t("header.dropdown.cv-finder")}
                    </button>
                  </li>

                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleNavigate("/company/create-adv")}
                    >
                      {t("header.dropdown.crea-annuncio")}
                    </button>
                  </li>
                </>
              );
            }

            return (
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => navigateTo("/candidate/profile")}
                >
                  {t("header.dropdown.profile")}
                </button>
              </li>
            );
          })()}
        </ul>
      </li>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    agent: state?.agent,
    candidate: state?.candidate,
    company: state?.company,
    franchisor: state?.franchisor,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DropdownNavigation);
