import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    requestStripePayment,
    requestPurchageTokenPayment,
    userLogout,
} from "../../../Redux/actions";
import { toast } from 'react-toastify';
import axios from 'axios';

function CheckoutForm(props) {
    const { amount, currency, productName, token_amount } = props.options;
    const stripe = useStripe();
    const elements = useElements();
    // const [errorMessage, setErrorMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async (event) => {
        const token = localStorage.getItem("token");
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const { error: submitError } = await elements.submit();
        if (submitError) {
            // setErrorMessage(submitError.message);
            return;
        }

        setIsProcessing(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_HOST}wideer/create-order/stripe/`, {
                amount: amount,
                currency: currency,
                productName: productName,
                token_amount: token_amount
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });

            const clientSecret = response.data.clientSecret;
            console.log(clientSecret);

            if (clientSecret) {
                const result = await stripe.confirmPayment({
                    elements,
                    clientSecret,
                    confirmParams: {
                        return_url: `http://jobportal.boiledbrains.it/home/company/payment/success/?token_amount=${token_amount}&productName=${productName}`
                    },
                });

                console.log(result, "resule");
                if (result) {
                    localStorage.setItem("stripe-confimr", JSON.stringify(result));
                    props.requestPurchageTokenPayment({
                        token: token,
                        data: {
                            bought: token_amount,
                            in: productName
                        }
                    });
                }

                if (result.error) {
                    console.error("Payment confirmation error:", result.error.message);
                    setIsProcessing(true);
                    // setErrorMessage(result.error.message);
                } else {
                    console.log("Payment successful!", result);

                    setIsProcessing(true);
                }
            }
        } catch (error) {
            console.error("Error creating order:", error);
        }
    };


    return (
        <form className='stripe_form' onSubmit={handleSubmit}>
            <PaymentElement />
            <button className='payment_stripe_btn' type="submit" disabled={isProcessing || !stripe || !elements}>
                <span id="button-text">
                    {isProcessing ? <>
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div></> : "Pay"}
                </span>
            </button>
            {/* {errorMessage && <div style={mystyle}>{errorMessage}</div>} */}
        </form>
    )
}


const mapStateToProps = (state) => {
    return { company: state.company };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        requestStripePayment,
        requestPurchageTokenPayment,
        userLogout
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);