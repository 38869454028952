import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Breadcrumbs from "../Components/Breadcrumbs";
import FormBuilder from "../Components/FormBuilder";

const NOTIFICATION_FREQUENCIES = [
  {
    label: "Ogni giorno",
    value: "every_day",
  },
  {
    label: "Ogni settimana",
    value: "every_week",
  },
];

const FIELDS = [
  {
    label: "Quanto spesso vorresti ricevere notifiche Email?",
    name: "email_frequency",
    type: "select",
  },
  {
    label: "Quanto spesso vorresti ricevere notifiche SMS?",
    name: "sms_frequency",
    type: "select",
  },
  {
    label: "Quanto spesso vorresti ricevere notifiche Whatsapp?",
    name: "whatsapp_frequency",
    type: "select",
  },
];

const NotificationPreferences = () => {
  const handleSubmit = () => {};

  return (
    <div className="min-vh-100 d-flex flex-column">
      <Header />
      <Breadcrumbs />
      <div className="container mt-4 h-100">
        <div className="h-100">
          <div className="seekng_job_title mb-4">
            <h2>Preferenze di notifica</h2>
          </div>
          <FormBuilder
            fields={FIELDS}
            onSubmit={handleSubmit}
            submitLabel="Avanti"
            selectOptions={{
              email_frequency: NOTIFICATION_FREQUENCIES,
              sms_frequency: NOTIFICATION_FREQUENCIES,
              whatsapp_frequency: NOTIFICATION_FREQUENCIES,
            }}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NotificationPreferences;
