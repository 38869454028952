import React, { useState } from "react";
import Header from "../Components/Header";
import Breadcrumbs from "../Components/Breadcrumbs";
import Footer from "../Components/Footer";
import FormBuilder from "../Components/FormBuilder";

const FIELDS = [
  {
    label: "Qual'è il tipo di servizio da te offerto?",
    name: "service_type",
    type: "text",
    mandatory: true,
  },
  {
    label: "Qual'è il servizio da te offerto?",
    name: "service_name",
    type: "text",
    mandatory: true,
  },
  {
    label: "Descrivi i servizi da te offerti",
    name: "services_description",
    type: "text_area",
    mandatory: false,
  },
  {
    label: "Inserisci una tua foto oppure un logo",
    name: "propic",
    type: "file",
    mandatory: false,
  },
  {
    label: "Inserisci i tuoi link social o siti web",
    name: "website_url",
    type: "url",
    mandatory: false,
    multiple: true,
  },
];

const ProfessionalRequirements = () => {
  const handleSubmit = (formData) => {
    console.log(formData);
  };

  return (
    <div className="min-vh-100 d-flex flex-column">
      <Header />
      <Breadcrumbs />
      <div className="container mt-4 min-vh-100">
        <div className="h-100">
          <div className="seekng_job_title mb-4">
            <h2>Alcune informazioni su di te</h2>
          </div>
          <FormBuilder
            fields={FIELDS}
            onSubmit={handleSubmit}
            submitLabel="Avanti"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProfessionalRequirements;
