import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Logo from '../images/logo.png'
import { requestLogin, userLogout } from '../Redux/actions'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { jwtDecode } from 'jwt-decode'
import { useNavigate, useLocation } from 'react-router-dom'
import Login from './login/Login'
import SocialRegister from './login/SocialRegister'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import { toast } from 'react-toastify'
import PasswordReset from './forggot-password/Password-Reset'
import PasswordResetConfirm from './forggot-password/Password-Reset-Confirm'
import DropdownNavigation from './DropdownNavigation'
import LanguageSelector from './LanguageSelector'

function Header (props) {
  const [user, setUser] = useState(null)
  const [showModal, setShowModal] = useState(props?.showModal || '')
  // const [showModal, setShowModal] = useState('');
  const location = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      try {
        const decodedToken = jwtDecode(token)
        setUser(decodedToken)
      } catch (error) {
        console.error('Invalid token', error)
        localStorage.removeItem('token')
      }
    }
  }, [])

  //======================= session exipire ================================================

  // useEffect(() => {
  //     const token = localStorage.getItem("token");
  //     if (token) {
  //         try {
  //             const decodedToken = jwtDecode(token);
  //             const currentTime = Date.now() / 1000;
  //             if (decodedToken.exp > currentTime) {
  //                 setUser(decodedToken);
  //             } else {
  //                 console.warn("Token has expired");
  //             }
  //         } catch (error) {
  //             console.error("Invalid token", error);
  //             toast.error('Invalid token. Please log in again.', { theme: "colored", autoClose: 1000 });
  //         }
  //     }
  // }, []);

  //======================= session exipire ================================================

  useEffect(() => {
    if (location.pathname !== '/resume') {
      localStorage.removeItem('education')
      localStorage.removeItem('educationalLevel')
      localStorage.removeItem('experience')
      localStorage.removeItem('experienceSector')
      localStorage.removeItem('languages')
      localStorage.removeItem('skills')
      localStorage.removeItem('currentStep')
    }
  }, [location])

  useEffect(() => {
    setShowModal(props.showModal)
  }, [props.showModal])

  useEffect(() => {
    if (typeof props.setShowModal === 'function') {
      props.setShowModal(showModal)
    }
    if (!showModal) {
      localStorage.removeItem('logging-for')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const logOut = () => {
    props.userLogout()
    localStorage.removeItem('token')
    localStorage.removeItem('username')
    localStorage.removeItem('userType')
    localStorage.removeItem('__paypal_storage__')
    setUser(null)
  }

  const handleLoginClick = () => {
    if (props.data === undefined) {
      setShowModal('login')
    } else {
      if (location.pathname === '/register') {
        toast.error('Can you please fill the registration form first?', {
          theme: 'colored',
          autoClose: 1000
        })
      }
    }
  }

  const handleRegisterClick = () => {
    if (props.data === undefined) {
      if (
        location.pathname === '/job-finder' ||
        location.pathname === '/jobs-list'
      ) {
        navigate('/candidate/register')
      } else if (
        location.pathname === '/agent/opportunity-finder' ||
        location.pathname === '/agent/opportunity-list'
      ) {
        navigate('/agent/register')
      } else {
        navigate('/register')
      }
    } else {
      if (location.pathname === '/register') {
        toast.error('Can you please fill the registration form first?', {
          theme: 'colored',
          autoClose: 1000
        })
      }
    }
  }

  const handleOnLogo = () => {
    if (props.data === undefined) {
      navigate('/', { state: { jobListData: [] } })
    } else {
      if (location.pathname === '/register') {
        toast.error('Can you please fill the registration form first?', {
          theme: 'colored',
          autoClose: 1000
        })
      }
    }
  }

  const handleCloseModal = () => {
    setShowModal('')
  }

  return (
    <>
      <div className='header_main'>
        <nav className='navbar navbar-expand-lg navbar-light'>
          <div className='container'>
            <div className='navbar-brand' onClick={handleOnLogo}>
              <img src={Logo} alt='Main Logo' />
            </div>
            {/* {user && <DropdownNavigation />} */}
            <div
              className='collapse navbar-collapse'
              id='navbarSupportedContent'
            >
              <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
                <li className='nav-item'>
                  <a className='nav-link'> {t('header.message')}</a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link">{t("header.notification")}</a>
                </li> */}
                {user ? (
                  <>
                    <li className='nav-item'>
                      <Link className='nav-link' to={'/'} onClick={logOut}>
                        Log Out
                      </Link>
                    </li>
                    <DropdownNavigation />
                  </>
                ) : (
                  <>
                    <li className='nav-item'>
                      <div className='nav-link' onClick={handleLoginClick}>
                        {t('header.login')}
                      </div>
                    </li>
                    <li className='nav-item'>
                      <div className='nav-link' onClick={handleRegisterClick}>
                        Registrati
                      </div>
                    </li>
                  </>
                )}
                {/*
                <li className='nav-item'>
                  <LanguageSelector />
                </li>
                */}
              </ul>
            </div>
          </div>
        </nav>
      </div>

      {showModal === 'login' && (
        <div
          className='modal fade show custom_modal'
          tabIndex='-1'
          aria-labelledby='loginModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button
                  type='button'
                  className='btn-close'
                  onClick={handleCloseModal}
                  aria-label='Close'
                ></button>
              </div>
              <div className='modal-body'>
                <Login
                  setShowModal={setShowModal}
                  handleCloseModal={handleCloseModal}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal === 'register' && (
        <div
          className='modal fade show custom_modal'
          tabIndex='-1'
          aria-labelledby='loginModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button
                  type='button'
                  className='btn-close'
                  onClick={handleCloseModal}
                  aria-label='Close'
                ></button>
              </div>
              <div className='modal-body'>
                <SocialRegister setShowModal={setShowModal} />
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal === 'password-reset' && (
        <div
          className='modal fade show custom_modal'
          tabIndex='-1'
          aria-labelledby='loginModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button
                  type='button'
                  className='btn-close'
                  onClick={handleCloseModal}
                  aria-label='Close'
                ></button>
              </div>
              <div className='modal-body'>
                <PasswordReset
                  setShowModal={setShowModal}
                  showModal={showModal}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {showModal === 'password-reset' &&
                <div className="custom_modal">
                    <PasswordResetConfirm />
                </div>
            } */}

      {showModal === 'password-confirm' && (
        <div className='custom_modal'>
          <PasswordResetConfirm />
        </div>
      )}
    </>
  )
}
const mapStateToProps = state => {
  return { candidate: state?.candidate }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ requestLogin, userLogout }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Header)
