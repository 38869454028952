import React from 'react';
import Select, { createFilter } from "react-select";

function IndirizzoField(props) {
    const {
        CreatAd_One,
        addressDetails,
        isClearable,
        isSearchable,
        filterConfig,
        mystyle,
        onSelectAddress,
        handleOnAddressSelect,
    } = props;


    const formatAddress = (address) => {
        const {
            street,
            building_number,
            zip_code,
            city,
            province,
            country,
        } = address;

        const parts = [];

        if (street) {
            parts.push(street);
        } else if (building_number) {
            return '';
        }

        if (zip_code) {
            parts.push(zip_code);
        }
        if (city) {
            parts.push(city);
        }

        if (province) {
            parts.push(`(${province})`);
        }
        if (country) {
            parts.push(country);
        }

        return parts.join(', ').trim();
    };

    const addressOptions = addressDetails ? [
        {
            value: addressDetails.indirizzo || '',
            label: formatAddress(addressDetails),
            addressDetails
        }
    ] : [];

    return (
        <div className="col-12">
            <label className="form-label">Indirizzo</label>
            <Select
                className="form-control select_dropdown"
                placeholder="Seleziona un indirizzo"
                options={addressOptions}
                value={
                    CreatAd_One.street || CreatAd_One.city || CreatAd_One.province || CreatAd_One.country || CreatAd_One.zip_code
                        ? {
                            label: `${CreatAd_One.street} ${CreatAd_One.city}  ${CreatAd_One.province} ${CreatAd_One.country}  ${CreatAd_One.zip_code}`,
                        }
                        : ""
                }
                isClearable={isClearable}
                isSearchable={isSearchable}
                filterOption={createFilter(filterConfig)}
                onChange={handleOnAddressSelect}
            />
        </div>
    )
}

export default IndirizzoField;