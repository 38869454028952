import React, { useState } from "react";
import Select from "react-select";

export const InputFieldRow = ({
  name,
  type,
  mandatory,
  value,
  multiple,
  variant,
  onRemove,
  onChange,
  onAdd,
}) => {
  return (
    <div className="d-flex gap-2">
      <input
        type={type}
        name={name}
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        className="form-control"
      />
      {multiple ? (
        <button
          onClick={variant === "danger" ? onRemove : onAdd}
          className={`btn btn-primary ${
            variant === "danger" ? "btn-danger" : ""
          }`}
        >
          {variant === "danger" ? "Rimuovi" : "Aggiungi"}
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

const FormBuilder = ({
  fields,
  onSubmit,
  submitLabel = "Avanti",
  values,
  selectOptions,
}) => {
  const [multipleFieldInputValue, setMultipleFieldInputValue] = useState({});
  const [formData, setFormData] = useState(
    values ??
      Object.fromEntries(
        fields.map((field) => [field.name, field.multiple ? [] : ""])
      )
  );

  return (
    <div>
      <div className="d-flex flex-column gap-4">
        {fields.map((field, i) => {
          return (
            <div key={i}>
              {(() => {
                switch (field.type) {
                  case "select":
                    console.log(field.name, formData[field.name]);
                    return (
                      <div key={i} className="row gx-4 mb-4">
                        <div className="col-md-12">
                          <label>{field.label}</label>
                          <Select
                            name={field.name}
                            className={`form-control set_max_width select_dropdown`}
                            options={selectOptions[field.name]}
                            value={
                              formData[field.name]
                                ? formData[field.name].map((value) => ({
                                    label: selectOptions[field.name].find(
                                      (option) => option.value === value
                                    )?.label,
                                    value: value,
                                  }))
                                : undefined
                            }
                            onChange={(e) => {
                              if (field.multiple) {
                                setFormData((p) => ({
                                  ...p,
                                  [field.name]: [...p[field.name], e?.value],
                                }));
                              } else {
                                setFormData({
                                  ...formData,
                                  [field.name]: e?.value
                                    ? [e?.value]
                                    : undefined,
                                });
                              }
                            }}
                            placeholder={
                              "Seleziona un'opzione per " + field.label
                            }
                            isClearable={true}
                          />
                        </div>
                      </div>
                    );
                  case "text":
                  case "number":
                  case "url":
                    return (
                      <div className="d-flex flex-column gap-2">
                        <label>{field.label}</label>
                        {field.multiple &&
                          formData[field.name]?.map((value, i) => (
                            <InputFieldRow
                              key={i}
                              name={field.name}
                              type={field.type}
                              value={value}
                              multiple={field.multiple}
                              variant="danger"
                              onRemove={() => {
                                setFormData((p) => ({
                                  ...p,
                                  [field.name]: p[field.name].filter(
                                    (_, index) => index !== i
                                  ),
                                }));
                              }}
                            />
                          ))}
                        <InputFieldRow
                          name={field.name}
                          type={field.type}
                          value={multipleFieldInputValue[i]}
                          multiple={field.multiple}
                          onAdd={() => {
                            setFormData((p) => ({
                              ...p,
                              [field.name]: [
                                ...p[field.name],
                                multipleFieldInputValue[i],
                              ],
                            }));
                            setMultipleFieldInputValue((p) => ({
                              ...p,
                              [i]: "",
                            }));
                          }}
                          onChange={(e) => {
                            if (!field.multiple) {
                              setFormData({
                                ...formData,
                                [field.name]: e.target.value,
                              });
                            } else {
                              setMultipleFieldInputValue((p) => ({
                                ...p,
                                [i]: e.target.value,
                              }));
                            }
                          }}
                        />
                      </div>
                    );
                  case "text_area":
                    return (
                      <div>
                        <label>{field.label}</label>
                        <textarea
                          name={field.name}
                          value={formData[field.name]}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [field.name]: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </div>
                    );
                  case "file":
                    return (
                      <div>
                        <label>{field.label}</label>
                        <input
                          type="file"
                          name={field.name}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [field.name]: e.target.files[0],
                            })
                          }
                          className="form-control"
                        />
                      </div>
                    );
                  default:
                    return <div></div>;
                }
              })()}
            </div>
          );
        })}
      </div>
      <div className="mt-auto">
        <div className="seeking_form_btn d-flex mt-4">
          <div
            onClick={() => onSubmit(formData)}
            style={{ marginLeft: "auto" }}
            className="btn btn_default btn_orange_outline"
          >
            {submitLabel}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormBuilder;
